import { STATUS } from "constant/Constants"
import { BvidsForFullLots } from "constant/ProductConstant"
import { checkPastDateTime } from "main/util/DateTimeHelper"
import { addToStorage, getFromStorage } from "main/util/LocalStorageHelper"

export const lotProductTotal = (
  product: any,
  lotKey = "selectedLot",
  quantityKey = "usedQty"
) => {
  const totalAmount = product[lotKey]?.reduce((accumulator: any, lot: any) => {
    const qty = lot[quantityKey] || 1
    const lotTotal = +lot.price * qty
    return accumulator + lotTotal
  }, 0)
  return totalAmount
}
export const totalLotQuantity = (
  product: any,
  lotKey = "selectedLot",
  quantityKey = "usedQty"
) => {
  const totalQty = product[lotKey]?.reduce((accumulator: any, lot: any) => {
    const qty = lot[quantityKey] || 1
    return accumulator + qty
  }, 0)
  return totalQty
}

export const findLot = (productLots: any, searchValue: string) => {
  const barcodeSplit = searchValue?.split("~")
  const lotCode = barcodeSplit[2]
    ? `${barcodeSplit[1]}~${barcodeSplit[2]}`
    : barcodeSplit[1]

  const lotFound = productLots?.find(
    (lot: { lotCode: string; quantityInside: string; status: string }) => {
      return (
        lotCode === lot?.lotCode &&
        +lot?.quantityInside > 0 &&
        lot?.status === STATUS.Active
      )
    }
  )
  if (lotFound) return lotFound

  return null
}

export const increaseLotQuantity = (product: any, selectedProduct: any) => {
  const { selectedLot } = selectedProduct || {}
  const [lot] = selectedLot || []

  const existingLotIndex = product?.selectedLot?.findIndex(
    (existingLot: { lotCode: string }) => existingLot?.lotCode === lot?.lotCode
  )

  if (existingLotIndex !== -1) {
    const existingLot = product?.selectedLot[existingLotIndex]
    const existingQty = existingLot?.usedQty || 1
    existingLot.usedQty = +existingQty + 1
    product.selectedLot[existingLotIndex] = existingLot
  } else {
    product?.selectedLot?.push(lot)
  }

  return product?.selectedLot
}

export const calculateLotTax = (products: any) => {
  let total = 0
  products?.forEach((product: any) => {
    product?.selectedLot?.forEach((lot: any) => {
      const qty = lot?.usedQty ? lot?.usedQty : 1
      const lotTotal = +lot?.gstValue * qty
      total = total + lotTotal
    })
  })
  return total
}

export const createLotVariables = (lots: any, lotPriceFlag?: boolean) => {
  return lots?.map((lot: any) => ({
    customField1: lot?.customField1,
    customField2: lot?.customField2,
    customField3: lot?.customField3,
    quantityInside: lot?.usedQty || 1,
    xid: lot?.bvid,
    ...(lotPriceFlag && { price: lot?.price })
  }))
}

export const saveProductsForSync = (products: any) => {
  if (products?.length) {
    const productsList = products?.map((product: any) => product?.bvid)
    const existingList = getFromStorage("productsBvids") || []
    const totalProducts = [...productsList, ...existingList]
    const arraySet = new Set(totalProducts)
    const finalProducts = Array.from(arraySet)
    addToStorage("productsBvids", finalProducts)
  }
}

export const countTotalLotsQty = (lots: []) => {
  return lots.reduce(
    (acc: number, lot: { quantityInside: string; expiry: string }) => {
      const totalQty = checkPastDateTime(lot?.expiry) ? 0 : +lot?.quantityInside
      return acc + totalQty
    },
    0
  )
}

export const increaseLotFullQuantity = (
  storeBvid: string,
  selectedLot: Record<string, undefined>
) => {
  if (BvidsForFullLots?.includes(storeBvid)) {
    selectedLot.usedQty = selectedLot?.quantityInside
  }
  return selectedLot
}
