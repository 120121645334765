import { cloneDeep, isEqual, sortBy } from "lodash-es"
import { wordSeparator } from "main/util/TextFormatHelper"
import { StoreModes } from "../constant/PosConstant"
import {
  createLotVariables,
  findLot
} from "../product/product_lots/helpers/ProductLotsHelper"

export const getActiveOptionAndVariant = (subItems: object[]) => {
  return subItems?.filter((variant: any) => variant.isActive)
}

export const sortBatches = (product: any) => {
  if (product?.batches) {
    const batchesCopy = [...product.batches]
    batchesCopy.sort(
      (a: any, b: any) =>
        new Date(a.expiry).getTime() - new Date(b.expiry).getTime()
    )
    return batchesCopy
  }
  return null
}

export const caluclateUsedBatchList = (batches: any, quantity: number) => {
  const batchList = []
  let remainingQuantity = quantity
  for (const batch of batches) {
    const batchQuantity = batch.quantity || 1
    if (remainingQuantity >= batchQuantity) {
      batchList.push({
        ...batch,
        utilizedQuantity: batchQuantity
      })
      remainingQuantity -= batchQuantity
    } else if (remainingQuantity > 0) {
      batchList.push({
        ...batch,
        utilizedQuantity: remainingQuantity
      })
      remainingQuantity = 0
    } else {
      break
    }
  }
  return batchList
}

export const batchProductTotal = (product: any) => {
  const { batches } = product
  const { quantity } = product
  const batchList = caluclateUsedBatchList(batches, quantity || 1)

  const totalAmount = batchList.reduce((accumulator, batch) => {
    const batchTotal = batch.discountedPrice * batch.utilizedQuantity
    return accumulator + batchTotal
  }, 0)
  return totalAmount
}

export const extractProductData = (
  product: any,
  productVariables?: object,
  isEditMode?: boolean
) => {
  if (!isEditMode)
    return {
      gtin: product?.gtin ?? "",
      xid: product?.bvid ?? "",
      barcode: product?.barcode ?? "",
      bvid: product?.bvid ?? "",
      name: product?.name ?? "",
      price: product?.price,
      originalPrice: product?.price,
      originalQuantity: product?.quantity,
      discountedPrice: product?.discountedPrice,
      quantity: product.cartonSize || 1,
      variants: product.variants,
      mainOptions: product?.mainOptions,
      categoryId: product?.categoryBvid,
      productType: product?.productType,
      pctCode: product?.pctCode,
      posId: product?.posId,
      flatDiscount: product?.flatDiscount || 0,
      flatDiscountApp: product?.flatDiscountApp || 0,
      productIntegrations: product?.productIntegrations,
      batches: sortBatches(product),
      batchQuantity: product?.batchQuantity,
      comment: product?.comment || "",
      isBarCodeScan: product?.isBarCodeScan || false,
      productLots: product?.productLots,
      cartonSize: product.cartonSize,
      minOrderQuantity: product.minOrderQuantity,
      maxOrderQuantity: product.maxOrderQuantity,
      ...(product?.selectedLot && {
        selectedLot: product?.selectedLot
      }),
      ...productVariables
    }
  else {
    return {
      xid: product?.bvid ?? "",
      barcode: product?.barcode ?? "",
      bvid: product?.bvid ?? "",
      name: product?.name ?? "",
      price: product?.price,
      originalPrice: product?.price,
      originalQuantity: product?.originalQuantity,
      discountedPrice: product?.discountedPrice,
      quantity: product?.quantity,
      variants: product.variants,
      mainOptions: product?.mainOptions,
      categoryId: product?.categoryId,
      pctCode: product?.pctCode,
      posId: product?.posId,
      flatDiscount: product?.flatDiscount || 0,
      flatDiscountApp: product?.flatDiscountApp || 0,
      batches: sortBatches(product),
      batchQuantity: product?.batchQuantity,
      comment: product?.comment || "",
      isBarCodeScan: product?.isBarCodeScan || false,
      productLots: product?.productLots,
      cartonSize: product.cartonSize,
      minOrderQuantity: product.minOrderQuantity,
      maxOrderQuantity: product.maxOrderQuantity,
      ...(product?.selectedLot && {
        selectedLot: product?.selectedLot
      }),
      ...productVariables
    }
  }
}

export const getProductPrice = (
  product: any,
  key = "price",
  toDisplay = false
) => {
  const qty = product?.qty ?? 1
  const price =
    product?.discountedPrice && product?.discountedPrice < product?.[key]
      ? product?.discountedPrice * qty
      : product?.[key] * qty
  if (toDisplay && price === 0)
    return product.discountedBasePrice
      ? product.discountedBasePrice
      : product.basePrice
  else return price
}

export const getProductBasePrice = (
  product: any,
  key = "price",
  toDisplay = false
) => {
  const qty = product?.qty ?? 1
  const price = product?.[key] || 0 * qty
  if (toDisplay && price === 0) product.basePrice
  else return price
}

export const calculateSubItemsTotal = (products: any, originalPrice?: any) => {
  if (products?.subItems?.length > 0) {
    return products?.subItems.reduce((prevValue: any, product: any) => {
      const getPrice = originalPrice
        ? product.optionPrice * (product?.qty ?? 1)
        : getProductPrice(product, "optionPrice")
      return prevValue + getPrice
    }, 0)
  }
  return 0
}

export const calculateBaseSubItemsTotal = (
  products: any,
  originalPrice?: any
) => {
  if (products?.subItems?.length > 0) {
    return products?.subItems.reduce((prevValue: any, product: any) => {
      const getPrice = originalPrice
        ? product.optionPrice * (product?.qty ?? 1)
        : getProductBasePrice(product, "optionPrice")
      return prevValue + getPrice
    }, 0)
  }
  return 0
}

export const convertSubVariantDetails = (product: any, subItems: any) => {
  const mainVariantDetails: any = {
    price: getProductPrice(product),
    originalPrice: product?.price
  }
  const subItemsTitle = subItems
    ?.sort((a: any, b: any) => (a.title > b.title ? 1 : -1))
    ?.map((item: any) => ({ title: item.title }))
  product?.variants?.forEach((variantOption: any) => {
    let selectedOptions = ""
    const variants = variantOption.variantOptionValues
      ?.sort((a: any, b: any) => (a.title > b.title ? 1 : -1))
      ?.map((value: any, index: any) => {
        selectedOptions += wordSeparator(index, "/") + value.title
        return { title: value.title }
      })

    if (isEqual(sortBy(variants), sortBy(subItemsTitle))) {
      mainVariantDetails.variantXid = variantOption.bvid
      mainVariantDetails.isActive = variantOption.isActive
      mainVariantDetails.selectedOptions = selectedOptions
      mainVariantDetails.price =
        getProductPrice(variantOption, "variantPrice") ||
        getProductPrice(product)
      mainVariantDetails.originalPrice = variantOption.variantPrice
    }
  })
  return mainVariantDetails
}

export const getExtraItemData = () => {
  return {
    extraItem: true,
    xid: "",
    name: "",
    price: "",
    quantity: 1,
    originalQuantity: null
  }
}

export function convertProductDetails(
  products: any,
  storeMode?: string,
  lotPriceFlag?: boolean,
  isOfflineData?: boolean
) {
  return products
    .filter((product: any) => !product.extraItem)
    .map((product: any) => {
      const { variantXid, selectedOptions } = convertSubVariantDetails(
        product,
        product.subItems
      )
      const tempSubItems = product.subItems
      const subItems =
        storeMode === StoreModes.Restaurant
          ? convertSubOptionDetails(product.subItems)
          : { variantXid, selectedOptions }

      const getVariantsPrice =
        product?.variants?.find((item: any) => item?.bvid === variantXid)
          ?.discountedPrice || 0

      let getOptionsPrice = 0
      if (product?.subItems?.length > 0) {
        getOptionsPrice = product?.subItems?.reduce(
          (accumulator: number, currentOption: any) => {
            return accumulator + (currentOption.discountedPrice || 0)
          },
          0
        )
      }

      const calculatedPrice =
        (product?.discountedPrice || product?.price) +
        (getOptionsPrice || getVariantsPrice)

      const discountedAmount =
        parseInt(calculatedPrice) *
        parseInt(product?.quantity) *
        (product?.flatDiscount / 100)
      return {
        xid: product?.xid,
        comment: product?.comment,
        barcode: product?.barcode,
        quantity: parseInt(product?.quantity),
        ...(isOfflineData && { discountedAmount }),
        ...(isOfflineData && { flatDiscount: product?.flatDiscount }),

        ...subItems,
        ...(!storeMode && { name: product?.name }),
        ...(!storeMode && {
          price: product?.discountedPrice || product?.price
        }),
        ...(product?.selectedLot?.length && {
          productLots: createLotVariables(product?.selectedLot, lotPriceFlag)
        }),
        ...(isOfflineData && { subItems: tempSubItems })
      }
    })
}

function convertSubOptionDetails(subItems: any) {
  const initialSubItemsData = {
    optionPrice: 0,
    selectedOptions: "",
    selectedMainOptionXids: [],
    selectedSubOptionXids: []
  }
  return subItems?.reduce((prev: any, option: any, index: number) => {
    if (option?.parent?.subOptionValues) {
      prev.selectedSubOptionXids.push(option.bvid)
    } else {
      if (option?.qty) {
        prev.selectedMainOptionXids.push([
          ...Array(option?.qty).fill(option?.bvid)
        ])
      } else {
        prev.selectedMainOptionXids.push(option.bvid)
      }
    }
    const title = option?.qty ? `${option.title}(${option.qty})` : option.title
    return {
      optionPrice: prev.optionPrice + option.price,
      selectedOptions:
        prev.selectedOptions + wordSeparator(index, ", ") + title,
      selectedMainOptionXids: prev.selectedMainOptionXids.flat(),
      selectedSubOptionXids: prev.selectedSubOptionXids
    }
  }, initialSubItemsData)
}

export function convertExtraItemDetails(products: any) {
  return products
    .filter((product: any) => product.extraItem)
    .map((product: any) => ({
      name: product.name,
      price: parseInt(product.price),
      quantity: parseInt(product.quantity),
      bvid: ""
    }))
}

export const splitCommentAndQuantity = (str: string): [string, number] => {
  const barcodeWithQuantityPattern = /\((.*?)\)x(.*)/
  const [, comment, commentQuantity] = str.split(barcodeWithQuantityPattern)
  return [comment, parseInt(commentQuantity, 10)]
}

export const isQuantityOverLotSize = (
  product: any,
  barcodeSearch: string,
  selectedProductsList: any[]
): boolean => {
  const currentProduct = selectedProductsList.find(
    (item) => item.xid === product.bvid
  )
  if (!currentProduct) return false

  const currentLot = findLot(currentProduct?.selectedLot, barcodeSearch)
  const lotQty = currentLot?.quantityInside
  const usedQty = currentLot?.usedQty || 1

  if (usedQty >= lotQty) {
    return true
  }

  return false
}
export const isQuantityOverBatchSize = (
  product: any,
  barcodeSearch: string,
  selectedProductsList: any[]
): boolean => {
  const currentProduct = selectedProductsList.find(
    (item) => item.xid === product.xid
  )
  if (!currentProduct) return false

  const searchTermBarCode = barcodeSearch

  const noOfqrcodes = currentProduct.comment.split(",")
  for (const code of noOfqrcodes) {
    const [comment, commentQuantity] = splitCommentAndQuantity(code)

    if (comment !== searchTermBarCode) continue
    if (commentQuantity >= product.batchSize) {
      return true
    }

    return false
  }

  return false
}

export const generateCommentQuantityByEachBarCode = (
  currentProduct: any,
  currentBarCode: string
): any => {
  const noOfqrcodes: string[] = currentProduct?.comment?.split(",")
  let currentScanIsProcessed = false
  const combinedComments = noOfqrcodes?.reduce(
    (prev: string[], currentCodeWithQuantity: string): string[] => {
      const [comment, commentQuantity] = splitCommentAndQuantity(
        currentCodeWithQuantity
      )
      if (comment !== currentBarCode) {
        prev?.push(`(${comment})x${commentQuantity}`)
        return prev
      }

      currentScanIsProcessed = true
      const incrementedCommentQuantity = commentQuantity + 1
      prev?.push(`(${currentBarCode})x${incrementedCommentQuantity}`)
      return prev
    },
    []
  )

  if (!currentScanIsProcessed) {
    combinedComments?.push(`(${currentBarCode})x${1}`)
  }

  return combinedComments?.join(",")
}

export const createOptionsForOfflineData = (product: {
  selectedOptions: string
  subItems: []
}) => {
  if (!product.selectedOptions) {
    const newProduct = cloneDeep(product)
    const selectedOptions: string[] = []
    newProduct?.subItems?.forEach((mainOption: { title: string }) => {
      selectedOptions?.push(mainOption.title)
    })
    newProduct.selectedOptions = selectedOptions.join(", ")
    return newProduct
  }
  return product
}
