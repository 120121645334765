import React, { Grid } from "@mui/material"

const styles = () => ({
  fontSmall: {
    fontSize: 10,
    margin: 0
  },
  fontNormal: {
    fontSize: 12,
    margin: 0
  },
  fontHeading: {
    fontSize: 14,
    fontWeight: 700,
    margin: 0
  },
  fontBold: {
    fontWeight: 700
  },
  labelHalfIndent: {
    marginLeft: 2
  },
  labelIndent: {
    marginLeft: 4
  }
})
type StyleClasses = {
  fontSmall: { fontSize: number; margin: number }
  fontNormal: { fontSize: number; margin: number }
  fontHeading: { fontSize: number; fontWeight: number; margin: number }
  fontBold: { fontWeight: number }
  labelHalfIndent: { marginLeft: number }
  labelIndent: { marginLeft: number }
}
type RowItemTypes = {
  labelBold?: boolean
  labelIndent?: boolean
  valueBold?: boolean
  fontClass: keyof StyleClasses
  fullIndent?: any
  label?: any
  labelClass?: any
  extraLabel?: any
  value?: any
}

const RowItem = ({
  labelBold,
  labelIndent,
  valueBold,
  fontClass,
  fullIndent,
  label,
  labelClass,
  extraLabel,
  value
}: RowItemTypes) => {
  const classes = styles()

  const labelBoldClass: object = labelBold || valueBold ? classes.fontBold : {}
  const labelIndentClass = labelIndent
    ? fullIndent
      ? classes.labelIndent
      : classes.labelHalfIndent
    : {}
  const labelClasses = {
    ...classes[fontClass],
    ...labelBoldClass,
    ...labelClass,
    ...labelIndentClass
  }
  return (
    <Grid container>
      <Grid item xs={value ? 8 : 12}>
        <p style={labelClasses}>{label || ""}</p>
        {extraLabel ? (
          <p style={{ ...labelIndentClass, ...classes.fontNormal }}>
            {extraLabel || ""}
          </p>
        ) : (
          <></>
        )}
      </Grid>
      {value ? (
        <Grid item xs={4} textAlign="end">
          <p
            style={{
              ...classes[fontClass],
              ...labelBoldClass
            }}
          >
            {value}
          </p>
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  )
}

export default RowItem
