import { useContext, useEffect } from "react"
import PosProductContext from "main/context/pos/PosProduct/PosProductContext"
import { PosProductType } from "main/context/pos/PosProduct/PosProductTypes"
import {
  findLot,
  increaseLotFullQuantity
} from "../product/product_lots/helpers/ProductLotsHelper"
import BranchContext from "main/context/branch/BranchContext"
import { BranchContextType } from "main/context/branch/BranchContextTypes"
import { fetchDataWithSearchPaginated } from "offline_database/queries/DbOperations"

export function useProductScanner(
  productList: any,
  handleProduct: any,
  fetchOfflineProducts: any
) {
  const productContext = useContext(PosProductContext) as PosProductType
  const { getStoreBvid } = useContext(BranchContext) as BranchContextType

  function addProduct() {
    const [product] = productList ?? []

    if (productContext.barcodeSearch) product.data.isBarCodeScan = true

    const checkProductOnSearch =
      productList && productList?.length === 1 && productContext.barcodeSearch

    const checkProductNotFound =
      !productList?.length && productContext.barcodeSearch
    if (checkProductOnSearch) {
      if (product.data?.productLots?.length) {
        const lotFound: any = findLot(
          product?.data?.productLots,
          productContext.barcodeSearch
        )
        if (lotFound) {
          handleProduct({
            flatDiscount: product.flatDiscount,
            flatDiscountApp: product.flatDiscountApp,
            selectedLot: [increaseLotFullQuantity(getStoreBvid(), lotFound)],
            ...product?.data
          })
          fetchOfflineProducts(
            fetchDataWithSearchPaginated,
            "productList",
            "name",
            "",
            50
          )
        } else
          productContext?.setShowError("Your barcode doesn't to match any lot")
      } else {
        handleProduct({
          flatDiscount: product.flatDiscount,
          flatDiscountApp: product.flatDiscountApp,
          ...product?.data
        })
        fetchOfflineProducts(
          fetchDataWithSearchPaginated,
          "productList",
          "name",
          "",
          50
        )
      }
      setTimeout(() => {
        productContext.setBarcodeSearch("")
      }, 50)
    } else if (checkProductNotFound) {
      setTimeout(() => {
        productContext.setBarcodeSearch("")
      }, 1000)
    }
  }

  useEffect(() => {
    if (productList?.length === 1) {
      addProduct()
    }
  }, [productList])
}
