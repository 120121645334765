import React, { useContext, useEffect, useState } from "react"
import {
  Grid,
  Button,
  TextField,
  Typography,
  CircularProgress
} from "@mui/material"
import { BranchContextType } from "main/context/branch/BranchContextTypes"
import BranchContext from "main/context/branch/BranchContext"
import PosProductContext from "main/context/pos/PosProduct/PosProductContext"
import { PosProductType } from "main/context/pos/PosProduct/PosProductTypes"
import PosContext from "main/context/pos/PosContext"
import { PosContextType } from "main/context/pos/PosContextTypes"
import { gql, useLazyQuery } from "@apollo/client"
import pulseClient from "graphql/pulse"
import ConfigContext from "main/context/config/ConfigContext"
import { ConfigContextTypes } from "main/context/config/ConfigContextTypes"
import PosCustomerContext from "main/context/pos/PosCustomer/PosCustomerContext"
import { PosCustomerType } from "main/context/pos/PosCustomer/PosCustomerTypes"
import { DiscountTypes } from "main/pos/constant/PosConstant"
import { convert03to92 } from "main/util/NumberHelper"
import {
  convertProductDetails,
  convertExtraItemDetails
} from "main/pos/helpers/ProductHelpers"

const styles = () => ({
  container: {
    margin: "20px 0px"
  },
  voucherText: {
    fontWeight: 500,
    cursor: "pointer"
  },
  voucherCode: {
    fontWeight: "bold",
    textTransform: "uppercase"
  }
})

const Voucher = () => {
  const classes = styles()
  const { selectedProductsList, getProductsSubTotal } = useContext(
    PosProductContext
  ) as PosProductType
  const posContext = useContext(PosContext) as PosContextType
  const { merchantBvid, storeMode, isProductLotOn } = useContext(
    ConfigContext
  ) as ConfigContextTypes
  const { getBranchBvid } = useContext(BranchContext) as BranchContextType
  const merchantXid = getBranchBvid() || merchantBvid
  const { customerPhone, setCustomerPhoneErr } = useContext(
    PosCustomerContext
  ) as PosCustomerType
  const {
    voucherDiscount,
    setVoucherDiscount,
    specialDiscount,
    setSpecialDiscount,
    setVoucherDetails,
    voucherDetails,
    setDiscountType,
    setCheckoutDialog,
    voucherCode,
    setVoucherCode
  } = useContext(PosContext) as PosContextType

  const [haveVoucher, setHaveVoucher] = useState(false)
  const [voucherError, setVoucherError] = useState("")

  const [getVoucherForCustomer, { loading: voucherLoading }] = useLazyQuery(
    VOUCHER_FOR_CUSTOMER,
    {
      client: pulseClient,
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true
    }
  )
  const [computeVoucher, { loading: computeVoucherLoading }] = useLazyQuery(
    COMPUTE_VOUCHER,
    {
      client: pulseClient,
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onError: function onError() {
        removeVoucher()
      }
    }
  )

  const [fetchVoucherDetails] = useLazyQuery(FETCH_VOUCHER_DETAILS, {
    client: pulseClient,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      posContext.setCategoryVoucherId(data?.voucher?.categoryXid)
    }
  })

  const addDiscount = async () => {
    if (isVoucherValid()) {
      setVoucherError("")
      const voucherResponse = await getVoucherForCustomer({
        variables: {
          code: voucherCode,
          username: convert03to92(customerPhone) ?? "",
          merchantBvid: merchantXid
        }
      })
      const voucherXid = voucherResponse?.data?.voucherForCustomer?.bvid
      if (voucherXid) await getAmountAfterCompute(voucherXid)
    }
  }

  const getAmountAfterCompute = async (voucherXid: string) => {
    const cartProducts = convertProductDetails(selectedProductsList, storeMode)
    const cartLineItems = convertExtraItemDetails(selectedProductsList)
    const variablesForComputeVoucher = {
      cartProducts,
      cartLineItems,
      voucherXid,
      isLotManagementAllowed: isProductLotOn
    }
    const computeResponse = await computeVoucher({
      variables: variablesForComputeVoucher
    })
    const { computeVoucherDiscount } = computeResponse?.data ?? {}

    if (computeVoucherDiscount) {
      const voucherDetails = await fetchVoucherDetails({
        variables: {
          bvid: voucherXid
        }
      })
      if (voucherDetails?.data?.voucher) {
        adjustDiscountVoucher(
          computeVoucherDiscount,
          variablesForComputeVoucher.voucherXid,
          voucherDetails?.data?.voucher
        )
      }
    }
  }

  const adjustDiscountVoucher = (
    discountValue: number,
    voucherXid: string,
    voucherDetails?: object
  ) => {
    setVoucherDiscount(discountValue)
    setVoucherDetails({ voucherXid, voucherCode, ...voucherDetails })
    setDiscountType(DiscountTypes.Voucher)
    setHaveVoucher(false)
    if (specialDiscount && getProductsSubTotal() - discountValue === 0) {
      setSpecialDiscount(0)
    }
    if (!customerPhone) {
      setCheckoutDialog(false)
    }
  }

  const isVoucherValid = () => {
    if (voucherCode === "") {
      setVoucherError("Voucher is required")
      return false
    }
    return true
  }

  const removeVoucher = (): void => {
    setVoucherDiscount(0)
    setVoucherCode("")
    setHaveVoucher(false)
    setVoucherError("")
    setDiscountType(DiscountTypes.Flat)
    setCustomerPhoneErr(false)
    setVoucherDetails({})
  }

  const openVoucherField = () => {
    setHaveVoucher(true)
  }

  useEffect(() => {
    if (voucherDiscount) addDiscount()
  }, [voucherDiscount])

  useEffect(() => {
    if (voucherDiscount) getAmountAfterCompute(voucherDetails?.voucherXid)
  }, [selectedProductsList])

  return (
    <>
      {voucherDiscount ? (
        <Typography
          color="primary"
          sx={classes.voucherText}
          onClick={removeVoucher}
        >
          Remove your voucher code? {"  "}
          <Typography color="primary" component="span" sx={classes.voucherCode}>
            {voucherDetails?.voucherCode}
          </Typography>
        </Typography>
      ) : (
        <></>
      )}
      {haveVoucher ? (
        <Grid container sx={classes.container}>
          <Grid item xs={10}>
            <TextField
              fullWidth
              placeholder="Voucher Code *"
              variant="outlined"
              size="small"
              value={voucherCode}
              id="voucher-txt"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setVoucherCode(e.target.value)
              }
              error={!!voucherError}
              helperText={voucherError}
            />{" "}
          </Grid>
          <Grid item xs={2}>
            {voucherLoading || computeVoucherLoading ? (
              <CircularProgress />
            ) : (
              <Button variant="text" onClick={addDiscount}>
                Add
              </Button>
            )}
          </Grid>
        </Grid>
      ) : (
        <></>
      )}

      {!haveVoucher && !voucherDiscount ? (
        <Typography
          color="primary"
          sx={classes.voucherText}
          onClick={openVoucherField}
        >
          Have a Voucher Code ?
        </Typography>
      ) : (
        <></>
      )}
    </>
  )
}

export default Voucher

const VOUCHER_FOR_CUSTOMER = gql`
  query voucherForCustomer(
    $code: String!
    $username: String!
    $merchantBvid: ID
  ) {
    voucherForCustomer(
      code: $code
      username: $username
      merchantBvid: $merchantBvid
    ) {
      bvid
      code
      isActive
      discountType
      discount
      minOrderValue
      maxDiscount
    }
  }
`
const COMPUTE_VOUCHER = gql`
  query computeVoucherDiscount(
    $voucherXid: ID!
    $cartProducts: [OrderProductParams]!
    $cartLineItems: [OrderLineParams]
    $isLotManagementAllowed: Boolean
  ) {
    computeVoucherDiscount(
      voucherXid: $voucherXid
      cartProducts: $cartProducts
      cartLineItems: $cartLineItems
      isLotManagementAllowed: $isLotManagementAllowed
    )
  }
`

const FETCH_VOUCHER_DETAILS = gql`
  query Voucher($bvid: ID) {
    voucher(bvid: $bvid) {
      maxDiscount
      categoryXid
      discount
      discountType
    }
  }
`
