import { useState } from "react"
import {
  useCustomerContext,
  usePosContext,
  useProductContext
} from "main/hooks/ContextHooks"
import {
  addBulkDataWithSearch,
  deleteSingleData,
  fetchDataWithSearch
} from "offline_database/queries/DbOperations"
import DatabaseTables from "constant/DatabaseTables"
import { isEqual } from "lodash-es"

export const useDraftOrder = () => {
  const [showConfirmOverwriteDraftDialog, setShowConfirmOverwriteDraftDialog] =
    useState(false)
  const {
    orderSource,
    customerName,
    customerPhone,
    alternatePhoneNumber,
    email,
    customerAddressDetails,
    customerAddressList,
    customerCity,
    customerArea,
    customerAddressId,
    orderPickupTime,
    showNewAddress,
    showSummary,
    showCustomerName,
    resetPosCustomerValues
  } = useCustomerContext()
  const {
    discount,
    discountType,
    flatDiscountPercentage,
    flatDiscount,
    specialDiscount,
    voucherDiscount,
    voucherCode,
    orderType,
    orderIdentifier,
    deliveryLaterTiming,
    isDeliveryLater,
    deliveryChoice,
    tax,
    categoryDiscount,
    resetPosValues
  } = usePosContext()
  const {
    resetPosProductValues,
    selectedProductsList,
    getProductsSubTotal,
    calculateProductsTotal
  } = useProductContext()
  const draftAlreadyExists: () => Promise<boolean> = async () => {
    if (!orderIdentifier) return false
    const draftedOrder = await fetchDataWithSearch(
      DatabaseTables.draftOrderTable,
      "orderNumber",
      orderIdentifier
    )

    return draftedOrder?.length
  }

  const updateProductListforPrint = async (products: any) => {
    let draftedOrder: any[] = []
    const productsToBePrinted: any[] = []
    if (!orderIdentifier) return products

    draftedOrder = await fetchDataWithSearch(
      DatabaseTables.draftOrderTable,
      "orderNumber",
      orderIdentifier
    )

    products.forEach((product: any) => {
      const matchingProduct = draftedOrder[0]?.data?.products?.find(
        (prevProd: any) => {
          if (product.extraItem) {
            return product.name === prevProd.name
          }
          return product.bvid === prevProd.bvid
        }
      )
      const isOptionChanged =
        matchingProduct &&
        (matchingProduct?.subItems?.length !== product?.subItems?.length ||
          !isEqual(matchingProduct, product))
      if (!matchingProduct) {
        productsToBePrinted.push({ ...product })
      } else if (product.quantity > matchingProduct.quantity) {
        const difference = Math.max(
          0,
          product.quantity - matchingProduct.quantity
        )
        productsToBePrinted.push({ ...product, quantity: difference })
      } else if (isOptionChanged) {
        productsToBePrinted.push({ ...product })
      }
    })
    return productsToBePrinted
  }

  const handleDraftOrder = async () => {
    if (await draftAlreadyExists()) setShowConfirmOverwriteDraftDialog(true)
    else return createDraftOrder()
  }
  const createDraftOrder = async () => {
    const orderVariables = {
      products: selectedProductsList,
      orderNumber: "",
      discountLine: {
        discount,
        discountType,
        flatDiscountPercentage,
        flatDiscount,
        specialDiscount,
        voucherDiscount,
        voucherCode,
        discountedAmount: specialDiscount + categoryDiscount
      },
      orderType,
      deliveryLaterTiming: deliveryLaterTiming.toString(),
      isDeliveryLater,
      deliveryChoice,
      orderTime: new Date().toString(),
      subTotal: getProductsSubTotal(),
      totalAmount: calculateProductsTotal(),
      taxAmount: tax,
      customer: {
        orderSource,
        customerName,
        customerPhone,
        alternatePhoneNumber,
        email,
        customerAddressDetails,
        customerAddressList,
        customerCity,
        customerArea,
        customerAddressId,
        orderPickupTime,
        showNewAddress,
        showSummary,
        showCustomerName
      }
    }

    const updatedProductResponse =
      await updateProductListforPrint(selectedProductsList)

    const draftId =
      orderIdentifier ||
      orderVariables.customer.customerPhone ||
      orderVariables.customer.alternatePhoneNumber ||
      orderVariables.customer.customerName ||
      Math.random().toString(36).substring(2)
    if (showConfirmOverwriteDraftDialog) {
      await deleteSingleData(
        DatabaseTables.draftOrderTable,
        "orderNumber",
        draftId
      )
    }

    orderVariables.orderNumber = draftId

    addBulkDataWithSearch("draftOrderList", [orderVariables], ["orderNumber"])

    resetPosProductValues()
    resetPosCustomerValues()
    resetPosValues()
    setShowConfirmOverwriteDraftDialog(false)
    return { ...orderVariables, products: updatedProductResponse }
  }

  return {
    handleDraftOrder,
    createDraftOrder,
    showConfirmOverwriteDraftDialog,
    setShowConfirmOverwriteDraftDialog,
    draftAlreadyExists
  }
}
