import React, { useContext } from "react"
import { Grid } from "@mui/material"
import PosContext from "main/context/pos/PosContext"
import { PosContextType } from "main/context/pos/PosContextTypes"
import PosProductContext from "main/context/pos/PosProduct/PosProductContext"
import { PosProductType } from "main/context/pos/PosProduct/PosProductTypes"
import ListElement from "main/common/ListElement"
import { numberRoundUp } from "main/util/NumberHelper"

const DiscountsDisplay = () => {
  const {
    specialDiscount,
    voucherDiscount,
    flatDiscount,
    categoryDiscount,
    binDiscount
  } = useContext(PosContext) as PosContextType
  const { getProductsSubTotal } = useContext(
    PosProductContext
  ) as PosProductType

  const checkFlatDiscount =
    !voucherDiscount && getProductsSubTotal() && flatDiscount

  const checkCategoryDiscount =
    !voucherDiscount && !flatDiscount && categoryDiscount

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          {!binDiscount && checkFlatDiscount ? (
            <ListElement
              label="Flat Discount"
              id="flat-discount"
              value={`- ${numberRoundUp(flatDiscount)}`}
            />
          ) : (
            <></>
          )}

          {!binDiscount && voucherDiscount ? (
            <ListElement
              label="Voucher Discount"
              id="voucher-discount"
              value={`- ${voucherDiscount?.toFixed(2)}`}
              valueColor={"red"}
            />
          ) : (
            <></>
          )}

          {!binDiscount && checkCategoryDiscount ? (
            <ListElement
              label="Category Discount"
              id="category-discount"
              value={`- ${Math.round(categoryDiscount)}`}
              valueColor={"red"}
            />
          ) : (
            <></>
          )}

          {!binDiscount && specialDiscount ? (
            <ListElement
              label="Special Discount"
              id="special-discount"
              value={`- ${specialDiscount}`}
              valueColor={"red"}
            />
          ) : (
            <></>
          )}
          {binDiscount ? (
            <ListElement
              label="Bin Discount"
              id="bin-discount"
              value={`- ${binDiscount?.discountedAmount}`}
              valueColor={"red"}
            />
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default DiscountsDisplay
