export const BVIDS = [
  "665c554d-626f-4ba7-9cce-d1b521421eb0",
  "28e5ac48-2817-4e9b-bfbe-9583ce706a0e",
  "52516be8-6f73-4324-98b1-3af0e77a546f",
  "b0c6cb12-974f-4344-a71d-408f6e6cccd7",
  "42f5c81a-d9b8-4045-aa13-5f6e5b4f8833",
  "e530a7a0-53b8-46f4-8c47-af41160c2111",
  "871c2638-cb82-46f5-96c8-8c30b8f25cbd",
  "bfbc24ef-d66c-41bb-90f9-5d6f9a74debc",
  "8ff7fbb7-5046-4c9a-8d33-2ac9ad509e20",
  "1164b4f2-e634-45aa-acb1-908e3517de39",
  "ea2ff0b5-cb8d-439b-b2cf-319f77980b8d",
  "0436ab43-c6bb-4bd0-813f-1e6e13c117a4",
  "4406d023-0476-4779-a400-55328ae2cc30",
  "22146235-debe-4ea6-a813-efade2b46e14",
  "63744121-ab9d-4233-8661-cacb66def049",
  "f756cb8d-c1d7-482f-94c2-2f68aa3d9685",
  "123b2c63-6584-4e05-a475-30676ad0ff6d",
  "2ff07d86-0246-4e1a-86a1-dd8d8b1caa38"
]
