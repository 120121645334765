import * as React from "react"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { DateCalendar as Calendar } from "@mui/x-date-pickers/DateCalendar"
import { DateCalenderProps } from "main/common/date_time/date_timeTypes"

const DateCalendar = ({ handleChange, disablePast }: DateCalenderProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Calendar onChange={handleChange} disablePast={disablePast} />
    </LocalizationProvider>
  )
}

export default DateCalendar
