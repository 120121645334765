import React, { SyntheticEvent } from "react"
import { Button, Grid, Typography } from "@mui/material"
import RemoveIcon from "@mui/icons-material/Remove"
import AddIcon from "@mui/icons-material/Add"
import { QuantityBarPropsType } from "main/common/option_button/QuantityBarTypes"

const styles = () => ({
  quantityBar: {
    width: 180,
    marginBottom: 4
  }
})

const QuantityBar = ({
  mainValues,
  handleQuantity,
  quantity
}: QuantityBarPropsType) => {
  const classes = styles()
  return (
    <>
      {mainValues?.isMultiSelect && mainValues?.quantity ? (
        <Grid
          item
          container
          justifyContent="space-between"
          sx={classes.quantityBar}
        >
          <Grid item>
            <Button
              size="small"
              color="secondary"
              variant="outlined"
              onClick={(e: SyntheticEvent<Element, Event>) =>
                handleQuantity(e, false)
              }
            >
              <RemoveIcon />
            </Button>
          </Grid>
          <Grid item>
            <Typography>{quantity}</Typography>
          </Grid>
          <Grid item>
            <Button
              size="small"
              color="secondary"
              variant="outlined"
              onClick={(e: SyntheticEvent<Element, Event>) =>
                handleQuantity(e, true)
              }
            >
              <AddIcon />
            </Button>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
    </>
  )
}
export default React.memo(QuantityBar)
