import React from "react"
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material"
import { CommonAccordionTypes } from "main/common/common_accordion/CommonAccordionTypes"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"

const styles = () => ({
  accordion: {
    borderRadius: 16,
    boxShadow: "none"
  }
})
const CommonAccordion = ({
  isAccordionOpen,
  setAccordionOpen,
  header,
  body
}: CommonAccordionTypes) => {
  const classes = styles()
  return (
    <Accordion expanded={isAccordionOpen} sx={classes.accordion}>
      <AccordionSummary
        expandIcon={<ArrowDropDownIcon />}
        onClick={() => setAccordionOpen(!isAccordionOpen)}
      >
        {header}
      </AccordionSummary>
      <AccordionDetails>{body}</AccordionDetails>
    </Accordion>
  )
}

export default CommonAccordion
