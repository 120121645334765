import { forwardRef, useContext } from "react"
import { Grid } from "@mui/material"
import RowItem from "main/pos/receipt/common/RowItem"
import { Table, TableBody, TableHead, TableRow, TableCell } from "@mui/material"
import { shiftUserReportValues, ShiftUserTypes } from "./helpers"
import { omit } from "lodash-es"
import PosContext from "main/context/pos/PosContext"
import { PosContextType } from "main/context/pos/PosContextTypes"

const styles = () => ({
  container: {
    width: 300,
    padding: 1,
    margin: 1,
    border: "1px solid black"
  },
  logo: {
    width: 120
  },
  fontSmall: {
    fontSize: 12,
    margin: 0
  },
  space: {
    margin: "8px 0px"
  },
  divider: {
    margin: "8px 0",
    border: "2px solid"
  },
  fontBold: {
    fontWeight: 700
  },
  extraSpace: {
    height: "80px",
    borderBottom: "1px solid"
  },
  table: {
    "& .MuiTableCell-root": {
      border: "1px solid gray"
    }
  }
})

type TableProps = {
  tableData: {
    [key: string]: string
  }
  classes: {
    [key: string]: any
  }
  description: string
  heading: string
  keyName: string
}

const TableComponent = ({
  tableData = {},
  classes,
  description,
  heading,
  keyName
}: TableProps) => {
  const updatedData = tableData["__typename"]
    ? omit(tableData, ["__typename"])
    : tableData

  return (
    <Table sx={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell sx={{ textAlign: "center", fontWeight: 700 }} colSpan={3}>
            <Grid>{heading}</Grid>
            <Grid>{description}</Grid>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.keys(updatedData).map((key: string) => {
          return (
            <TableRow key={key}>
              <TableCell>
                {shiftUserReportValues[keyName as keyof ShiftUserTypes]?.[key]}
              </TableCell>
              <TableCell sx={{ width: "50px" }}>{tableData[key]}</TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

function ShiftUserReport(props: any, ref: any) {
  const posContext = useContext(PosContext) as PosContextType
  const merchantData = posContext.merchantProfileData
  const { storeName } = merchantData || {}
  const classes = styles()
  const { data } = props
  const {
    alreadyPaidSaleSummary,
    cardSaleSummary,
    cashSaleSummary,
    onlinePaymentSaleSummary,
    creditSaleSummary,
    returnSummary,
    totalSalesSummary,
    shiftEndTime,
    shiftStartTime,
    userName
  } = data || {}

  const updateCashSalesSummary = (
    currentObject: { [key: string]: string },
    newObject: { [key: string]: string }
  ) => {
    const finalObject: { [key: string]: string } = {}

    for (const key in currentObject) {
      if (Object.hasOwn(currentObject, key)) {
        finalObject[key] = currentObject[key]
      }
    }

    for (const key in newObject) {
      if (Object.hasOwn(newObject, key)) {
        if (Object.hasOwn(finalObject, key)) {
          finalObject[key] += newObject[key]
        } else {
          finalObject[key] = newObject[key]
        }
      }
    }

    return finalObject
  }

  const updateTimeStamp = (timeStamp: string) => {
    const convertedTimeStamp = parseInt(timeStamp, 10)

    const date = new Date(convertedTimeStamp)

    return date?.toUTCString().slice(17, 22)
  }

  return (
    <>
      <Grid container ref={ref} sx={classes.container}>
        <Grid item xs={12} textAlign="center">
          <h4>SHIFT SUMMARY REPORT</h4>
        </Grid>
        <Grid item xs={12}>
          <RowItem
            fontClass="fontNormal"
            label={
              <span>
                Business: <span style={classes.fontBold}>{storeName}</span>
              </span>
            }
          />
          <RowItem
            fontClass="fontNormal"
            label={
              <span>
                Cashier Name: <span style={classes.fontBold}>{userName}</span>
              </span>
            }
          />
          <RowItem
            fontClass="fontNormal"
            label={
              <span>
                Shift Start Time:{" "}
                <span style={classes.fontBold}>
                  {shiftStartTime ? updateTimeStamp(shiftStartTime) : ""}
                </span>
              </span>
            }
          />

          <RowItem
            fontClass="fontNormal"
            label={
              <span>
                Shift End Time:{" "}
                <span style={classes.fontBold}>
                  {shiftEndTime ? updateTimeStamp(shiftEndTime) : ""}
                </span>
              </span>
            }
          />
        </Grid>
        <Grid item xs={12} sx={classes.space}>
          <TableComponent
            tableData={totalSalesSummary}
            classes={classes}
            heading={"TOTAL SALES SUMMARY"}
            description={"Net Sales excluding cancellations"}
            keyName={"totalSalesSummary"}
          />
        </Grid>
        <Grid item xs={12} sx={classes.space}>
          <TableComponent
            tableData={updateCashSalesSummary(
              cashSaleSummary,
              alreadyPaidSaleSummary
            )}
            classes={classes}
            heading={"CASH SALES SUMMARY"}
            description={"Net Sales excluding cancellations"}
            keyName={"cashSaleSummary"}
          />
        </Grid>
        <Grid item xs={12} sx={classes.space}>
          <TableComponent
            tableData={cardSaleSummary}
            classes={classes}
            heading={"CARD SALES SUMMARY"}
            description={"Net Sales excluding cancellations"}
            keyName={"cardSaleSummary"}
          />
        </Grid>
        <Grid item xs={12} sx={classes.space}>
          <TableComponent
            tableData={onlinePaymentSaleSummary}
            classes={classes}
            heading={"ONLINE SALES SUMMARY"}
            description={"Net Sales excluding cancellations"}
            keyName={"onlinePaymentSaleSummary"}
          />
        </Grid>
        <Grid item xs={12} sx={classes.space}>
          <TableComponent
            tableData={creditSaleSummary}
            classes={classes}
            heading={"CREDIT SALES SUMMARY"}
            description={"Net Sales excluding cancellations"}
            keyName={"creditSaleSummary"}
          />
        </Grid>
        <Grid item xs={12} sx={classes.space}>
          <TableComponent
            tableData={returnSummary}
            classes={classes}
            heading={"RETURNS REPORT"}
            description={"Orders returned by the customer"}
            keyName={"returnSummary"}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default forwardRef(ShiftUserReport)
