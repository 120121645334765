import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  setContext,
  from,
  onError
} from "graphql/apollo"
import config from "config/config"
import { getFromStorage } from "main/util/LocalStorageHelper"
import { eAccumulator } from "main/common/errorAccumulator"

const phoenix = "phoenix"

const httpLink = createHttpLink({
  uri: config[phoenix].url
})

const authLink = setContext((_, { headers }) => {
  const user = getFromStorage("user")
  const token = user ? user.token : ""
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ""
    }
  }
})

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors)
    graphQLErrors.forEach((error) => {
      eAccumulator.call(error)
    })
})

const gqlClient = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache()
})

export default gqlClient
