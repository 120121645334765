import React from "react"
import { createOptionsForOfflineData } from "main/pos/helpers/ProductHelpers"
import RowItem from "main/pos/receipt/common/RowItem"

const KitchenProductList = ({
  products,
  kot
}: {
  products: any
  kot: boolean
}) => {
  return (
    <>
      {products?.map((existingProduct: any) => {
        const product: any = createOptionsForOfflineData(existingProduct)
        return (
          <>
            <RowItem
              labelBold
              valueBold
              fontClass="fontNormal"
              label={product?.name}
              extraLabel={product?.selectedOptions}
              value={product?.quantity - (product?.oldQuantity || 0)}
            />
            {product?.comment ? (
              <RowItem
                labelIndent
                fullIndent={!kot ? true : false}
                fontClass="fontSmall"
                label={`Instructions: ${product?.comment}`}
              />
            ) : (
              <></>
            )}
            <hr />
          </>
        )
      })}
    </>
  )
}

export default KitchenProductList
