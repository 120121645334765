import { getFromStorage } from "main/util/LocalStorageHelper"
import WhiteLabelsPartners from "main/white-label/WhiteLabel"

const RouteHelpers = {
  isWhiteLabelSlug(url: any) {
    return Object.keys(WhiteLabelsPartners).find((key) =>
      url.includes(WhiteLabelsPartners[key].slug)
    )
  },
  getCurrentUrl() {
    return window.location.origin + window.location.pathname
  },
  getMerchantSlug() {
    const merchantSlug = window.location.hostname.split(".")
    const whiteLabel = merchantSlug.includes("chikoo")
      ? "chikoo"
      : RouteHelpers.isWhiteLabelSlug(window.location.hostname)
    return whiteLabel
  },
  getWhiteLabel(tld: string) {
    return RouteHelpers.isWhiteLabelSlug(tld) && tld?.split(".")[0]
  },
  getWhiteLabelPartner() {
    const checkUser = getFromStorage("user")
    if (checkUser) {
      const { tld } =
        checkUser.user.userMerchant || checkUser.user.userMaster || {}

      const slug =
        RouteHelpers.getWhiteLabel(tld || WhiteLabelsPartners.chikoo.tld) ||
        "chikoo"

      const whiteLabelPartner = WhiteLabelsPartners[slug]
      return whiteLabelPartner
    } else {
      return
    }
  }
}

export default RouteHelpers
