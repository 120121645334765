import React, { useState } from "react"
import { ButtonGroup, Grid, IconButton, TextField, Theme } from "@mui/material"
import { useTheme } from "@mui/styles"
import ConfirmDialog from "main/common/ConfirmDialog"
import { allowPositiveNumbers } from "main/util/NumberHelper"
import { isDigital } from "main/util/ProductHelper"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle"

const styles = (theme: Theme) => ({
  quantityDelete: {
    color: theme.custom.red,
    cursor: "pointer",
    "& > button": {
      border: "none"
    }
  },
  quantityField: {
    marginLeft: 1,
    width: 60,
    "& input": {
      fontSize: 20,
      padding: "14px 8px",
      fontWeight: 600,
      textAlign: "center"
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: 1
    }
  },
  group: {
    gap: 0.5,
    "& > button": {
      border: "1px solid",
      borderColor: theme.palette.primary.main,
      padding: 0
    },
    "& .del-btn": {
      borderColor: theme.custom.red
    }
  }
})

const QuantityBox = ({
  error,
  id,
  count = 1,
  productQuantity,
  handleQuantity,
  deleteProduct,
  productType,
  disable,
  fieldStyle
}: {
  error: any
  id: any
  productQuantity: any
  handleQuantity: any
  deleteProduct: any
  productType: any
  count?: number
  disable?: boolean
  deleteStyle?: Record<string, unknown>
  fieldStyle?: Record<string, unknown>
}) => {
  const classes = styles(useTheme())
  const [confirmDialog, setConfirmDialog] = useState(false)

  const deleteCartItem = () => {
    deleteProduct(id)
    setConfirmDialog(false)
  }

  const handleQuantityText = (e: any) => {
    e.target.select()
  }

  const handleQuantityField = (value: string) => {
    handleQuantity(allowPositiveNumbers(value), id)
  }

  const handleDeleteItem = () => {
    setConfirmDialog(true)
  }

  const handleCancelDelete = (value: any) => {
    setConfirmDialog(value)
  }

  const upButton = (
    <IconButton
      size="small"
      onClick={() => handleQuantityField(String(+productQuantity + count))}
    >
      <ArrowDropUpIcon />
    </IconButton>
  )

  const downArrow = (
    <IconButton
      size="small"
      onClick={() => handleQuantityField(String(+productQuantity - count))}
    >
      <ArrowDropDownIcon />
    </IconButton>
  )

  const deleteButton = (
    <IconButton
      size="small"
      onClick={handleDeleteItem}
      sx={{ ...classes.quantityDelete }}
      className="del-btn"
    >
      <RemoveCircleIcon />
    </IconButton>
  )
  const downButton = productQuantity == count ? deleteButton : downArrow
  const buttons = [upButton, downButton]

  return (
    <Grid container alignItems={"center"} flexWrap="nowrap">
      <Grid item id="remove-cart-product">
        <ButtonGroup
          orientation="vertical"
          aria-label="Vertical button group"
          sx={{ ...classes.group }}
        >
          {buttons}
        </ButtonGroup>
      </Grid>
      <Grid item>
        <TextField
          variant="outlined"
          name="quantity"
          autoComplete="off"
          id="cart-product-quantity-txt"
          sx={{ ...classes.quantityField, ...fieldStyle }}
          error={!!error}
          helperText={error ? error : ""}
          value={productQuantity}
          onClick={handleQuantityText}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleQuantityField(e.target.value)
          }
          disabled={isDigital(productType) || disable || count > 1}
        />
      </Grid>
      <ConfirmDialog
        title="Delete Cart's Item"
        message="Are you sure you want to remove this item?"
        onConfirm={deleteCartItem}
        openDialog={confirmDialog}
        closeDialog={handleCancelDelete}
      />
    </Grid>
  )
}
export default QuantityBox
