import { Grid, Typography } from "@mui/material"
import ConnectionHooks from "main/context/connection/ConnectionHook"

const styles = () => ({
  internetWarning: {
    marginTop: 1,
    textAlign: "center",
    fontWeight: 600
  }
})

const InternetConnection = () => {
  const classes = styles()
  const { isInternetOn } = ConnectionHooks()

  return (
    <Grid container>
      <Grid item xs={12}>
        {!isInternetOn ? (
          <Typography sx={classes.internetWarning} color="error">
            POS is now working in offline mode. Data will be synced once
            internet connection is restored
          </Typography>
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  )
}
export default InternetConnection
