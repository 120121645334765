import { fetchDataWithSearch } from "offline_database/queries/DbOperations"
import ShortUniqueId from "short-unique-id"
const alphabet = "abcdefghijklmnopqrstuvwxyz"

const createOrderNumber = async () => {
  const uid = new ShortUniqueId({ length: 5 })
  const lastOrder: any = await fetchLastOrder()
  const passName = OrderNumberGenerator(
    lastOrder ? lastOrder.orderNumber : "AAA-999"
  )
  return `${passName.toUpperCase()}-${uid()}`
}

const fetchLastOrder = async () => {
  const list: any = await fetchDataWithSearch("orderList")
  return list[list?.length - 1]
}

const OrderNumberGenerator = (lastNumber: any) => {
  const lastNumberArray = lastNumber.split("-")
  const str = `${lastNumberArray[0]}-${lastNumberArray[1] || ""}`
  const length = alphabet.length
  let result = str
  let i = str.length

  while (i >= 0) {
    const last: any = str.charAt(--i)
    let next = ""
    let carry = false
    const index = alphabet.indexOf(last.toLowerCase())

    if (isNaN(last)) {
      const numbers = processNumber({
        i,
        last,
        carry,
        result,
        length,
        index,
        next
      })
      result = numbers.result
      carry = numbers.carry
      next = numbers.next
      if (numbers.breaks) break
    } else {
      const alphabets = processAlphabet({
        next,
        last,
        carry,
        i,
        result
      })
      result = alphabets.result
      carry = alphabets.carry
      next = alphabets.next
      if (alphabets.breaks) break
    }

    result = result.slice(0, i) + next + result.slice(i + 1)
    if (!carry) {
      break
    }
  }
  return result
}

const processAlphabet = ({ next, last, carry, result, i }: any) => {
  let breaks = false
  next = +last + 1
  if (next > 9) {
    next = 0
    carry = true
  }

  if (carry && i === 0) {
    result = "1" + next + result.slice(1)
    breaks = true
  }
  return { result, next, carry, breaks }
}

const processNumber = ({
  i,
  last,
  carry,
  result,
  length,
  index,
  next
}: any) => {
  let breaks = false

  if (index === -1) {
    next = last
    carry = true
  } else {
    const isUpperCase = last === last.toUpperCase()
    next = alphabet.charAt((index + 1) % length)
    if (isUpperCase) {
      next = next.toUpperCase()
    }

    carry = index + 1 >= length
    if (carry && i === 0) {
      const added = isUpperCase ? "A" : "a"
      result = added + next + result.slice(1)
      breaks = true
    }
  }
  return { result, breaks, carry, next }
}

export default createOrderNumber
