import React from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  CircularProgress,
  Grid
} from "@mui/material"
import { SimpleDialogTypes } from "main/common/simple_dialog/SimpleDialogTypes"
import CloseIcon from "@mui/icons-material/Close"

const styles = () => ({
  overflowVisible: {
    overflow: "visible"
  },
  overflowHidden: {
    overflow: "hidden"
  }
})

const SimpleDialog = ({
  body,
  dialogOpen,
  title,
  titleClass,
  maxWidth,
  overflowVisible = false,
  fullWidth = false,
  cancelButton = "Cancel",
  okButton = "OK",
  confirmButtonClass,
  handleCancel,
  handleOk,
  loading = false,
  allowActions = false,
  disabled = false,
  small = false,
  onHandleClose,
  closeButton
}: SimpleDialogTypes) => {
  const classes = styles()
  const visibleClass = overflowVisible ? classes.overflowVisible : {}

  const condition =
    title && closeButton
      ? "space-between"
      : title
        ? "flex-start"
        : closeButton
          ? "flex-end"
          : ""

  return (
    <Dialog
      open={dialogOpen}
      fullWidth={fullWidth}
      maxWidth={maxWidth || "sm"}
      aria-labelledby="simple-dialog-title"
      onClose={onHandleClose}
      sx={visibleClass}
    >
      <DialogTitle id="simple-dialog-title" sx={titleClass}>
        <Grid container justifyContent={condition}>
          {title ? <Grid item>{title}</Grid> : <></>}
          {closeButton ? (
            <IconButton onClick={onHandleClose} id="modal-close-btn">
              <CloseIcon />
            </IconButton>
          ) : (
            <></>
          )}
        </Grid>
      </DialogTitle>

      <DialogContent sx={visibleClass}>{body}</DialogContent>
      {allowActions ? (
        <DialogActions>
          {loading ? (
            <Grid>
              <CircularProgress size={24} />
            </Grid>
          ) : (
            <>
              {handleCancel ? (
                <Button
                  id="cancel-button"
                  onClick={handleCancel}
                  color="secondary"
                  size={small ? "small" : "medium"}
                  variant="outlined"
                >
                  {cancelButton}
                </Button>
              ) : (
                <></>
              )}

              {handleOk ? (
                <Button
                  id="ok-button"
                  onClick={handleOk}
                  color="primary"
                  variant="contained"
                  disabled={loading || disabled}
                  size={small ? "small" : "medium"}
                  sx={confirmButtonClass}
                >
                  {okButton}
                </Button>
              ) : (
                <></>
              )}
            </>
          )}
        </DialogActions>
      ) : (
        <></>
      )}
    </Dialog>
  )
}

export default SimpleDialog
