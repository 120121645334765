export const updateFavicon = (faviconUrl: string) => {
  if (faviconUrl) {
    const link =
      (document.querySelector(
        'link[rel="shortcut icon"]'
      ) as HTMLLinkElement) ||
      (document.querySelector('link[rel="icon"]') as HTMLLinkElement)
    link.href = faviconUrl
  }
}
