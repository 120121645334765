import React from "react"
import { BrowserRouter as Router } from "react-router-dom"
import { ApolloProvider } from "graphql/apollo"
import MainWrapper from "main/MainWrapper"
import { ThemeMaker } from "styles/ThemeMaker"
import phoenixClient from "graphql/phoenix"
import UserContainer from "main/context/user/UserContainer"
import GlobalError from "main/common/GlobalError"
import ErrorContainer from "main/context/error/ErrorContainer"
import { useMediaQuery } from "@mui/material"
import MobileView from "main/common/MobileView"
import { Provider } from "react-redux"
import { store } from "./redux/Store"

const App = () => {
  const MobileViewPort = "(max-width:600px)"
  const isMobile = useMediaQuery(MobileViewPort)

  return (
    <ApolloProvider client={phoenixClient}>
      <Provider store={store}>
        <Router>
          <ThemeMaker>
            {!isMobile ? (
              <ErrorContainer>
                <GlobalError />
                <UserContainer>
                  <MainWrapper />
                </UserContainer>
              </ErrorContainer>
            ) : (
              <MobileView />
            )}
          </ThemeMaker>
        </Router>
      </Provider>
    </ApolloProvider>
  )
}

export default App
