import React, { useContext } from "react"
import { Grid } from "@mui/material"
import CustomerFields from "main/pos/order/details/common/CustomerFields"
import SummaryButton from "main/pos/order/details/common/details_summary/SummaryButtons"
import DetailsSummary from "main/pos/order/details/common/details_summary/DetailsSummary"
import PosCustomerContext from "main/context/pos/PosCustomer/PosCustomerContext"
import { PosCustomerType } from "main/context/pos/PosCustomer/PosCustomerTypes"

const WalkInDetails = () => {
  const customerContext = useContext(PosCustomerContext) as PosCustomerType

  return (
    <Grid container>
      <Grid item xs={12}>
        {customerContext.showSummary ? (
          <DetailsSummary />
        ) : (
          <Grid item xs={12}>
            <CustomerFields hideAltNumber />
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <SummaryButton />
      </Grid>
    </Grid>
  )
}

export default WalkInDetails
