import React from "react"
import { Grid } from "@mui/material"
import { useTheme } from "@mui/styles"
import { Theme } from "@mui/material"

const styles = (theme: Theme) => ({
  fontSmall: {
    fontSize: 10,
    margin: 0
  },
  fontNormal: {
    fontSize: 12,
    margin: 0
  },
  fontHeading: {
    fontSize: 14,
    fontWeight: 700,
    margin: 0
  },
  fontBold: {
    fontWeight: 700
  },
  labelHalfIndent: {
    marginLeft: 2
  },
  labelIndent: {
    marginLeft: 4
  },
  discountedText: {
    color: theme.custom.red
  }
})
type StyleClasses = {
  fontSmall: { fontSize: number; margin: number }
  fontNormal: { fontSize: number; margin: number }
  fontHeading: { fontSize: number; fontWeight: number; margin: number }
  fontBold: { fontWeight: number }
  labelHalfIndent: { marginLeft: number }
  labelIndent: { marginLeft: number }
}
type RowThreeColsTypes = {
  labelBold?: boolean
  labelIndent?: boolean
  valueBold?: boolean
  fontClass: keyof StyleClasses
  fullIndent?: boolean
  label?: any
  labelClass?: any
  extraLabel?: any
  value?: any
  midValue?: any
  midValueBold?: boolean
  discountedAmount?: any
}
const RowThreeCols = ({
  labelBold,
  labelIndent,
  valueBold,
  fontClass,
  fullIndent,
  label,
  labelClass,
  extraLabel,
  value,
  midValue,
  midValueBold,
  discountedAmount
}: RowThreeColsTypes) => {
  const classes = styles(useTheme())

  const labelBoldClass: object =
    labelBold || valueBold || midValueBold ? classes.fontBold : {}
  const labelIndentClass = labelIndent
    ? fullIndent
      ? classes.labelIndent
      : classes.labelHalfIndent
    : {}
  const labelClasses = {
    ...classes[fontClass],
    ...labelBoldClass,
    ...labelClass,
    ...labelIndentClass
  }

  return (
    <Grid container>
      <Grid item xs={value ? 5 : 12}>
        <p style={labelClasses}>{label || ""}</p>
        {extraLabel ? (
          <p style={labelIndentClass}>{extraLabel || ""}</p>
        ) : (
          <></>
        )}
      </Grid>
      {midValue ? (
        <Grid item xs={2.25} textAlign="end">
          <p
            style={{
              ...classes[fontClass],
              ...labelBoldClass
            }}
          >
            {midValue}
          </p>
        </Grid>
      ) : (
        <></>
      )}
      <Grid item xs={2.25} textAlign="end">
        {discountedAmount !== undefined || discountedAmount !== null ? (
          <p
            style={{
              ...classes[fontClass],
              ...labelBoldClass
            }}
          >
            {isNaN(discountedAmount)
              ? discountedAmount
              : Number(discountedAmount).toFixed(2)}
          </p>
        ) : (
          <></>
        )}
      </Grid>
      {value ? (
        <Grid item xs={2.5} textAlign="end">
          <p
            style={{
              ...classes[fontClass],
              ...labelBoldClass
            }}
          >
            {value}
          </p>
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  )
}

export default RowThreeCols
