import React from "react"
import { Grid } from "@mui/material"
import { useTheme } from "@mui/styles"
import { Theme } from "@mui/material"

const styles = (theme: Theme) => ({
  container: {
    marginBottom: 2
  },
  fontSmall: {
    fontSize: 11,
    margin: 0
  },
  fontNormal: {
    fontSize: 12,
    margin: 0
  },
  fontHeading: {
    fontSize: 14,
    fontWeight: 700,
    margin: 0
  },
  fontBold: {
    fontWeight: 700
  },
  labelHalfIndent: {
    marginLeft: 2
  },
  labelIndent: {
    marginLeft: 4
  },
  discountedText: {
    color: theme.custom.red
  }
})
type StyleClasses = {
  fontSmall: { fontSize: number; margin: number }
  fontNormal: { fontSize: number; margin: number }
  fontHeading: { fontSize: number; fontWeight: number; margin: number }
  fontBold: { fontWeight: number }
  labelHalfIndent: { marginLeft: number }
  labelIndent: { marginLeft: number }
}
type RowThreeColsTypes = {
  labelBold?: boolean
  labelIndent?: boolean
  valueBold?: boolean
  fontClass: keyof StyleClasses
  fullIndent?: boolean
  label?: any
  labelClass?: any
  extraLabel?: any
  firstValue?: any
  midValue?: any
  midValueBold?: boolean
  lastValue?: any
}
const RowThreeItem = ({
  labelBold,
  valueBold,
  fontClass,
  firstValue,
  midValue,
  midValueBold,
  lastValue
}: RowThreeColsTypes) => {
  const classes = styles(useTheme())

  const labelBoldClass: object =
    labelBold || valueBold || midValueBold ? classes.fontBold : {}

  return (
    <Grid container justifyContent={"space-between"} sx={classes.container}>
      {firstValue ? (
        <Grid item>
          <p
            style={{
              ...classes[fontClass],
              ...labelBoldClass
            }}
          >
            {firstValue}
          </p>
        </Grid>
      ) : (
        <></>
      )}
      <Grid item>
        {midValue ? (
          <p
            style={{
              ...classes[fontClass],
              ...labelBoldClass
            }}
          >
            {midValue}
          </p>
        ) : (
          <></>
        )}
      </Grid>
      {lastValue ? (
        <Grid item>
          <p
            style={{
              ...classes[fontClass],
              ...labelBoldClass
            }}
          >
            {lastValue}
          </p>
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  )
}

export default RowThreeItem
