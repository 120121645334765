import React, { useContext } from "react"
import { Button, Grid } from "@mui/material"
import ConfigContext from "main/context/config/ConfigContext"
import { ConfigContextTypes } from "main/context/config/ConfigContextTypes"

const styles = () => ({
  commentButton: {
    padding: "8px 16px",
    marginTop: 2,
    borderRadius: 18,
    fontSize: 12,
    "&:hover": {
      opacity: 0.5
    }
  },
  resetButton: {
    opacity: 0.7
  },
  resetButtonContainer: {
    flexGrow: 1,
    textAlign: "end"
  }
})

const ProductComments = ({
  productComment,
  setProductComment
}: {
  productComment: any
  setProductComment: any
}) => {
  const classes = styles()
  const { isRestaurantMode } = useContext(ConfigContext) as ConfigContextTypes

  const handleProductComment = (comment: string) => {
    const newComment = `${productComment} ${comment}`
    setProductComment(newComment)
  }

  const defaultComments = [
    "No Mushrooms",
    "Extra Ketchup",
    "Sauce on the Side",
    "No Onions"
  ]

  const handleClearButton = () => {
    setProductComment("")
  }
  return (
    <>
      {isRestaurantMode() ? (
        <Grid container spacing={2}>
          {defaultComments?.map((comment, index) => (
            <Grid item key={index}>
              <Button
                onClick={() => handleProductComment(comment)}
                sx={classes.commentButton}
                id={comment}
                variant="outlined"
              >
                {comment}
              </Button>
            </Grid>
          ))}
          <Grid item sx={classes.resetButtonContainer}>
            <Button
              onClick={handleClearButton}
              sx={{ ...classes.commentButton, ...classes.resetButton }}
              id="clear-comments-btn"
              color="secondary"
              variant="outlined"
            >
              Clear
            </Button>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
    </>
  )
}

export default ProductComments
