import React from "react"
import PosContainer from "main/context/pos/PosContainer"
import PosCustomerContainer from "main/context/pos/PosCustomer/PosCustomerContainer"
import PosProductContainer from "main/context/pos/PosProduct/PosProductContainer"
import PosWrapper from "main/pos/PosWrapper"

const Pos = () => {
  return (
    <PosContainer>
      <PosCustomerContainer>
        <PosProductContainer>
          <PosWrapper />
        </PosProductContainer>
      </PosCustomerContainer>
    </PosContainer>
  )
}

export default Pos
