import { SyntheticEvent, useEffect, useState } from "react"
import { TextField, Autocomplete } from "@mui/material"
import { fetchDataWithSearch } from "offline_database/queries/DbOperations"
import {
  AreaTypes,
  OfflineAreasType
} from "main/common/new_address/area/AreaTypes"
import ConnectionHooks from "main/context/connection/ConnectionHook"

const Area = ({
  cityBvid,
  selectedArea,
  setSelectedArea,
  error,
  setAreaError
}: AreaTypes) => {
  const { isInternetOn } = ConnectionHooks()

  const [offlineAreas, setOfflineAreas] = useState<OfflineAreasType[]>([])

  const fetchOfflineAreas = async (
    tableName: string,
    query?: string,
    search?: string
  ) => {
    const areasList = await fetchDataWithSearch(tableName, query, search)
    setOfflineAreas(areasList)
  }

  const handleArea = (
    e: SyntheticEvent<Element, Event>,
    area: OfflineAreasType | null
  ) => {
    const filteredArea = area?.data ? area.data : area
    setSelectedArea(filteredArea as OfflineAreasType)
    setAreaError(false)
  }

  useEffect(() => {
    if (cityBvid) fetchOfflineAreas("areaList", "bvid", cityBvid?.bvid)
    // eslint-disable-next-line
  }, [cityBvid, isInternetOn])

  const getAreas = () => {
    return offlineAreas
  }
  return (
    <>
      {getAreas()?.length && cityBvid ? (
        <Autocomplete
          fullWidth
          options={getAreas()}
          getOptionLabel={(option: OfflineAreasType | undefined) => {
            option = Object.keys(option?.data ?? {}).length
              ? option?.data
              : option

            return option?.name ?? ""
          }}
          value={selectedArea as OfflineAreasType}
          onChange={handleArea}
          renderInput={(params) => (
            <TextField
              required
              {...params}
              id="area-txt"
              label="Area"
              placeholder="Search your area e.g. Johar Town"
              variant="outlined"
              error={Boolean(error)}
              helperText={error ? "Please select area" : ""}
            />
          )}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default Area
