import React, { useState, useEffect, useContext } from "react"
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Grid,
  Typography,
  Button,
  CircularProgress
} from "@mui/material"
import { useNavigate } from "react-router-dom"
import {
  deleteSingleData,
  fetchDataWithSearch
} from "offline_database/queries/DbOperations"
import { formatDateTime } from "main/util/DateTimeHelper"
import CustomCard from "main/common/CustomCard"
import Search from "main/common/Search"
import EmptySearch from "main/common/Empty"
import { CREATE_ORDER } from "main/pos/checkout/CheckoutApi"
import { useMutation } from "@apollo/client"
import pulseClient from "graphql/pulse"
import BranchContext from "main/context/branch/BranchContext"
import { BranchContextType } from "main/context/branch/BranchContextTypes"
import { useDispatch } from "react-redux"
import { setFromOrderPage } from "main/context/connection/ConnectionSlice"
import ConnectionHooks from "main/context/connection/ConnectionHook"

const styles = () => ({
  posGoBtn: {
    margin: "20px 20px 0px 80px"
  },
  customCard: {
    marginTop: 0
  }
})

const OfflineOrders = () => {
  const classes = styles()
  const [orderList, setOrderList] = useState([])
  const dispatch = useDispatch()
  const [searchOrder, setSearchOrder] = useState<string>("")
  const [orderNumber, setOrderNumber] = useState("")
  const { isInternetOn } = ConnectionHooks()

  const { getBranchBvid } = useContext(BranchContext) as BranchContextType
  const merchantBvid = getBranchBvid()
  const navigate = useNavigate()

  const deleteOrderAfterSync = async (orderNumber: string) => {
    await deleteSingleData("orderList", "orderNumber", orderNumber)
    await getOfflineOrders()
    setOrderNumber("")
  }

  const [createOrder, { loading: createOrderLoading }] = useMutation(
    CREATE_ORDER,
    {
      client: pulseClient,
      variables: {
        ...(merchantBvid && { merchantBvid: merchantBvid })
      }
    }
  )

  const getOfflineOrders = async () => {
    const list = await fetchDataWithSearch(
      "orderList",
      "orderNumber",
      searchOrder
    )

    if (list) setOrderList(list)
  }

  useEffect(() => {
    getOfflineOrders()
  }, [searchOrder])

  useEffect(() => {
    dispatch(setFromOrderPage(true))
  }, [])

  const sendOrder = async (orderData: any) => {
    setOrderNumber(orderData?.orderNumber)
    delete orderData?.temp
    const orderResponse = await createOrder({ variables: orderData })
    if (orderResponse) {
      await deleteOrderAfterSync(orderData?.orderNumber)
    }
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Button
            size="small"
            color="primary"
            variant="text"
            sx={classes.posGoBtn}
            onClick={() => navigate("/")}
          >
            &lt; POS
          </Button>
        </Grid>
      </Grid>
      <CustomCard customCardClass={classes.customCard}>
        <Grid container>
          <Grid item xs={9}>
            <Typography variant="h2">Orders</Typography>
          </Grid>
          <Grid item xs={3}>
            <Search
              focus
              label="Search"
              searchSize="small"
              placeholder="Eg: Order# ABD-124"
              searchVariant="standard"
              searchValue={searchOrder}
              setSearchValue={setSearchOrder}
            />
          </Grid>
          <Grid item xs={12}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Order#</TableCell>
                  <TableCell>Source</TableCell>
                  <TableCell>Date Time</TableCell>
                  <TableCell>Products</TableCell>
                  <TableCell>Total Amount</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Contact</TableCell>
                  <TableCell>Alternative Contact</TableCell>
                  <TableCell>Address</TableCell>
                </TableRow>
              </TableHead>
              {orderList?.length ? (
                <TableBody>
                  {orderList?.map((order: any, index) => {
                    const {
                      orderNumber: productOrderNumber,
                      orderSource,
                      orderTime,
                      products,
                      temp,
                      customerDetails
                    } = order?.data ?? {}

                    const { name, username, alternatePhoneNumber } =
                      customerDetails?.user ?? {}
                    const { totalAmount, address } = temp ?? {}
                    return (
                      <TableRow key={index}>
                        <TableCell>{productOrderNumber || "-"}</TableCell>
                        <TableCell>{orderSource || "-"}</TableCell>
                        <TableCell>
                          {formatDateTime(orderTime) || "-"}
                        </TableCell>
                        <TableCell>{products?.length || 0}</TableCell>
                        <TableCell>{totalAmount || 0}</TableCell>
                        <TableCell>{name || "-"}</TableCell>
                        <TableCell>{username || "-"}</TableCell>
                        <TableCell>{alternatePhoneNumber || "-"}</TableCell>

                        <TableCell>{address || "-"}</TableCell>
                        <TableCell>
                          {productOrderNumber === orderNumber &&
                          createOrderLoading ? (
                            <CircularProgress />
                          ) : (
                            <Button
                              size="small"
                              color="primary"
                              variant="contained"
                              disabled={!isInternetOn}
                              onClick={() => {
                                sendOrder(order?.data)
                              }}
                            >
                              Sync
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              ) : (
                <></>
              )}
            </Table>
            {!orderList.length ? <EmptySearch /> : <></>}
          </Grid>
        </Grid>
      </CustomCard>
    </>
  )
}

export default OfflineOrders
