import React from "react"
import { Card } from "@mui/material"
import { CustomCardProps } from "main/common/CustomCardTypes"

const styles = () => ({
  box: {
    padding: 2,
    borderRadius: 2,
    margin: 10
  },
  fullWidth: {
    width: "97%"
  }
})

function CustomCard({
  children,
  customCardClass,
  fullWidth = false
}: CustomCardProps) {
  const classes = styles()
  return (
    <Card
      elevation={0}
      sx={{
        ...classes.box,
        ...(fullWidth ? classes.fullWidth : {}),
        ...customCardClass
      }}
    >
      {children}
    </Card>
  )
}

export default React.memo(CustomCard)
