import React, { useContext } from "react"
import { Button, Grid, CircularProgress, Theme } from "@mui/material"
import UserContext from "main/context/user/UserContext"
import { UserContextType } from "main/context/user/UserContextTypes"
import { useTheme } from "@mui/styles"

const styles = (theme: Theme) => ({
  logOutButton: {
    backgroundColor: theme.custom.white,
    marginBottom: 1
  },
  progressBar: {
    marginTop: 2,
    justifyContent: "center"
  }
})

const Logout = () => {
  const classes = styles(useTheme())
  const { logout, logOutLoading } = useContext(UserContext) as UserContextType

  return (
    <Grid item xs={6}>
      {logOutLoading ? (
        <Grid container sx={classes.progressBar}>
          <CircularProgress />
        </Grid>
      ) : (
        <Button
          fullWidth
          size="large"
          variant="outlined"
          color="primary"
          sx={classes.logOutButton}
          onClick={logout}
        >
          Log out
        </Button>
      )}
    </Grid>
  )
}

export default Logout
