import { Button, Grid } from "@mui/material"
import { usePosContext } from "main/hooks/ContextHooks"

const DraftedOrdersButton = () => {
  const { setShowDraftedOrders } = usePosContext()
  const navigateToOpenOrders = () => setShowDraftedOrders(true)
  return (
    <Grid item xs={12}>
      <Button
        fullWidth
        size="large"
        variant="outlined"
        color="primary"
        onClick={navigateToOpenOrders}
      >
        All Open Orders
      </Button>
    </Grid>
  )
}
export default DraftedOrdersButton
