import React, { useContext, useState, useEffect } from "react"
import { PosCustomerProvider } from "main/context/pos/PosCustomer/PosCustomerContext"
import { getCurrentDateTime, addMinutesInTime } from "main/util/DateTimeHelper"
import PosContext from "../PosContext"
import { PosContextType } from "../PosContextTypes"
import BranchContext from "main/context/branch/BranchContext"
import { BranchContextType } from "main/context/branch/BranchContextTypes"
import UserContext from "main/context/user/UserContext"
import { userTypesList } from "main/util/UserHelper"
import { UserContextType } from "main/context/user/UserContextTypes"
import ConnectionHooks from "main/context/connection/ConnectionHook"

const PosCustomerContainer = ({ children }: { children: React.ReactNode }) => {
  const posContext = useContext(PosContext) as PosContextType
  const { isInternetOn } = ConnectionHooks()
  const [customerAccordion, setCustomerAccordion] = useState(true)
  const [orderSource, setOrderSource] = useState("Phone")

  const [customerPhone, setCustomerPhone] = useState("")
  const [alternatePhoneNumber, setAlternatePhoneNumber] = useState("")
  const [customerName, setCustomerName] = useState("")
  const [customerAddressDetails, setCustomerAddressDetails] = useState("")
  const [customerArea, setCustomerArea] = useState<any>({})
  const [customerCity, setCustomerCity] = useState<any>({})
  const [customerAddressList, setCustomerAddressList] = useState([])
  const [customerAddressId, setCustomerAddressId] = useState("")
  const [customerLocation, setCustomerLocation] = useState("")
  const [showCustomerName, setShowCustomerName] = useState(false)
  const [showNewAddress, setShowNewAddress] = useState(false)

  const [showSummary, setShowSummary] = useState(false)
  const [customerPhoneErr, setCustomerPhoneErr] = useState(false)

  const [customerNameErr, setCustomerNameErr] = useState(false)
  const [customerAddressDetailsErr, setCustomerAddressDetailsErr] =
    useState(false)
  const [customerAreaErr, setCustomerAreaErr] = useState(false)
  const [customerCityErr, setCustomerCityErr] = useState(false)
  const [alternatePhoneNumberErr, setAlternatePhoneNumberErr] = useState(false)

  const [orderPickupTimeErr, setOrderPickupTimeErr] = useState(false)
  const [defaultPickupTime, setDefaultPickupTime] = useState(15)
  const [orderPickupTime, setOrderPickupTime] = useState(
    addMinutesInTime(defaultPickupTime) || ""
  )
  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState(false)

  const { isUserRole } = useContext(UserContext) as UserContextType
  const isMasterAdmin = isUserRole(userTypesList.MasterAdmin)
  const isMasterPos = isUserRole(userTypesList.MasterPOS)

  const { fetchMerchantByArea } = useContext(BranchContext) as BranchContextType

  useEffect(() => {
    if (posContext.merchantProfileData) {
      const { defaultPickUpTime } =
        posContext?.merchantProfileData?.config || {}
      setDefaultPickupTime(defaultPickUpTime)
      setOrderPickupTime(addMinutesInTime(defaultPickUpTime || 15) || "")
    }
  }, [posContext.merchantProfileData])
  useEffect(() => {
    if (
      isInternetOn &&
      (isMasterAdmin || isMasterPos) &&
      customerCity &&
      customerArea
    )
      fetchMerchantByArea({
        variables: {
          cityBvid: customerCity?.bvid,
          areaBvid: customerArea?.bvid
        }
      })
    // eslint-disable-next-line
  }, [customerCity, customerArea])

  const checkFields = (isFieldInvalid: any, setState: any) => {
    setState(isFieldInvalid)
    return !isFieldInvalid
  }

  const autoPickAddress = () => {
    const address: any = customerAddressList[customerAddressList?.length - 1]
    setCustomerAddressId(address?.uid)
    setCustomerAddressDetails(address?.details)
    setShowSummary(true)
  }

  const resetPosCustomerValues = () => {
    setOrderSource("Phone")
    setCustomerName("")
    setCustomerPhone("")
    setAlternatePhoneNumber("")
    setEmail("")
    setCustomerAddressDetails("")
    setCustomerAddressList([])
    setCustomerCity("")
    setCustomerArea("")
    setCustomerAddressId("")
    setCustomerLocation("")
    setOrderPickupTime(getCurrentDateTime())
    setCustomerPhoneErr(false)
    setAlternatePhoneNumberErr(false)
    setCustomerNameErr(false)
    setOrderPickupTimeErr(false)
    setShowNewAddress(false)
    setShowSummary(false)
    setShowCustomerName(false)
    setOrderPickupTime(addMinutesInTime(defaultPickupTime))
  }

  const applyPosCustomerValues = (customerValues: any) => {
    setOrderSource(customerValues.orderSource || "Phone")
    setCustomerName(customerValues.customerName || "")
    setCustomerPhone(customerValues.customerPhone || "")
    setAlternatePhoneNumber(customerValues.alternatePhoneNumber || "")
    setEmail(customerValues.email || "")
    setCustomerAddressDetails(customerValues.customerAddressDetails || "")
    setCustomerAddressList(customerValues.customerAddressList || [])
    setCustomerCity(customerValues.customerCity || "")
    setCustomerArea(customerValues.customerArea || "")
    setCustomerAddressId(customerValues.customerAddressId || "")
    setCustomerLocation(customerValues.customerLocation || "")
    setOrderPickupTime(customerValues.orderPickupTime || getCurrentDateTime())
    setCustomerPhoneErr(customerValues.customerPhoneErr || false)
    setAlternatePhoneNumberErr(customerValues.customerPhoneErr || false)
    setCustomerNameErr(customerValues.customerNameErr || false)
    setOrderPickupTimeErr(customerValues.orderPickupTimeErr || false)
    setShowNewAddress(customerValues.showNewAddress || false)
    setShowSummary(customerValues.showSummary || false)
    setShowCustomerName(customerValues.showCustomerName || false)
    setOrderPickupTime(
      addMinutesInTime(customerValues.defaultPickupTime || defaultPickupTime)
    )
  }

  return (
    <PosCustomerProvider
      value={{
        customerAccordion,
        setCustomerAccordion,
        orderSource,
        setOrderSource,
        customerPhone,
        setCustomerPhone,
        customerName,
        setCustomerName,
        customerAddressDetails,
        setCustomerAddressDetails,
        customerArea,
        setCustomerArea,
        customerCity,
        setCustomerCity,
        customerAddressList,
        setCustomerAddressList,
        customerAddressId,
        setCustomerAddressId,
        customerLocation,
        setCustomerLocation,
        showNewAddress,
        setShowNewAddress,
        showSummary,
        setShowSummary,
        showCustomerName,
        setShowCustomerName,
        orderPickupTime,
        setOrderPickupTime,
        customerPhoneErr,
        setCustomerPhoneErr,
        alternatePhoneNumber,
        setAlternatePhoneNumber,
        customerNameErr,
        setCustomerNameErr,
        customerAddressDetailsErr,
        setCustomerAddressDetailsErr,
        customerAreaErr,
        setCustomerAreaErr,
        customerCityErr,
        setCustomerCityErr,
        autoPickAddress,
        orderPickupTimeErr,
        setOrderPickupTimeErr,
        resetPosCustomerValues,
        applyPosCustomerValues,
        checkFields,
        email,
        setEmail,
        emailError,
        setEmailError,
        setAlternatePhoneNumberErr,
        alternatePhoneNumberErr
      }}
    >
      {children}
    </PosCustomerProvider>
  )
}

export default PosCustomerContainer
