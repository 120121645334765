import React from "react"
import { Button, Grid, Theme } from "@mui/material"
import { useTheme } from "@mui/styles"
import { useNavigate } from "react-router-dom"
const styles = (theme: Theme) => ({
  clearOrderButton: {
    backgroundColor: theme.custom.white
  }
})

const OrdersButton = () => {
  const classes = styles(useTheme())
  const navigate = useNavigate()

  const navigateToOrders = () => navigate("/orders")
  return (
    <Grid item xs={6}>
      <Button
        fullWidth
        size="large"
        variant="outlined"
        color="primary"
        sx={classes.clearOrderButton}
        onClick={navigateToOrders}
      >
        Offline Orders
      </Button>
    </Grid>
  )
}
export default OrdersButton
