import React, { forwardRef } from "react"
import { Grid } from "@mui/material"
import QRCode from "react-qr-code"
import withStyles from "@mui/styles/withStyles"
import RowItem from "main/pos/receipt/common/RowItem"
import fbrLogo from "assets/images/logos/fbr_logo.png"
import srbLogo from "assets/images/logos/srb_logo.png"
import praLogo from "assets/images/logos/pra_logo.png"
import { getActiveRevenueIntegration } from "utils/fbrHelpers"

const styles = () => ({
  fbrLogo: {
    width: 80
  }
})

const FBRReceiptDetails = ({
  fbrInvoiceNo,
  merchantIntegrations
}: {
  fbrInvoiceNo: string
  merchantIntegrations: {
    partner: string
    status: string
  }[]
}) => {
  const classes = styles()

  const activeRevenueIntegration =
    getActiveRevenueIntegration(merchantIntegrations)?.partner

  const partnerLogo: Record<string, string> = {
    Fbr: fbrLogo,
    Srb: srbLogo,
    Pra: praLogo
  }

  return (
    <>
      <Grid item xs={12} textAlign="center">
        <RowItem
          labelBold
          fontClass="fontNormal"
          label={`${activeRevenueIntegration?.toUpperCase()} Invoice #`}
        />
      </Grid>
      <Grid item xs={12} textAlign="center">
        <RowItem labelBold fontClass="fontNormal" label={fbrInvoiceNo} />
      </Grid>
      <Grid my={2} item xs={6} textAlign="center">
        <img
          src={partnerLogo[activeRevenueIntegration]}
          alt="logo"
          style={classes.fbrLogo}
        />
      </Grid>
      <Grid my={2} item xs={6} textAlign="center">
        <QRCode value={fbrInvoiceNo || ""} size={80} />
      </Grid>
    </>
  )
}

export default withStyles(styles)(forwardRef(FBRReceiptDetails))
