import React, { Grid, Typography, Theme } from "@mui/material"
import { useTheme } from "@mui/styles"
import ChikooLogo from "assets/images/logos/chikoo_orange.png"

const styles = (theme: Theme) => ({
  main: {
    textAlign: "center",
    marginTop: 16
  },
  image: {
    width: "30%",
    height: "auto",
    padding: 8
  },
  text: {
    margin: "auto 12",
    color: theme.palette.secondary.main
  }
})
const Reconnect = ({ image }: any) => {
  const classes = styles(useTheme())

  return (
    <Grid container alignItems="center" direction="column" sx={classes.main}>
      <Grid item xs={6}>
        <img
          style={{ ...classes.image }}
          src={image ? image : ChikooLogo}
          alt="Page not found"
        />

        <Typography sx={classes.text} variant="h6">
          You have logged out of the Point of Sale. To log back in, Please click
          the Create Order button on Chikoo web.
        </Typography>
      </Grid>
    </Grid>
  )
}

export default Reconnect
