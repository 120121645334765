const debounceHelper = (
  fn: { apply: (_this: any, _args: any) => void },
  ms = 300
) => {
  let timeoutId: ReturnType<typeof setTimeout>
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => fn.apply(this, args), ms)
  }
}

export default debounceHelper
