import React, { useContext, useState, useEffect } from "react"
import { gql, useLazyQuery } from "graphql/apollo"
import {
  Button,
  CircularProgress,
  Grid,
  Theme,
  Typography
} from "@mui/material"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import CancelOutlined from "@mui/icons-material/CancelOutlined"
import { useTheme } from "@mui/styles"
import SimpleDialog from "main/common/simple_dialog/SimpleDialog"
import { getFromStorage, removeFromStorage } from "main/util/LocalStorageHelper"
import BranchContext from "main/context/branch/BranchContext"
import { BranchContextType } from "main/context/branch/BranchContextTypes"
import { modifyData } from "offline_database/queries/DbOperations"
import { useDispatch } from "react-redux"
import { setProductSync } from "main/context/connection/ConnectionSlice"
import ConnectionHooks from "main/context/connection/ConnectionHook"
const styles = (theme: Theme) => ({
  posSyncButton: {
    backgroundColor: theme.custom.white
  },
  icon: {
    fontSize: 56
  }
})

const ProductSyncButton = () => {
  const classes = styles(useTheme())
  const { isInternetOn } = ConnectionHooks()
  const { getBranchBvid } = useContext(BranchContext) as BranchContextType
  const dispatch = useDispatch()
  const merchantBvid = getBranchBvid()
  const [openSyncing, setOpenSyncing] = useState(false)
  const [syncSuccess, setSyncSuccess] = useState(true)
  const [error, setError] = useState("")

  const updateProducts = async (product: any) => {
    await modifyData("productList", "bvid", product.bvid, product)
  }
  const [fetchUsedProducts, { loading }] = useLazyQuery(SYNC_PRODUCTS, {
    fetchPolicy: "network-only",
    onError: () => setSyncSuccess(false),
    onCompleted: (data) => {
      if (data?.syncProductsQuantity) {
        data?.syncProductsQuantity?.forEach((product: { bvid: string }) => {
          updateProducts(product)
        })
        removeFromStorage("productsBvids")
      }
      dispatch(setProductSync(true))
    }
  })

  useEffect(() => {
    if (openSyncing) {
      setProductSync(false)
      setError("")
      const productBvids = getFromStorage("productsBvids") || []
      if (productBvids.length)
        fetchUsedProducts({
          variables: {
            productBvids,
            ...(merchantBvid && { merchantXid: merchantBvid })
          }
        })
      else {
        setError("No products to sync")
      }
    }
  }, [openSyncing])

  return (
    <>
      {isInternetOn ? (
        <Grid item xs={6}>
          <Button
            fullWidth
            size="large"
            color="primary"
            variant="outlined"
            sx={classes.posSyncButton}
            onClick={() => setOpenSyncing(true)}
          >
            Sync Quantity
          </Button>
        </Grid>
      ) : (
        <></>
      )}
      <SimpleDialog
        fullWidth
        allowActions
        maxWidth="sm"
        title={<Typography variant="h5">Syncing</Typography>}
        dialogOpen={openSyncing}
        onHandleClose={() => setOpenSyncing(false)}
        handleOk={() => setOpenSyncing(false)}
        disabled={loading}
        okButton="Close"
        body={
          <Grid container spacing={4}>
            <Grid item xs={12} textAlign="center">
              {loading ? (
                <>
                  <CircularProgress />
                  <Typography align="center" component="p">
                    Please wait, Syncing is in process
                  </Typography>
                </>
              ) : (
                <>
                  {syncSuccess && !error ? (
                    <>
                      <CheckCircleOutlineIcon
                        color="success"
                        sx={classes.icon}
                      />
                      <Typography align="center" component="p">
                        Syncing has completed.
                      </Typography>
                    </>
                  ) : (
                    <>
                      <CancelOutlined color="error" sx={classes.icon} />
                      <Typography align="center" component="p">
                        {error ? error : " Syncing failed, Try again"}
                      </Typography>
                    </>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        }
      />
    </>
  )
}

export default ProductSyncButton

export const SYNC_PRODUCTS = gql`
  query syncProductsQuantity($productBvids: [String!]!, $merchantXid: String) {
    syncProductsQuantity(
      productBvids: $productBvids
      merchantXid: $merchantXid
    ) {
      pctCode
      posId
      gtin
      name
      bvid
      barcode
      uid
      productStatus
      productType
      price
      basePrice
      discountedBasePrice
      barcode
      batchSize
      discountedPrice
      quantity
      shortUrl
      categoryBvid
      subcategoryBvid
      images {
        thumbnailUrl
        isHero
      }
      productIntegrations {
        integrationId
        integrationType
        integrationCode
        price
        discountedPrice
      }
      variantOptions {
        bvid
        title
        isActive
        variantOptionValues {
          bvid
          title
          sortOrder
          isActive
        }
      }
      productLots {
        bvid
        expiry
        lotCode
        quantityInside
        price
        gstValue
        customField1
        customField2
        customField3
        status
      }
      variants {
        bvid
        isActive
        variantPrice
        discountedPrice
        variantOptionValues {
          bvid
          title
          isActive
          sortOrder
        }
      }
      batches {
        batchName
        quantity
        discountedPrice
        price
        batchCode
        expiry
        batchProductBvid
      }
      batchQuantity
      mainOptions {
        bvid
        title
        sortOrder
        isMandatory
        isMultiSelect
        isActive
        quantity
        mainOptionValues {
          bvid
          title
          sortOrder
          optionPrice
          discountedPrice
          isActive
          maxAllowed
          productIntegrations {
            integrationId
            integrationType
            integrationCode
            price
            discountedPrice
          }
          subOptions {
            bvid
            title
            sortOrder
            isMandatory
            isMultiSelect
            isActive
            subOptionValues {
              bvid
              title
              sortOrder
              optionPrice
              discountedPrice
              isActive
              productIntegrations {
                integrationId
                integrationType
                integrationCode
                price
                discountedPrice
              }
            }
          }
        }
      }
    }
  }
`
