import React, { useContext } from "react"
import { Grid, List, ListItem, ListItemText, ListItemIcon } from "@mui/material"
import PosCustomerContext from "main/context/pos/PosCustomer/PosCustomerContext"
import { PosCustomerType } from "main/context/pos/PosCustomer/PosCustomerTypes"
import PosProductContext from "main/context/pos/PosProduct/PosProductContext"
import { PosProductType } from "main/context/pos/PosProduct/PosProductTypes"
import PosContext from "main/context/pos/PosContext"
import { PosContextType } from "main/context/pos/PosContextTypes"
import { OrderTypes } from "main/pos/constant/PosConstant"
import { formatDateTime, formatSimpleDateTime } from "main/util/DateTimeHelper"
import AccessTimeIcon from "@mui/icons-material/AccessTime"
import CampaignIcon from "@mui/icons-material/Campaign"
import PermIdentityIcon from "@mui/icons-material/PermIdentity"
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone"
import PinDropIcon from "@mui/icons-material/PinDrop"
import emailIcon from "assets/images/icons/email.svg"
import AccessTime from "@mui/icons-material/AccessTime"

const styles = () => ({
  noSpace: {
    padding: 0
  },
  listIcon: {
    minWidth: "32px"
  }
})

const DetailsSummary = () => {
  const classes = styles()
  const posContext = useContext(PosContext) as PosContextType
  const customerContext = useContext(PosCustomerContext) as PosCustomerType
  const productContext = useContext(PosProductContext) as PosProductType

  const {
    orderSource,
    customerName,
    customerPhone,
    alternatePhoneNumber,
    orderPickupTime,
    customerAddressDetails,
    customerArea,
    customerCity,
    email
  } = customerContext

  function getCommonSummary(summary: any) {
    const emailDetails = [
      {
        icon: <img src={emailIcon} alt="email" width="24px" />,
        value: email
      }
    ]
    if (productContext.isDigitalProductInCart()) {
      return [...summary, ...emailDetails]
    }
    return summary
  }

  const getSummary = () => {
    const summary = [
      {
        icon: <PermIdentityIcon />,
        value: customerName
      },
      {
        icon: <PhoneIphoneIcon />,
        value: customerPhone
      }
    ]

    if (posContext.orderType === OrderTypes.Delivery) {
      return deliverySummary(getCommonSummary(summary))
    } else if (posContext.orderType === OrderTypes.PickUp) {
      return pickUpSummary(getCommonSummary(summary))
    } else if (posContext.orderType === OrderTypes.WalkIn) {
      return getCommonSummary(summary)
    }
  }

  const deliverySummary = (commonSummary: object[]) => {
    const addressDetails = `${customerAddressDetails}, ${
      customerArea?.name || customerArea?.data?.name
    }, ${customerCity?.name}`
    const summary = [
      {
        icon: <CampaignIcon />,
        value: orderSource
      },
      ...commonSummary,
      {
        icon: <PhoneIphoneIcon />,
        value: alternatePhoneNumber
      },
      {
        icon: <PinDropIcon />,
        value:
          !customerContext.showNewAddress && customerAddressDetails
            ? customerAddressDetails
            : addressDetails
      },
      {
        ...(posContext?.isDeliveryLater && {
          icon: <AccessTime />,
          value: formatDateTime(posContext?.deliveryLaterTiming?.toString())
        })
      }
    ]
    return summary
  }

  const pickUpSummary = (commonSummary: object[]) => {
    const newDate = formatSimpleDateTime(orderPickupTime)
    const summary = [
      {
        icon: <CampaignIcon />,
        value: orderSource
      },
      ...commonSummary,
      {
        icon: <PhoneIphoneIcon />,
        value: alternatePhoneNumber
      },
      {
        icon: <AccessTimeIcon />,
        value: newDate
      }
    ]
    return summary
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <List sx={classes.noSpace}>
          {getSummary()?.map((detail: any) => (
            <ListItem sx={classes.noSpace} key={detail.value}>
              <ListItemIcon sx={classes.listIcon}>{detail.icon}</ListItemIcon>
              <ListItemText primary={detail.value || (detail.icon && "-")} />
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  )
}

export default DetailsSummary
