import React from "react"
import { TextField, Typography } from "@mui/material"

const SpecialInstruction = ({
  value,
  handleChange
}: {
  value: string
  handleChange: React.Dispatch<React.SetStateAction<any>>
}) => {
  return (
    <>
      <Typography component="h3">Special Instructions</Typography>
      <TextField
        fullWidth
        multiline
        placeholder="Special Instructions by customer"
        variant="outlined"
        minRows={3}
        maxRows={4}
        value={value}
        id="special-instructions-text"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleChange(e.target.value)
        }
      />
    </>
  )
}
export default SpecialInstruction
