import React, { useEffect, useRef, useState } from "react"
import { UserProvider } from "main/context/user/UserContext"
import { gql, useMutation } from "graphql/apollo"
import { useLocation, useNavigate } from "react-router-dom"
import jwt_decode, { JwtPayload } from "jwt-decode"
import {
  getFromStorage,
  removeFromStorage,
  addToStorage
} from "main/util/LocalStorageHelper"
import { decryptCrypto } from "main/util/CryptoHelper"
import pulseClient from "graphql/pulse"

const UserContainer = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState(getFromStorage("user"))
  const [userInfo, setUserInfo] = useState(getFromStorage("user_info"))
  const [loginLoading, setLoginLoading] = useState(true)

  const paramsRef = useRef(true)
  const userInfoRef = useRef(true)
  const queryParams = useLocation()?.search
  const navigate = useNavigate()
  const query = "?userInfo="

  const [logoutUser, { loading: logOutLoading }] = useMutation(LOGOUT, {
    client: pulseClient
  })

  const [loginMutation] = useMutation(LOGIN_MERCHANT, {
    client: pulseClient,
    notifyOnNetworkStatusChange: true,
    onCompleted: () => setLoginLoading(false)
  })

  const loginUser = async (userInfo: any) => {
    const response = await loginMutation({
      variables: {
        username: userInfo?.username,
        password: userInfo?.pin
      }
    })
    if (response?.data) {
      addToStorage("user", response?.data?.loginUser)
      setUser(response?.data?.loginUser)
    }
  }

  const gettingParamsAndLogin = () => {
    if (queryParams.includes(query)) {
      const userInfo = queryParams.replace(query, "")
      removeFromStorage("user")
      loginUser(JSON.parse(decryptCrypto(userInfo)))
      addToStorage("user_info", userInfo)
      setUserInfo(userInfo)
    }
  }

  useEffect(() => {
    if (paramsRef.current) gettingParamsAndLogin()
    paramsRef.current = false
  }, [queryParams])

  useEffect(() => {
    if (userInfo && userInfoRef.current && !queryParams.includes(query)) {
      const decryptUserInfo = JSON.parse(decryptCrypto(userInfo))
      removeFromStorage("user")
      loginUser(decryptUserInfo)
      userInfoRef.current = false
    }
  }, [userInfo])

  const updateUser = async (updatedUser: object) => {
    if (updatedUser) {
      await addToStorage("user", updatedUser)
      setUser(updatedUser)
    }
  }

  const getName = () => {
    return `${user.user.firstName} ${user.user.lastName} `
  }

  const isAuthenticated = () => {
    const user = getFromStorage("user")
    const token = user ? user.token : ""
    if (token) {
      const exp: any = jwt_decode<JwtPayload>(token)
      const date: any = Date.now()
      return !(+date >= exp * 1000)
    }
    return false
  }

  const removeUser = () => {
    removeFromStorage("user")
    removeFromStorage("user_info")
    setUser(null)
  }

  const logout = async () => {
    try {
      await logoutUser({
        variables: {
          deviceId: user?.user?.deviceId || ""
        }
      })
    } catch (err) {
      console.error(err)
    }

    removeUser()
    navigate("/reconnect")
  }

  const userData = user?.user?.userMaster || user?.user?.userMerchant

  const getSlug = () => {
    return userData ? userData?.slug : ""
  }

  const getUser = () => {
    return user?.user?.username
  }

  function isItMeriPharmacy() {
    const slug = getSlug()
    if (slug === "meripharmacy") return true
    return false
  }

  function getMeriPharmacyWebsite() {
    if (isItMeriPharmacy()) return "https://meripharmacy.pk"
    return ""
  }

  const getWebsite = () => {
    if (getSlug())
      return userData.hasDomain
        ? `https://${getSlug()}`
        : `https://${getSlug()}.chikoo.app`
    return ""
  }

  function isUserRole(thisRole: string) {
    return user?.user?.role === thisRole
  }
  function getUserMerchantBvid() {
    return user?.user?.userMerchant?.bvid
  }

  function getUserMasterBvid() {
    return user?.user?.userMaster?.bvid
  }

  return (
    <UserProvider
      value={{
        user,
        updateUser,
        getWebsite,
        getName,
        getSlug,
        getUser,
        removeUser,
        isAuthenticated,
        logout,
        isUserRole,
        getUserMerchantBvid,
        getUserMasterBvid,
        logOutLoading,
        loginLoading,
        getMeriPharmacyWebsite,
        isItMeriPharmacy
      }}
    >
      {children}
    </UserProvider>
  )
}

export default UserContainer

const LOGIN_MERCHANT = gql`
  mutation loginUser($username: String!, $password: String, $deviceId: String) {
    loginUser(username: $username, password: $password, deviceId: $deviceId) {
      token
      user {
        bvid
        firstName
        lastName
        username
        role
        userMerchant {
          bvid
          storeName
          slug
          tld
          hasDomain
        }
        userMaster {
          bvid
          name
          tld
          slug
          hasDomain
        }
      }
    }
  }
`

const LOGOUT = gql`
  mutation logout($deviceId: String!) {
    logout(deviceId: $deviceId)
  }
`
