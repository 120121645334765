import React, { useEffect, useContext } from "react"
import { Grid, TextField, Typography } from "@mui/material"
import { useLazyQuery, gql } from "@apollo/client"
import spaceBridgeClient from "graphql/SpaceBridge"
import PosContext from "main/context/pos/PosContext"
import { PosContextType } from "main/context/pos/PosContextTypes"
import PosProductContext from "main/context/pos/PosProduct/PosProductContext"
import { PosProductType } from "main/context/pos/PosProduct/PosProductTypes"

const BinDiscountFields = () => {
  const {
    merchantBinDiscounts,
    binDiscount,
    setBinNumber,
    binNumber,
    setBinDiscount,
    setBinBvid,
    paymentMethod
  } = useContext(PosContext) as PosContextType
  const productContext = useContext(PosProductContext) as PosProductType

  const [calculateBinDiscount] = useLazyQuery(CALCULATE_BIN_DISCOUNT, {
    client: spaceBridgeClient,
    fetchPolicy: "network-only"
  })

  const handleBinDiscount = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBinNumber(e.target.value)
  }

  const resetBinDiscount = () => {
    setBinDiscount(0)
    setBinNumber("")
    setBinBvid("")
  }

  useEffect(() => {
    let isBinavailable: {
      [key: string]: any
    } = {}
    if (binNumber.length >= 6) {
      isBinavailable =
        merchantBinDiscounts.find(
          (item: { binNo: string }) => item.binNo === binNumber.slice(0, 6)
        ) || {}
      if (isBinavailable?.binBvid) {
        calculateBinDiscount({
          variables: {
            binBvid: isBinavailable.binBvid,
            orderAmount: parseInt(productContext.getProductsSubTotal())
          },
          onCompleted: (data) => {
            if (data.calculateBinDiscount) {
              setBinDiscount(data?.calculateBinDiscount)
              setBinBvid(isBinavailable?.binBvid)
            }
          }
        })
      }
    } else {
      setBinDiscount(0)
      setBinBvid("")
    }
  }, [binNumber])

  useEffect(() => {
    resetBinDiscount()

    return () => {
      resetBinDiscount()
    }
  }, [paymentMethod])

  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Enter the initial 6 digits of your card"
          name="bin_no"
          id="bin-no"
          type="text"
          autoComplete="off"
          variant="outlined"
          inputProps={{ maxLength: 6 }}
          value={binNumber}
          onChange={handleBinDiscount}
        />
        {binDiscount ? (
          <Grid p={2}>
            <Typography>
              - Discount Available {binDiscount?.discountPercentage}%
            </Typography>
            <Typography>
              - Max Discount available {binDiscount?.maxDiscount}
            </Typography>
            <Typography>- Min Order Value {binDiscount?.minOrder}</Typography>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  )
}

export default BinDiscountFields

const CALCULATE_BIN_DISCOUNT = gql`
  query calculateBinDiscount($binBvid: ID!, $orderAmount: Float!) {
    calculateBinDiscount(binBvid: $binBvid, orderAmount: $orderAmount) {
      discountedAmount
      discountPercentage
      maxDiscount
      minOrder
    }
  }
`
