import React, { useEffect } from "react"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import ButtonGroup from "@mui/material/ButtonGroup"
import Grid from "@mui/material/Grid"
import { TimeField as Time } from "@mui/x-date-pickers/TimeField"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import moment from "moment"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { Theme } from "@mui/material"
import { useTheme } from "@mui/styles"
import { TimeFieldPropsType } from "main/common/date_time/TimeFieldTypes"

const createStyles = (theme: Theme) => ({
  fieldSizes: {
    "& .MuiInputBase-input": {
      fontSize: 32,
      textAlign: "center",
      padding: 1,
      fontWeight: "bold"
    }
  },
  activeButton: {
    backgroundColor: `${theme?.palette?.primary?.main}30`
  }
})

const TimeField = ({
  defaultTime,
  hours,
  setHours,
  minutes,
  setMinutes,
  meridiem,
  setMeridiem
}: TimeFieldPropsType) => {
  const classes = createStyles(useTheme())
  useEffect(() => {
    const date: Date = defaultTime ? new Date(defaultTime) : new Date()
    setMeridiem(date.getHours() > 12 ? "PM" : "AM")
    setHours(moment(date))
    setMinutes(moment(date))
  }, [])

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Grid container justifyContent="center">
        <Grid item xs={4}>
          <Time
            fullWidth
            sx={classes.fieldSizes}
            value={hours}
            format="hh"
            onChange={(newValue: any) => {
              setHours(newValue)
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Grid item alignSelf="center" textAlign="center">
            <Typography variant="h4">:</Typography>
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <Time
            fullWidth
            sx={classes.fieldSizes}
            format="mm"
            value={minutes}
            onChange={(newValue: any) => setMinutes(newValue)}
          />
        </Grid>
        <Grid item xs={2} alignSelf="center" textAlign="center">
          <ButtonGroup orientation="vertical" size="small">
            <Button
              key="one"
              variant="outlined"
              color="secondary"
              sx={meridiem === "AM" ? classes.activeButton : {}}
              onClick={() => setMeridiem("AM")}
            >
              AM
            </Button>
            <Button
              key="two"
              variant="outlined"
              color="secondary"
              sx={meridiem === "PM" ? classes.activeButton : {}}
              onClick={() => setMeridiem("PM")}
            >
              PM
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    </LocalizationProvider>
  )
}

export default TimeField
