export const extractError = (error: any) => {
  if (error.message) {
    let message = error.message.split(":")

    if (message[0].indexOf("GraphQL") !== -1)
      message = message[message.length - 1]
    else message = error.message

    return message.replace(/"/g, "")
  }
  return null
}
