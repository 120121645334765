import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "redux/Store"
import { ConnectionSliceTypes, setFromOrderPage } from "./ConnectionSlice"
import { checkOnlineStatus } from "main/util/ConnectionHelper"
import debounceHelper from "main/util/DebounceHelper"

const ConnectionHooks = () => {
  const dispatch = useDispatch()

  const contextContent = useSelector(
    (state: RootState) => state?.connection
  ) as ConnectionSliceTypes

  useEffect(() => {
    checkOnlineStatus(dispatch)
    if (!contextContent.isInternetOn) dispatch(setFromOrderPage(false))
  }, [contextContent.isInternetOn])

  useEffect(() => {
    window.addEventListener("mousemove", checkInternetConnection)
    return () =>
      window.removeEventListener("mousemove", checkInternetConnection)
  }, [])

  const checkInternetConnection = useCallback(
    debounceHelper(() => {
      checkOnlineStatus(dispatch)
    }, 1000),
    []
  )

  return {
    FBRPosId: contextContent.FBRPosId,
    backgroundSync: contextContent.backgroundSync,
    fromOrderPage: contextContent.fromOrderPage,
    productSync: contextContent.productSync,
    isInternetOn: contextContent.isInternetOn,
    firstSync: contextContent.firstSync
  }
}

export default ConnectionHooks
