import { SyntheticEvent, useEffect, memo, useState } from "react"
import { Autocomplete, TextField, Typography } from "@mui/material"
import { fetchDataWithSearch } from "offline_database/queries/DbOperations"
import {
  SelectedCityType,
  CityTypes
} from "main/common/new_address/city/CityTypes"
import ConnectionHooks from "main/context/connection/ConnectionHook"

const City = ({
  selectedCity,
  setSelectedCity,
  setSelectedArea,
  error,
  setCityError
}: CityTypes) => {
  const { isInternetOn } = ConnectionHooks()
  const [offlineCities, setOfflineCities] = useState<SelectedCityType[]>([])

  const fetchOfflineCities = async () => {
    const cityList = await fetchDataWithSearch("cityList")
    setOfflineCities(cityList)
  }

  useEffect(() => {
    fetchOfflineCities()
    // eslint-disable-next-line
  }, [isInternetOn])

  const handleCity = (
    e: SyntheticEvent<Element, Event>,
    selectedCity: SelectedCityType | null
  ) => {
    setSelectedArea({})
    setSelectedCity(selectedCity)
    setCityError(false)
  }

  return (
    <>
      {offlineCities?.length ? (
        <Autocomplete
          fullWidth
          options={offlineCities}
          getOptionLabel={(option) => option?.name ?? ""}
          value={selectedCity}
          onChange={handleCity}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.bvid}>
                {option.name}
              </li>
            )
          }}
          renderInput={(params) => (
            <TextField
              required
              {...params}
              label="City"
              id="city-txt"
              placeholder="Enter your city"
              variant="outlined"
              error={Boolean(error)}
              helperText={error ? "Select your city" : ""}
            />
          )}
        />
      ) : (
        <Typography textAlign="center">No city available.</Typography>
      )}
    </>
  )
}

export default memo(City)
