import React, { useContext, useState } from "react"
import PosProductContext from "main/context/pos/PosProduct/PosProductContext"
import { PosProductType } from "main/context/pos/PosProduct/PosProductTypes"
import { MenuItem, TextField } from "@mui/material"
import {
  extractProductData,
  isQuantityOverLotSize
} from "main/pos/helpers/ProductHelpers"
import moment from "moment"
import { checkPastDateTime } from "main/util/DateTimeHelper"
import BranchContext from "main/context/branch/BranchContext"
import { BranchContextType } from "main/context/branch/BranchContextTypes"
import { increaseLotFullQuantity } from "../helpers/ProductLotsHelper"
import { STATUS } from "constant/Constants"

const LotsDialogBody = () => {
  const productContext = useContext(PosProductContext) as PosProductType
  const { getStoreBvid } = useContext(BranchContext) as BranchContextType

  const { handleProduct, selectedProduct, setOpenLotsDialog } = productContext
  const [error, setError] = useState("")

  const handleLot = (e: any) => {
    const selectedLot = e?.target?.value
    if (
      isQuantityOverLotSize(
        selectedProduct,
        `${selectedProduct.barcode}~${selectedLot.lotCode}`,
        productContext.selectedProductsList
      )
    ) {
      setError("Lot quantity limit exceeded.")
      return
    } else if (checkPastDateTime(selectedLot?.expiry)) {
      setError("Lot expired")
      return
    }

    handleProduct(
      extractProductData(selectedProduct, {
        selectedLot: [increaseLotFullQuantity(getStoreBvid(), selectedLot)]
      })
    )
    setOpenLotsDialog(false)
  }
  return (
    <TextField
      select
      fullWidth
      variant="outlined"
      label="Product Lots"
      helperText={error}
      error={!!error}
      onChange={handleLot}
      sx={{ mt: 1 }}
    >
      {selectedProduct?.productLots?.map((lots: any, index: number) => {
        return parseInt(lots?.quantityInside) &&
          lots?.status === STATUS.Active ? (
          <MenuItem key={index} value={lots}>
            {`Code:${lots?.lotCode}, Price:${lots?.price} - Quantity: ${
              lots?.quantityInside
            } - Expiry: ${moment(lots?.expiry).format("DD-MMMM-YY")} `}
          </MenuItem>
        ) : (
          <></>
        )
      })}
    </TextField>
  )
}
export default LotsDialogBody
