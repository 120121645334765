class ErrorAccumulator {
  #callback: null | ((_value: string | object) => void) | undefined

  setCallback(callback: (_value: string | object) => void) {
    this.#callback = callback
  }

  clearCallback() {
    this.#callback = null
  }

  call(error: object) {
    if (this.#callback) {
      this.#callback(error)
    }
  }
}

export const eAccumulator = new ErrorAccumulator()
