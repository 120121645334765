const payWithCard = async (amount = 0) => {
  const totalAmount = 2000000000000000 + amount * 1000
  try {
    if ("serial" in navigator) {
      const filters = [{ usbVendorId: 1478 }]
      const port = await (navigator as any).serial.requestPort({ filters })

      await port.open({ baudRate: 7989 })
      const encoder = new TextEncoder()
      const writer = port.writable.getWriter()
      writer.write(encoder.encode(`0${totalAmount}`))

      await writer.close()
      while (port.readable) {
        const reader = port.readable.getReader()
        let isReadingDone = false
        try {
          while (!isReadingDone) {
            const { value, done } = await reader.read()

            if (done) {
              isReadingDone = true
              break
            }
            return new TextDecoder().decode(value)
          }
        } catch (error) {
          console.error(error)
        } finally {
          reader.releaseLock()
          await port.close()
        }
      }
    }
  } catch {
    console.error("Swipe card payment error")
  }
}

export default payWithCard
