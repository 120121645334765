import React from "react"
import { Grid, Typography } from "@mui/material"
import { Theme } from "@mui/material"
import { useTheme } from "@mui/styles"
import SimpleDialog from "./simple_dialog/SimpleDialog"
import { ConfirmDialogProps } from "main/common/ConfirmDialogTypes"

const styles = (theme: Theme) => ({
  message: {
    fontWeight: 600
  },
  confirmButton: {
    backgroundColor: theme.custom.red,
    "&:hover": {
      backgroundColor: theme.custom.red,
      opacity: 0.7
    }
  }
})

const ConfirmDialog = ({
  title,
  message,
  onConfirm,
  openDialog,
  closeDialog,
  disabled = false
}: ConfirmDialogProps) => {
  const classes = styles(useTheme())

  return (
    <SimpleDialog
      disabled={disabled}
      allowActions
      fullWidth
      closeButton
      maxWidth="sm"
      title={<Typography variant="h5">{title}</Typography>}
      dialogOpen={openDialog}
      onHandleClose={() => closeDialog(false)}
      handleCancel={() => closeDialog(false)}
      handleOk={onConfirm}
      okButton="Confirm"
      confirmButtonClass={classes.confirmButton}
      cancelButton="Cancel"
      body={
        <Grid container>
          <Grid item xs={12}>
            <Typography align="center" component="p" sx={classes.message}>
              {message}
            </Typography>
          </Grid>
        </Grid>
      }
    />
  )
}
export default ConfirmDialog
