import { configureStore } from "@reduxjs/toolkit"
import ConnectionReducer from "../main/context/connection/ConnectionSlice"

export const store = configureStore({
  reducer: {
    connection: ConnectionReducer
  }
})
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
