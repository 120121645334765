import { find } from "lodash-es"
import RouteHelpers from "utils/RouteHelpers"

const ImageHelpers = {
  getImage(product: any) {
    if (product?.images?.length) {
      const heroImage = find(product.images, { isHero: true })
      return heroImage ? heroImage : product.images?.[0]
    }
    return null
  },
  getImageUrl(product: any, logoImage: any) {
    const image = this.getImage(product)
    const partner = RouteHelpers.getWhiteLabelPartner()
    if (partner) {
      return image?.thumbnailUrl || logoImage || partner.logo
    }
  }
}

export default ImageHelpers
