import React, { useContext, useEffect, useState } from "react"
import Button from "@mui/material/Button"
import { Grid, Theme } from "@mui/material"
import Typography from "@mui/material/Typography"
import useTheme from "@mui/styles/useTheme"
import SimpleDialog from "main/common/simple_dialog/SimpleDialog"
import UserContext from "main/context/user/UserContext"
import { UserContextType } from "main/context/user/UserContextTypes"
import {
  formatSimpleDateTime,
  getCurrentDateTime
} from "main/util/DateTimeHelper"
import { userTypesList } from "main/util/UserHelper"
import ShiftForm from "main/pos/shifts/ShiftForm"
import { getFromStorage, keys } from "main/util/LocalStorageHelper"
import PosContext from "main/context/pos/PosContext"
import { PosContextType } from "main/context/pos/PosContextTypes"

const styles = (theme: Theme) => ({
  shiftButtons: {
    backgroundColor: theme.custom.white
  }
})

const ShiftButtons = () => {
  const { isUserRole } = useContext(UserContext) as UserContextType
  const { isShiftStarted, setIsShiftStarted } = useContext(
    PosContext
  ) as PosContextType
  const classes = styles(useTheme())
  const [dialog, setDialog] = useState(false)

  const isShiftUser = isUserRole(userTypesList.ShiftUser)

  useEffect(() => {
    getShiftFromStorage()
  }, [dialog])

  const getShiftFromStorage = () => {
    const shiftInfo = getFromStorage(keys.SHIFT)

    if (shiftInfo && Object.hasOwn(shiftInfo, "shiftBvid")) {
      setIsShiftStarted(true)
    }
  }

  const openDialog = () => {
    setDialog(true)
  }

  return (
    <>
      {isShiftUser ? (
        <Grid item xs={6}>
          {isShiftStarted ? (
            <Button
              fullWidth
              size="large"
              variant="outlined"
              color="primary"
              sx={classes.shiftButtons}
              onClick={openDialog}
            >
              End Shift
            </Button>
          ) : (
            <Button
              fullWidth
              size="large"
              variant="outlined"
              color="primary"
              sx={classes.shiftButtons}
              onClick={openDialog}
            >
              Start Shift
            </Button>
          )}
        </Grid>
      ) : (
        <></>
      )}
      {isShiftUser ? (
        <SimpleDialog
          fullWidth
          closeButton
          maxWidth="xs"
          dialogOpen={dialog}
          onHandleClose={() => setDialog(false)}
          handleCancel={() => setDialog(false)}
          title={
            <Typography variant="h5">
              {formatSimpleDateTime(getCurrentDateTime())}
            </Typography>
          }
          body={
            <ShiftForm
              dialog={dialog}
              setDialog={setDialog}
              isShiftStarted={isShiftStarted}
              setIsShiftStarted={setIsShiftStarted}
            />
          }
        />
      ) : (
        <></>
      )}
    </>
  )
}
export default ShiftButtons
