import defaultIcon from "assets/images/icons/default.png"

export const productType = Object.freeze({
  physical: "Physical",
  digital: "Digital",
  nonGtin: "NonGtin"
})

export const getProductCreateTypes = (isRestaurant: boolean) => {
  const productTypeList = [
    { name: "Create Product", value: productType.physical, isActive: true },
    {
      name: "Create Digital Product ",
      value: productType.digital,
      isActive: !isRestaurant
    }
  ]
  return productTypeList
}

export const fileIcons = ["Default", "Image", "Document", "Audio", "Video"]

export const digitalProductImage = Object.freeze({
  Default: defaultIcon
})

export const BvidsForFullLots = [
  "042747c7-bd4b-408c-97f1-ac2490a9ab4e",
  "8bb5789c-73a1-4d42-aa42-6b7e83717f21"
]

export const MERI_PHARMACY_BVID = "96c2a635-171a-4253-9d59-b5b96e4566fd"
