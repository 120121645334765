import React from "react"
import { Box, Grid, Theme } from "@mui/material"
import { secondaryColor } from "styles/Theme"
import { useTheme } from "@mui/styles"

const styles = (theme: Theme) => ({
  container: {
    minHeight: "100vh",
    backgroundColor: theme.custom.white,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  centered: {
    width: 400,
    transform: "translate(-50%, -50%)",
    background: theme.custom.white,
    position: "absolute",
    top: " 50%",
    left: "50%"
  },
  blob: {
    width: 70,
    height: 70,
    position: "absolute",
    background: theme.palette.secondary.main,
    borderRadius: "50%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  blob1: {
    left: "20%",
    animation: "osc-l 2s ease infinite"
  },
  blob2: {
    left: "80%",
    animation: "osc-r 2s ease infinite",
    background: secondaryColor
  },
  "@keyframes osc-l": {
    "0%": {
      left: "20%"
    },
    "50%": {
      left: "50%"
    },
    "100%": {
      left: "20%"
    }
  },
  "@keyframes osc-r": {
    "0%": {
      left: "80%"
    },
    "50%": {
      left: " 50%"
    },
    "100%": {
      left: "80%"
    }
  }
})

const FullPageLoader = () => {
  const classes = styles(useTheme())
  return (
    <Box sx={classes.container}>
      <Grid sx={classes.centered}>
        <Grid sx={{ ...classes.blob, ...classes.blob1 }}></Grid>
        <Grid sx={{ ...classes.blob, ...classes.blob2 }}></Grid>
      </Grid>
    </Box>
  )
}
export default FullPageLoader
