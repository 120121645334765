import React, { Grid } from "@mui/material"
import CounterButton from "main/pos/common/counters/CounterButton"
import { Backspace } from "@mui/icons-material"

const styles = () => ({
  container: {
    margin: "12px 0px",
    justifyContent: "space-between"
  }
})

const Counters = ({
  fullWidth,
  hideBigNumbers,
  state,
  handleCounter
}: {
  fullWidth?: boolean
  hideBigNumbers?: boolean
  state?: any
  handleCounter: (_value: any) => any
}) => {
  const classes = styles()
  const BigNumbers = [500, 1000, 5000]
  const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9]

  const handleNumbers = (number: string) => {
    const holdNumbers = state + number
    handleCounter(holdNumbers)
  }

  const handleBigNumbers = (number: string) => {
    handleCounter(String(number))
  }

  const handleBackSpace = () => {
    const numbers = state.slice(0, -1)
    handleCounter(numbers || "")
  }

  const ExtraButtons = () => {
    return (
      <>
        <Grid item xs={4}>
          <CounterButton text="." handleClick={handleNumbers} />
        </Grid>
        <Grid item xs={4}>
          <CounterButton text={0} handleClick={handleNumbers} />
        </Grid>

        <Grid item xs={4}>
          <CounterButton text={<Backspace />} handleClick={handleBackSpace} />
        </Grid>
      </>
    )
  }

  const BigNumberButtons = () => {
    return BigNumbers.map((number) => (
      <Grid key={number} item xs={12}>
        <CounterButton text={number} handleClick={handleBigNumbers} />
      </Grid>
    ))
  }

  const NumberButtons = () => {
    return numbers.map((number) => (
      <Grid key={number} item xs={4}>
        <CounterButton text={number} handleClick={handleNumbers} />
      </Grid>
    ))
  }
  return (
    <Grid container sx={classes.container}>
      <Grid item xs={fullWidth ? 12 : 7}>
        <Grid container>
          {NumberButtons()}
          {ExtraButtons()}
        </Grid>
      </Grid>
      {hideBigNumbers ? (
        <></>
      ) : (
        <Grid item xs={2}>
          {BigNumberButtons()}
        </Grid>
      )}
    </Grid>
  )
}

export default Counters
