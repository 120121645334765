import React from "react"
import BranchSelect from "main/branches/BranchSelect"
import CustomCard from "main/common/CustomCard"
import { userTypesList } from "main/util/UserHelper"
import { getFromStorage } from "main/util/LocalStorageHelper"

const styles = () => ({
  customCard: {
    margin: "16px 0px"
  }
})

const PosBranch = () => {
  const classes = styles()
  const checkUser = getFromStorage("user")
  const { role } = checkUser?.user ?? {}
  const { MasterAdmin, MasterPOS } = userTypesList
  const isAdmin = role === MasterAdmin || role === MasterPOS

  return (
    <>
      {isAdmin ? (
        <CustomCard customCardClass={classes.customCard}>
          <BranchSelect />
        </CustomCard>
      ) : (
        <></>
      )}
    </>
  )
}

export default React.memo(PosBranch)
