import React, { useContext } from "react"
import { Grid, TextField } from "@mui/material"
import CustomerFields from "main/pos/order/details/common/CustomerFields"
import DetailsSummary from "main/pos/order/details/common/details_summary/DetailsSummary"
import SummaryButtons from "main/pos/order/details/common/details_summary/SummaryButtons"
import OrderSource from "main/pos/order/details/common/OrderSource"
import PosCustomerContext from "main/context/pos/PosCustomer/PosCustomerContext"
import { PosCustomerType } from "main/context/pos/PosCustomer/PosCustomerTypes"

const PickupDetails = () => {
  const customerContext = useContext(PosCustomerContext) as PosCustomerType

  const handlePickupTime = (value: string) => {
    customerContext.setOrderPickupTime(value)
    customerContext.setOrderPickupTimeErr(false)
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        {customerContext.showSummary ? (
          <DetailsSummary />
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <OrderSource />
            </Grid>
            <Grid item xs={12}>
              <CustomerFields />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="datetime-local"
                name="pickup-time"
                label="Pickup Time"
                id="pickup-time-txt"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                value={customerContext.orderPickupTime}
                onChange={(e) => handlePickupTime(e.target.value)}
                error={customerContext.orderPickupTimeErr}
                helperText={
                  customerContext.orderPickupTimeErr
                    ? "Time and date cannot be empty or past date and time"
                    : ""
                }
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <SummaryButtons />
      </Grid>
    </Grid>
  )
}

export default PickupDetails
