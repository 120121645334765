import React, { forwardRef } from "react"
import { Grid } from "@mui/material"
import RowItem from "main/pos/receipt/common/RowItem"
import KitchenProductList from "main/pos/receipt/product_list/KitchenProductList"
import { formatDateTime } from "main/util/DateTimeHelper"

const styles = () => ({
  container: {
    width: 300,
    padding: 2
  },
  logo: {
    width: 120
  },
  fontSmall: {
    fontSize: 12,
    margin: 0
  },
  space: {
    margin: "8px 0px"
  },
  divider: {
    margin: "8px 0",
    border: "2px solid"
  },
  fontBold: {
    fontWeight: 700
  },
  extraSpace: {
    height: "80px",
    borderBottom: "1px solid"
  }
})

function DraftKOT(props: any, ref: any) {
  const classes = styles()
  const { data } = props
  const { products, orderTime, orderNumber, orderType } = data ?? {}
  const orderDate = orderTime ? formatDateTime(orderTime) : ""

  return (
    <>
      <Grid container ref={ref} sx={classes.container}>
        <Grid item xs={12} sx={classes.space} textAlign="center">
          <hr style={classes.divider} />
          <h4>{orderNumber} (KOT)</h4>
          <hr style={classes.divider} />
        </Grid>
        <Grid item xs={12}>
          <RowItem
            fontClass="fontNormal"
            label={
              <span>
                Order date: <span style={classes.fontBold}>{orderDate}</span>
              </span>
            }
          />
          <RowItem
            fontClass="fontNormal"
            label={
              <span>
                Order type: <span style={classes.fontBold}>{orderType}</span>
              </span>
            }
          />
        </Grid>
        <Grid item xs={12} sx={classes.space}>
          <RowItem fontClass="fontHeading" label="Items" value="Qty" />
          <hr style={classes.divider} />
          <KitchenProductList products={products} kot />
        </Grid>
        <Grid item xs={12} sx={classes.space}>
          <p style={classes.extraSpace}></p>
        </Grid>
      </Grid>
    </>
  )
}

export default forwardRef(DraftKOT)
