import React, { useContext } from "react"
import SimpleDialog from "main/common/simple_dialog/SimpleDialog"
import PosProductContext from "main/context/pos/PosProduct/PosProductContext"
import { PosProductType } from "main/context/pos/PosProduct/PosProductTypes"
import { Typography } from "@mui/material"
import LotsDialogBody from "./LotsDialogBody"

const ProductLotsDialog = () => {
  const productContext = useContext(PosProductContext) as PosProductType

  return (
    <SimpleDialog
      fullWidth
      closeButton
      maxWidth="sm"
      dialogOpen={productContext.openLotsDialog}
      onHandleClose={() => productContext.setOpenLotsDialog(false)}
      title={<Typography>Select Product Lot</Typography>}
      body={<LotsDialogBody />}
    />
  )
}
export default ProductLotsDialog
