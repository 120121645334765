import React from "react"
import { Card, Grid, Typography } from "@mui/material"
import NoResultIcon from "assets/images/general/NoResultIcon"

const styles = () => ({
  image: {
    width: 300
  },
  text: {
    fontSize: 24
  }
})

const EmptySearch = () => {
  const classes = styles()
  return (
    <Card>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <Grid sx={classes.image}>
          <NoResultIcon />
        </Grid>
        <Typography id="no-results-found-text" sx={classes.text} variant="h5">
          No results found.
        </Typography>
      </Grid>
    </Card>
  )
}

export default EmptySearch
