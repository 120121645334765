import React, { useContext } from "react"
import { Grid, Theme, Typography } from "@mui/material"
import { useTheme } from "@mui/styles"
import PosProductContext from "main/context/pos/PosProduct/PosProductContext"
import { PosProductType } from "main/context/pos/PosProduct/PosProductTypes"
import QuantityBox from "main/pos/common/QuantityBox"
import Title from "main/common/Title"
import {
  calculateSubItemsTotal,
  convertSubVariantDetails
} from "main/pos/helpers/ProductHelpers"
import { getProductPrice } from "main/pos/helpers/ProductHelpers"
import { numberRoundUp } from "main/util/NumberHelper"
import ProductOptionDialog from "main/pos/product/product_option/ProductOptionDialog"
import ConfigContext from "main/context/config/ConfigContext"
import { ConfigContextTypes } from "main/context/config/ConfigContextTypes"
import ListElement from "main/common/ListElement"
import { restrictOperation } from "../helpers/RestrictionHelpers"
import { BVIDS } from "constant/RestrictionQuantityBvids"
import BranchContext from "main/context/branch/BranchContext"
import { BranchContextType } from "main/context/branch/BranchContextTypes"
import CartItemsBatch from "./CartItemsBatch"
import CartItemsLot from "./CartItemsLot"
import PosContext from "main/context/pos/PosContext"
import { PosContextType } from "main/context/pos/PosContextTypes"
import { CartSubItems } from "./CartItemTypes"
import { ProductDataType } from "../product/product_list/ProductListTypes"

const styles = (theme: Theme) => ({
  cartItemRow: {
    margin: "8px 0px"
  },
  productTotal: {
    fontWeight: "bold",
    paddingRight: 1
  },
  cartItems: {
    marginTop: 4,
    height: 212,
    overflowY: "scroll"
  },
  emptyCartItems: {
    height: 20,
    marginBottom: 4
  },
  itemTitle: {
    flexGrow: 5,
    flexDirection: "column"
  },
  title: {
    marginTop: 1
  },
  cart: {
    width: 200
  },
  productComment: {
    fontSize: 15
  },
  titleLabel: {
    padding: 0,
    cursor: "pointer"
  },
  titleLabelNoPointer: {
    padding: 0
  },
  cartScroll: {
    overflowY: "scroll"
  },
  productError: {
    color: theme.custom.red,
    fontSize: 12,
    textAlign: "center"
  },
  subTitle: {
    textAlign: "end"
  },
  discountText: {
    fontSize: 16,
    fontWeight: 700,
    color: theme.custom.red
  },
  maxScan: {
    fontSize: 12,
    textAlign: "center"
  }
})

const CartItems = ({ hideQuantityBtn }: { hideQuantityBtn?: boolean }) => {
  const classes = styles(useTheme())
  const productContext = useContext(PosProductContext) as PosProductType
  const { getStoreBvid } = useContext(BranchContext) as BranchContextType
  const { isRestaurantMode, isProductLotOn } = useContext(
    ConfigContext
  ) as ConfigContextTypes
  const showCart = productContext?.selectedProductsList?.length
  const showScroll = productContext?.selectedProductsList?.length > 2
  const isRestaurant = isRestaurantMode()
  const posContext = useContext(PosContext) as PosContextType
  const {
    voucherDiscount,
    voucherDetails,
    flatDiscount,
    binDiscount,
    categoryVoucherId
  } = posContext
  const calculateAndShowVoucherDiscount = (
    product: {
      price: number
      flatDiscount: number
      quantity: number
      discountedPrice: number
      lotTotal: number
      subItems: Array<CartSubItems>
      variants: Array<any>
    },
    voucherDetails: {
      categoryXid: string
      maxDiscount: number
      discount: number
    },
    voucherDiscount: number
  ): number => {
    const getProductsTotal = productContext?.selectedProductsList.reduce(
      (
        acc: number,
        currentItem: {
          categoryId: string
          price: number
          lotTotal?: number
          subItems: Array<CartSubItems>
          quantity: number
          discountedPrice: number
          variants: Array<any>
          variantOptionValues: Array<any>
        }
      ) => {
        if (currentItem.categoryId === voucherDetails?.categoryXid) {
          // INFO this is specific for meriPharmacy
          if (isProductLotOn && currentItem.lotTotal) {
            acc += currentItem.lotTotal
          } else {
            acc += Number(getTotal(currentItem))
          }
        }

        return acc
      },
      0
    )
    const productPrice = Number(getTotal(product))
    // INFO voucher has more priority than category discount
    if (voucherDiscount) {
      if (voucherDiscount >= voucherDetails?.maxDiscount) {
        // INFO this is spefic for Meri Pharmacy
        if (isProductLotOn) {
          return (
            (product.lotTotal / getProductsTotal) * voucherDetails.maxDiscount
          )
        }

        return (productPrice / getProductsTotal) * voucherDetails?.maxDiscount
      } else if (voucherDiscount < voucherDetails?.maxDiscount) {
        return productPrice * (voucherDetails?.discount / 100)
      } else {
        // INFO this is specific for Meri Pharmacy
        if (isProductLotOn) {
          return (product.lotTotal * voucherDetails.discount) / 100
        }
        return (product.price * voucherDetails.discount) / 100
      }
    }

    // INFO this is where I am checking for Category Discount
    return isProductLotOn
      ? product.lotTotal * (product.flatDiscount / 100)
      : productPrice * (product.flatDiscount / 100)
  }

  const cartClasses = {
    emptyCartItems: !showCart ? classes.emptyCartItems : classes.cartItems,
    cartScroll: showScroll ? classes.cartScroll : {}
  }
  const getProductTotal = (product: ProductDataType) => {
    const productTotal =
      parseInt(product.quantity as string) * getProductPrice(product) || 0

    const subItemsTotal =
      calculateSubItemsTotal(product) * Number(product.quantity as string) || 0

    if (!isRestaurantMode())
      return (
        convertSubVariantDetails(product, product.subItems)?.price *
        Number(product.quantity as string)
      )
    return productTotal + subItemsTotal
  }

  const showProductSubItems = (product: ProductDataType) => {
    return product?.subItems
      ?.map((item: any) => {
        const qty = item?.qty ? `(${item.qty})` : ""
        return `${item.title}${qty}`
      })
      .join(", ")
  }

  const openProductDialog = (
    product: ProductDataType,
    productIndex: number
  ): void => {
    if (!product?.extraItem) {
      productContext.setSelectedVariants([])
      productContext.setSelectedOptions([])
      productContext.setSelectedProduct(product)
      productContext.setOpenOptionDialog(true)
      productContext.setIsProductUpdate(true)
      productContext.setProductEditIndex(productIndex)
    }
  }

  const handleTitleOnClick = (product: any, index: number) => {
    if (isRestaurant) return openProductDialog(product, index)
  }

  const disabledQuantity = restrictOperation(BVIDS, getStoreBvid())

  const getTotal = (product: any) => {
    if (product?.productLots?.length) return numberRoundUp(product?.lotTotal)
    else if (product?.batches?.length)
      return numberRoundUp(product.batchedTotal)
    return numberRoundUp(getProductTotal(product) || 0)
  }

  const showDiscountedAmount = (
    product: {
      price: number
      flatDiscount: number
      quantity: number
      discountedPrice: number
      lotTotal: number
      subItems: Array<CartSubItems>
      variants: Array<any>
    },
    voucherDetails: {
      categoryXid: string
      maxDiscount: number
      discount: number
    },
    voucherDiscount: number
  ) => {
    if ((flatDiscount && !voucherDiscount) || binDiscount) return ""

    return isNaN(
      calculateAndShowVoucherDiscount(product, voucherDetails, voucherDiscount)
    )
      ? ""
      : calculateAndShowVoucherDiscount(
            product,
            voucherDetails,
            voucherDiscount
          ) > 0
        ? `-${calculateAndShowVoucherDiscount(
            product,
            voucherDetails,
            voucherDiscount
          ).toFixed(2)}`
        : ""
  }

  return (
    <>
      <Grid sx={{ ...cartClasses.emptyCartItems, ...cartClasses.cartScroll }}>
        {showCart ? (
          productContext?.selectedProductsList.map(
            (product: any, index: number) => {
              const productCategoryDiscount = showDiscountedAmount(
                product,
                voucherDetails,
                voucherDiscount
              )
              return (
                <Grid key={index}>
                  <Grid
                    container
                    key={index}
                    id={`cart-product-${index}`}
                    alignItems="flex-start"
                    sx={classes.cartItemRow}
                  >
                    {!product?.selectedLot ? (
                      <Grid item md={hideQuantityBtn ? 1 : 3}>
                        {hideQuantityBtn ? (
                          <Typography variant="h6">
                            {product.quantity}x
                          </Typography>
                        ) : (
                          <QuantityBox
                            id={index}
                            disable={disabledQuantity || product?.selectedLot}
                            productQuantity={product?.quantity}
                            deleteProduct={productContext.productDelete}
                            handleQuantity={
                              productContext.handleProductQuantity
                            }
                            productType={product?.productType}
                            error={product.quantityError}
                            count={product?.cartonSize || 1}
                          />
                        )}
                      </Grid>
                    ) : (
                      <></>
                    )}

                    <Grid
                      container
                      item
                      md={
                        product?.selectedLot?.length && hideQuantityBtn
                          ? 11
                          : hideQuantityBtn
                            ? 10
                            : 7
                      }
                      sx={classes.itemTitle}
                    >
                      <Title
                        title={product.name}
                        labelClass={
                          isRestaurant
                            ? classes.titleLabel
                            : classes.titleLabelNoPointer
                        }
                        subtitle={showProductSubItems(product)}
                        handleClick={() => handleTitleOnClick(product, index)}
                      />
                      {product?.batches ? (
                        <CartItemsBatch product={product} />
                      ) : (
                        <></>
                      )}

                      {product.originalQuantity ? (
                        <ListElement
                          label={`Available Qty: ${product.originalQuantity}`}
                          id="subtotal"
                          value=""
                        />
                      ) : (
                        <></>
                      )}
                      {product.comment && !product?.selectedLot ? (
                        <Typography sx={classes.productComment}>
                          {isRestaurant ? "Instructions:" : ""}
                          {product.comment}
                        </Typography>
                      ) : (
                        <></>
                      )}
                    </Grid>
                    <Grid
                      item
                      md={
                        product?.selectedLot?.length && !hideQuantityBtn
                          ? 5
                          : hideQuantityBtn
                            ? 1
                            : 2
                      }
                    >
                      <Typography
                        id="cart-product-count-txt"
                        sx={{ ...classes.productTotal, ...classes.subTitle }}
                      >
                        {getTotal(product)}

                        {product?.flatDiscount > 0 && !voucherDiscount ? (
                          <Typography
                            sx={{ ...classes.discountText }}
                            id="cart-product-discount-txt"
                          >
                            {`${productCategoryDiscount}`}
                          </Typography>
                        ) : categoryVoucherId === product?.categoryId &&
                          (voucherDiscount || flatDiscount) ? (
                          <Typography sx={{ ...classes.discountText }}>
                            {showDiscountedAmount(
                              product,
                              voucherDetails,
                              voucherDiscount
                            )}
                          </Typography>
                        ) : (
                          <></>
                        )}
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      {productContext.isQuantityInLimit(product) ? (
                        <></>
                      ) : (
                        <Typography sx={classes.productError}>
                          {product.name} cannot be added more than
                          {` ${productContext.getProductLimit(product)}`}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  {product?.selectedLot ? (
                    <CartItemsLot product={product} productIndex={index} />
                  ) : (
                    <></>
                  )}
                </Grid>
              )
            }
          )
        ) : (
          <Typography align="center">No Items in Cart</Typography>
        )}
      </Grid>
      <ProductOptionDialog />
    </>
  )
}
export default CartItems
