import moment from "moment"

const formatDate = (timestamp: any) => {
  const date = new Date(timestamp)
  return date.toLocaleString("en-US", {
    day: "2-digit",
    month: "short",
    year: "numeric"
  })
}

const formatTime = (timestamp: any) => {
  const date = new Date(timestamp)
  return date.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true
  })
}

export const formatSimpleDateTime = (date: any) => {
  if (!date) return "No Date Available"
  const customDate = new Date(date)
  const year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
    customDate
  )
  const month = new Intl.DateTimeFormat("en", { month: "short" }).format(
    customDate
  )
  const day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(
    customDate
  )

  let hours = customDate.getHours()
  let minutes: number | string
  minutes = customDate.getMinutes()
  const amPm = hours >= 12 ? "pm" : "am"
  hours = hours % 12
  hours = hours ? hours : 12
  minutes = minutes < 10 ? "0" + minutes : minutes

  return `${day}-${month}-${year}, ${hours}:${minutes} ${amPm.toUpperCase()}`
}

export const getCurrentDateTime = () => {
  const date = new Date()
  const formatedDateTime = new Date(
    date.getTime() - date.getTimezoneOffset() * 60000
  )
  const dateTime = formatedDateTime.toISOString().replace(/.\d+Z$/g, "")
  return dateTime.slice(0, dateTime.lastIndexOf(":"))
}

export const getCurrentFormatedDateTime = () =>
  moment().format("YYYY-MM-DD HH:mm:ss")

export const convertDateTimeToString = (date?: any) => {
  const newDate = date ? new Date(date) : new Date()
  return newDate.toISOString()
}

export const getDateAndTime = (when: any) => {
  const date = new Date(when * 1000)
  return `${formatDate(date)} ${formatTime(date)}`
}

export const getDateAndTimeOffline = (date: any) => {
  return `${formatDate(date)} ${formatTime(date)}`
}

export const checkPastDateTime = (date: any) => {
  return !moment(getCurrentDateTime()).isSameOrBefore(date)
}

export const checkPastTime = (date: any) => {
  const givenMoment = moment(date).seconds(0)
  const currentMoment = moment().seconds(0)
  return givenMoment.isSameOrBefore(currentMoment)
}

export const addMinutesInTime = (
  minutes = 0,
  date = getCurrentDateTime()
): string => {
  const newDateTime = moment(date).add(minutes, "m").toDate()
  return moment(newDateTime).format("YYYY-MM-DDTHH:mm")
}

export const checkCurrentDateBetweenTwo = (
  firstDate: string,
  secondDate: string
): boolean => {
  const format = "YYYY-MM-DD HH:mm"
  const currentDate = moment()
  const time = moment(currentDate, format)
  return time.isBetween(firstDate, secondDate)
}

export const formatDateTime = (dateAndTime: string): string => {
  const format = "MMMM Do YYYY, h:mm a"
  return moment(dateAndTime).format(format)
}
