import React from "react"
import Pos from "main/pos/Pos"
import { Routes, Route } from "react-router-dom"
import Reconnect from "main/pos/logout/Reconnect"
import OfflineOrders from "main/orders/OfflineOrders"

const Main = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Pos />} key="Pos" />
        <Route path="/reconnect" element={<Reconnect />} key="Reconnect" />
        <Route path="/orders" element={<OfflineOrders />} key="OfflineOrders" />
      </Routes>
    </>
  )
}

export default Main
