import React, { useState } from "react"
import {
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography
} from "@mui/material"
import { Cancel, Done } from "@mui/icons-material"
import SimpleDialog from "main/common/simple_dialog/SimpleDialog"
import { useSync } from "main/pos/hooks/SyncHooks"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import { useDispatch } from "react-redux"
import { setFirstSync } from "main/context/connection/ConnectionSlice"

const styles = () => ({
  successIcon: {
    fontSize: 56
  }
})

const PosSync = ({ stopSyncing }: { stopSyncing?: any }) => {
  const classes = styles()
  const dispatch = useDispatch()

  const [syncDialog, setSyncDialog] = useState(true)
  const sync = useSync()

  const finishSyncing = () => {
    if (!sync.loading) {
      setSyncDialog(false)
      stopSyncing(false)
      dispatch(setFirstSync(true))
    }
  }

  return (
    <SimpleDialog
      fullWidth
      allowActions={!sync.loading}
      maxWidth="sm"
      title={<Typography variant="h5">Syncing</Typography>}
      dialogOpen={syncDialog}
      onHandleClose={() => finishSyncing()}
      handleOk={() => finishSyncing()}
      disabled={sync.loading}
      okButton="Close"
      body={
        <Grid container spacing={4}>
          <Grid item xs={12} textAlign="center">
            {sync.loading ? (
              <>
                <CircularProgress />
                <Typography align="center" component="p">
                  Please wait, Syncing is in process
                </Typography>
              </>
            ) : (
              <>
                <CheckCircleOutlineIcon
                  color="success"
                  sx={classes.successIcon}
                />
                <Typography align="center" component="p">
                  Syncing has completed.
                </Typography>
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            <List>
              {sync.resolverName.map((item: any, index: number) => {
                return (
                  <ListItem
                    key={index}
                    secondaryAction={
                      item.status ? (
                        <Done color="success" />
                      ) : (
                        <Cancel color="error" />
                      )
                    }
                  >
                    <ListItemText primary={item.name} />
                  </ListItem>
                )
              })}
            </List>
          </Grid>
        </Grid>
      }
    />
  )
}

export default PosSync
