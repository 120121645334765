import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export type ConnectionSliceTypes = {
  isInternetOn: boolean
  firstSync: boolean
  backgroundSync: boolean
  productSync: boolean
  fromOrderPage: boolean
  FBRPosId: string
}
const initialState: ConnectionSliceTypes = {
  isInternetOn: false,
  firstSync: false,
  backgroundSync: false,
  productSync: false,
  fromOrderPage: false,
  FBRPosId: ""
}

export const ConnectionSlice = createSlice({
  name: "connection",
  initialState,
  reducers: {
    setIsInternetOn(state, action: PayloadAction<boolean>) {
      state.isInternetOn = action.payload
    },
    setFirstSync(state, action: PayloadAction<boolean>) {
      state.firstSync = action.payload
    },
    setBackgroundSync(state, action: PayloadAction<boolean>) {
      state.backgroundSync = action.payload
    },
    setProductSync(state, action: PayloadAction<boolean>) {
      state.productSync = action.payload
    },
    setFromOrderPage(state, action: PayloadAction<boolean>) {
      state.fromOrderPage = action.payload
    },
    setFBRPosId(state, action: PayloadAction<string>) {
      state.FBRPosId = action.payload
    }
  }
})
export const {
  setIsInternetOn,
  setFirstSync,
  setBackgroundSync,
  setProductSync,
  setFBRPosId,
  setFromOrderPage
} = ConnectionSlice.actions

export default ConnectionSlice.reducer
