import React, { memo, useContext, useEffect, useState } from "react"
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  IconButton,
  Typography,
  Divider,
  Theme
} from "@mui/material"
import { useTheme } from "@mui/styles"
import OptionButton from "main/common/option_button/OptionButton"
import { fetchDataWithSearch } from "offline_database/queries/DbOperations"
import ConfigContext from "main/context/config/ConfigContext"
import { ConfigContextTypes } from "main/context/config/ConfigContextTypes"
import BranchContext from "main/context/branch/BranchContext"
import { BranchContextType } from "main/context/branch/BranchContextTypes"
import { getFromStorage } from "main/util/LocalStorageHelper"
import { userTypesList } from "main/util/UserHelper"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import CloseIcon from "@mui/icons-material/Close"
import { fetchDataWithSearchPaginated } from "offline_database/queries/DbOperations"
import ConnectionHooks from "main/context/connection/ConnectionHook"

const styles = (theme: Theme) => ({
  categoryListSection: {
    backgroundColor: theme.custom.white,
    marginBottom: 5
  },
  categoryAccordion: {
    "&.MuiAccordion-root": {
      margin: "20px 0px",
      borderRadius: 3
    }
  },
  divider: {
    marginBottom: 2
  }
})

const CategoryAccordion = ({
  fetchOfflineProducts
}: {
  fetchOfflineProducts: any
}) => {
  const classes = styles(useTheme())
  const { isRestaurantMode } = useContext(ConfigContext) as ConfigContextTypes
  const { isInternetOn, firstSync } = ConnectionHooks()

  const [categoryAccordion, setCategoryAccordion] = useState(false)
  const [mainCategory, setMainCategory] = useState<any>("")
  const [subCategory, setSubCategory] = useState<any>("")
  const [clearSelection, setClearSelection] = useState(false)
  const [offlineCategories, setOfflineCategories] = useState([])

  const { getBranchBvid, getStoreBvid } = useContext(
    BranchContext
  ) as BranchContextType

  const branchBvid = getBranchBvid()
  const storeBvid = getStoreBvid()

  const fetchOfflineCategories = async () => {
    const categoryList = await fetchDataWithSearch("categoryList")
    setOfflineCategories(categoryList)
  }

  const getCategoriesList = () => offlineCategories

  useEffect(() => {
    const checkUser = getFromStorage("user")
    const { role } = checkUser?.user ?? {}
    const { MasterAdmin, MasterPOS } = userTypesList
    if (role === MasterAdmin || role === MasterPOS) {
      if (branchBvid) {
        fetchOfflineCategories()
      }
    } else {
      fetchOfflineCategories()
    }
  }, [isInternetOn, storeBvid, branchBvid, firstSync])

  useEffect(() => {
    if (mainCategory)
      fetchOfflineProducts(
        fetchDataWithSearchPaginated,
        "productList",
        "categoryBvid",
        mainCategory.value,
        50
      )
    setClearSelection(false)
  }, [mainCategory])

  useEffect(() => {
    if (subCategory) {
      fetchOfflineProducts(
        fetchDataWithSearchPaginated,
        "productList",
        "subcategoryBvid",
        subCategory.value,
        50
      )
      handleCategoryAfterFetch()
    }
  }, [subCategory])

  function handleCategoryAfterFetch() {
    const showSubCategory = getSubCategoryList()?.length
    const checkCategories = (mainCategory && !showSubCategory) || subCategory

    if (checkCategories) {
      setCategoryAccordion(false)
    }
  }

  function openCategoriesAccordion() {
    if (!categoryAccordion) {
      clearCategories()
    }
    setCategoryAccordion(!categoryAccordion)
  }

  function clearCategories() {
    setMainCategory("")
    setSubCategory("")
    setClearSelection(true)
  }

  function showAllProducts(e: any) {
    e.stopPropagation()
    clearCategories()
  }

  const getSubCategoryList = () => {
    if (!isRestaurantMode() && mainCategory) {
      const categoryList = getCategoriesList()
      const subCategories: any = categoryList.find((category: any) => {
        return category.bvid === mainCategory.value
      })

      return subCategories?.merchantSubcategories || []
    }
    return []
  }

  function transformCategoriesList() {
    const categoryList = getCategoriesList()
    return categoryList.map((item: any) => ({
      title: item.name,
      value: item.bvid
    }))
  }

  function transformSubCategoriesList() {
    const subCategories = getSubCategoryList()
    return subCategories.map((item: any) => ({
      title: item.name,
      value: item.bvid
    }))
  }

  const categoriesList = transformCategoriesList()

  const subCategoriesList = transformSubCategoriesList()
  const showSubCategories = getSubCategoryList()?.length

  const handleMainCategory = (value: object) => {
    setMainCategory(value)
  }

  const handleSubCategory = (value: object) => {
    setSubCategory(value)
  }

  return (
    <Accordion
      elevation={0}
      expanded={categoryAccordion}
      sx={classes.categoryAccordion}
    >
      <AccordionSummary
        onClick={openCategoriesAccordion}
        expandIcon={
          <IconButton
            onClick={openCategoriesAccordion}
            id="open-cat-accordion-btn"
          >
            <ArrowDropDownIcon />
          </IconButton>
        }
      >
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography
              variant="h5"
              onClick={() => {
                !mainCategory ? openCategoriesAccordion : null
              }}
            >
              {mainCategory ? mainCategory.title : "Select category"}
              {subCategory ? ` > ${subCategory.title}` : ""}
            </Typography>
          </Grid>
          {mainCategory ? (
            <Grid item>
              <Typography variant="h5" onClick={showAllProducts}>
                All products
              </Typography>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={classes.categoryListSection}>
        <Grid container>
          <Grid item xs={12}>
            {mainCategory ? (
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography variant="h5">{mainCategory.title}</Typography>
                </Grid>
                <IconButton
                  id="close-cat-accordion-btn"
                  onClick={() => setMainCategory("")}
                >
                  <CloseIcon />
                </IconButton>
                <Grid item xs={12}>
                  <Divider sx={classes.divider} />
                </Grid>
              </Grid>
            ) : (
              <></>
            )}

            <OptionButton
              allActive
              clearSelection={clearSelection}
              subValues={mainCategory ? [mainCategory] : categoriesList}
              handleSelection={handleMainCategory}
            />
          </Grid>

          {showSubCategories ? (
            <Grid item xs={12}>
              <Divider sx={classes.divider} />
              <OptionButton
                allActive
                subValues={subCategoriesList}
                handleSelection={handleSubCategory}
              />
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default memo(CategoryAccordion)
