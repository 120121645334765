const PHONE_NUMBER_REGEX = /^\+923([0-6]{1})([\d]{8})$/g
const PHONE_NUMBER_REGEX_03 = /03[\d]{2}[\d]{7}$/
const EMAIL_REGEX =
  /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const barcodeRegex = /^([a-zA-Z0-9-]){4,20}$/g
const NUMBER_REGEX = /[0-9]/g

const Validator = {
  isPhoneNumberValid(value: any) {
    return value.match(PHONE_NUMBER_REGEX)
  },
  isPasswordValid(value: any) {
    return value.length === 4
  },
  isEmailValid(value: any, optional = false) {
    if (optional && !value) return true
    return EMAIL_REGEX.test(value)
  },
  isEmpty(value: any) {
    return value?.length <= 0 || !value
  },
  isEmptyObject(value: any) {
    return Object.keys(value)?.length <= 0 || !value
  },
  isOtpValid(value: any) {
    return value.length === 4
  },
  is03NumberValid(value: any) {
    if (PHONE_NUMBER_REGEX_03.test(value) && value.length > 0) {
      return true
    }
  },
  checkIsNumber(value: any) {
    return value?.replace(/[^0-9]/g, "")
  },
  onlyDecimalNumber(value: any) {
    return value?.replace(/[^0-9.]/g, "")
  },
  isBarcodeValid(barcode: any) {
    return barcode.match(barcodeRegex)
  },
  isNumberValid(value: any) {
    return value?.match(NUMBER_REGEX)
  }
}

export default Validator
