import React from "react"
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from "@mui/material"
import ErrorIcon from "@mui/icons-material/Error"

type Props = {
  open?: boolean
}

const OfflineModal = (props: Props) => {
  const { open = false } = props
  return (
    <Dialog open={open}>
      <DialogContent dividers>
        <Grid container alignItems="center" flexDirection="column">
          <Box component={ErrorIcon} fontSize={60} color="red" />
          <DialogTitle textAlign="center">
            Oops! It seems we've lost the internet connection. Please check your
            internet connection and try again
          </DialogTitle>
          <Typography gutterBottom></Typography>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default OfflineModal
