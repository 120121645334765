import { db } from "offline_database/Db"

export const addData = async (tableName: string, data: any) => {
  try {
    //@ts-ignore: Unreachable code error
    await db[tableName].add(data)
  } catch (error) {
    console.info(error)
  }
}

export const addBulkData = async (tableName: string, data: any) => {
  try {
    //@ts-ignore: Unreachable code error
    await db[tableName].bulkAdd(data || [])
  } catch (error) {
    console.info(error)
  }
}

export const addBulkDataWithSearch = async (
  tableName: string,
  data: any,
  searchFields?: any
) => {
  try {
    data?.forEach((item: any) => {
      const searchFieldsSet = {}
      searchFields?.forEach((item2: any) => {
        if (
          item2.key &&
          item?.[item2?.parentKey] &&
          typeof item2 === "object"
        ) {
          //@ts-ignore: Unreachable code error
          searchFieldsSet[item2.key] = item[item2.parentKey][item2.key]
        } else {
          //@ts-ignore: Unreachable code error
          searchFieldsSet[item2] = item[item2]
        }
      })
      //@ts-ignore: Unreachable code error
      db[tableName].add({
        data: item || [],
        ...searchFieldsSet
      })
    })
    //@ts-ignore: Unreachable code error
  } catch (error) {
    console.info(error)
  }
}

export const fetchDataWithSearch = async (
  tableName: string,
  query?: any,
  search?: any
) => {
  if (search) {
    //@ts-ignore: Unreachable code error
    return await db[tableName]
      .where(query)
      .startsWithIgnoreCase(search)
      .toArray()
  } else {
    //@ts-ignore: Unreachable code error
    return await db[tableName].toArray()
  }
}
export const fetchDataWithSearchPaginated = async (
  tableName: string,
  query?: any,
  search?: any,
  limit?: number
) => {
  if (search) {
    //@ts-ignore: Unreachable code error
    return await db[tableName]
      .where(query)
      .startsWithIgnoreCase(search)
      .limit(limit)
      .toArray()
  } else {
    //@ts-ignore: Unreachable code error
    return await db[tableName].limit(limit).toArray()
  }
}
export const fetchMultiFieldSearchPaginated = async (
  tableName: string,
  query: string,
  searchOnSecondField: string,
  search?: any,
  limit: number = 50
) => {
  if (search) {
    const wordToSearch = search.toLowerCase()
    //@ts-ignore: Unreachable code error
    return await db[tableName]
      .filter((obj: any) => {
        const nameMatches = obj[query]?.toLowerCase()?.includes(wordToSearch)
        const descriptionMatches = obj[searchOnSecondField]
          .toLowerCase()
          .includes(wordToSearch)
        return nameMatches || descriptionMatches
      })
      .limit(limit)
      .toArray()
  } else {
    //@ts-ignore: Unreachable code error
    return await db[tableName]?.limit(limit)?.toArray()
  }
}
export const deleteData = async (tableName?: string) => {
  try {
    //@ts-ignore: Unreachable code error
    await db[tableName].clear()
  } catch (error) {
    console.info(error)
  }
}

export const deleteSingleData = async (
  tableName?: string,
  query?: string,
  id?: string
) => {
  try {
    // @ts-ignore: Unreachable code error
    await db[tableName].where(query).startsWithIgnoreCase(id).delete()
  } catch (error) {
    console.info(error)
  }
}

export const modifyData = async (
  tableName?: string,
  query?: string,
  id?: string,
  newData?: any
) => {
  try {
    // @ts-ignore: Unreachable code error
    await db[tableName]
      .where(query)
      .startsWithIgnoreCase(id)
      .modify({ data: newData })
  } catch (error) {
    console.info(error)
  }
}
