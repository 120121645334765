export const getBase64FromUrl = async (url: string) => {
  const urlIndex = url?.search("https://storage.googleapis.com")
  if (urlIndex < 0) {
    const data = await fetch(url)
    if (data.status === 200) {
      const blob = await data.blob()
      return new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onloadend = () => {
          const base64data = reader.result
          resolve(base64data)
        }
      })
    }
  }
}
