import React from "react"
import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Theme,
  useMediaQuery
} from "@mui/material"
import CustomProductImage from "main/common/CustomProductImage"
import { useTheme } from "@mui/styles"
import {
  ProductDataType,
  ProductType
} from "main/pos/product/product_list/ProductListTypes"

const styles = (theme: Theme) => ({
  imageBox: {
    marginBottom: 4,
    "& > div": {
      width: 140,
      borderBottomLeftRadius: 12,
      borderBottomRightRadius: 12
    }
  },
  image: {
    backgroundColor: theme.custom.white,
    border: `1px solid ${theme.custom.whiteGrey}`,
    borderRadius: 3,
    width: 140,
    height: 140,
    objectFit: "contain"
  }
})

const ProductsList = ({
  products,
  handleProduct
}: {
  products: ProductType[]
  handleProduct: (_productToAdd: object, _onValue: boolean) => void
}) => {
  const classes = styles(useTheme())
  const PosViewPort = "(max-width:1250px)"
  const isPos = useMediaQuery(PosViewPort)

  return (
    <ImageList cols={isPos ? 3 : 5}>
      {products?.map((product: ProductType) => {
        const { flatDiscount, flatDiscountApp } = product
        const { productLots } = product?.data ?? {}
        const productToAdd = {
          ...((Object.keys(product?.data ?? {}).length
            ? product?.data
            : product) as ProductDataType),
          flatDiscount,
          flatDiscountApp
        }
        return productLots?.length ||
          productToAdd.quantity === null ||
          (productToAdd.quantity as number) > 0 ? (
          <ImageListItem
            key={productToAdd?.bvid}
            id={productToAdd?.bvid}
            sx={classes.imageBox}
            onClick={() => handleProduct(productToAdd, true)}
          >
            <CustomProductImage
              sx={classes.image}
              alt={productToAdd.name}
              product={productToAdd}
              loading="lazy"
            />
            <ImageListItemBar title={productToAdd.name} />
          </ImageListItem>
        ) : (
          <></>
        )
      })}
    </ImageList>
  )
}

export default ProductsList
