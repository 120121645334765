import { isEmpty } from "lodash-es"
import { scrollTop } from "main/util/ScrollHelper"
import { OrderTypes } from "../constant/PosConstant"

export function extractActivePaymentMethod(methods: any) {
  return methods?.find(
    (method: any) => method.paymentProviderStatus === "Active"
  )?.paymentProviderType
}

export const getOrderType = (dispatchDetails: any, deliveryDetails: any) => {
  let orderType = ""
  if (!isEmpty(dispatchDetails) && !isEmpty(deliveryDetails)) {
    orderType =
      dispatchDetails?.riderName || dispatchDetails?.comment
        ? OrderTypes.Delivery
        : OrderTypes.PickUp
  } else if (!isEmpty(dispatchDetails) && isEmpty(deliveryDetails)) {
    orderType = OrderTypes.WalkIn
  } else if (isEmpty(dispatchDetails) && !isEmpty(deliveryDetails)) {
    orderType = OrderTypes.Delivery
  }
  return orderType
}

export const scrollTopOnValidation = (checkValidation: any) => {
  if (!checkValidation) scrollTop()
}
