import React, { useState, useContext, useEffect } from "react"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import Typography from "@mui/material/Typography"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Grid, IconButton } from "@mui/material"
import TimeField from "main/common/date_time/TimeField"
import DateCalendar from "main/common/date_time/DateCalender"
import { useTheme } from "@mui/styles"
import PosContext from "main/context/pos/PosContext"
import { DeliveryOptions } from "../constants/OrderConstants"
import { Theme } from "@mui/material"
import { PosContextType } from "main/context/pos/PosContextTypes"
import { Edit } from "@mui/icons-material"
import { formatDateTime } from "main/util/DateTimeHelper"
import { Button } from "@mui/material"
import moment, { Moment } from "moment"

const styles = (theme: Theme) => ({
  activeAccordion: {
    backgroundColor: `${theme?.palette?.primary?.main}30`
  },
  icon: {
    padding: 2,
    borderRadius: "3em",
    backgroundColor: `${theme?.palette?.primary?.main}30`
  },
  buttonContainer: {
    marginTop: 1
  },
  editButton: {
    fontSize: 16
  },
  saveButton: {
    marginTop: 2,
    textAlign: "end"
  },
  error: {
    color: theme.custom.red,
    fontSize: 12
  }
})

function DeliveryChoices() {
  const classes = styles(useTheme())
  const [expanded, setExpanded] = useState(false)
  const [showTime, setShowTime] = useState(false)

  const {
    deliveryLaterTiming,
    setDeliveryLaterTiming,
    isDeliveryLater,
    setIsDeliveryLater,
    deliveryChoice,
    setDeliveryChoice,
    delveryHours,
    setDeliveryHours,
    deliveryMinutes,
    setDeliveryMinutes,
    deliveryMeridiem,
    setDeliveryMeridiem,
    deliveryDate,
    setDeliveryDate,
    getIsDeliveryLaterValid
  } = useContext(PosContext) as PosContextType
  const isDeliveryLaterValid = getIsDeliveryLaterValid()
  const handleChange = (panel: any) => (event: any, isExpanded: any) => {
    if (isExpanded && panel !== expanded) {
      setIsDeliveryLater(true)
    }
    if (panel === DeliveryOptions.DeliveryAsap) setIsDeliveryLater(false)
    setDeliveryChoice(isExpanded ? panel : panel)
  }

  const handleDate = (e?: Moment | null) => {
    setDeliveryDate(e?.format())
    setShowTime(true)
  }

  useEffect(() => {
    const customDate: Date = deliveryDate ? new Date(deliveryDate) : new Date()
    customDate.setHours(
      deliveryMeridiem === "AM"
        ? delveryHours?.hour() % 12
        : (delveryHours?.hour() % 12) + 12
    )
    customDate.setMinutes(deliveryMinutes?.minutes())
    setDeliveryLaterTiming(customDate)
  }, [delveryHours, deliveryMinutes, deliveryMeridiem, deliveryDate])

  return (
    <div>
      <Accordion expanded={expanded}>
        <AccordionSummary
          id="panel1bh-header"
          expandIcon={<ExpandMoreIcon />}
          onClick={() => setExpanded(!expanded)}
        >
          <Typography fontWeight={600}>
            Delivery:{" "}
            {deliveryLaterTiming && isDeliveryLater ? (
              <>{formatDateTime(deliveryLaterTiming.toString())} </>
            ) : (
              <>As soon as possible</>
            )}
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: 0 }}>
          <>
            <Accordion
              disableGutters
              expanded={deliveryChoice === DeliveryOptions?.DeliveryAsap}
              onChange={handleChange(DeliveryOptions?.DeliveryAsap)}
              elevation={0}
            >
              <AccordionSummary
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                sx={
                  deliveryChoice === DeliveryOptions.DeliveryAsap
                    ? classes.activeAccordion
                    : {}
                }
              >
                <Grid container spacing={2}>
                  <Grid item>
                    <Typography
                      component="h6"
                      variant="body1"
                      fontWeight="bold"
                    >
                      Delivery ASAP
                    </Typography>
                    <Typography
                      component="p"
                      variant="subtitle2"
                      color="secondary"
                    >
                      Delivery ASAP
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>
            </Accordion>
            <Accordion
              disableGutters
              expanded={deliveryChoice === DeliveryOptions?.DeliveryLater}
              onChange={(event, isExpanded) => {
                handleDate(moment())
                handleChange(DeliveryOptions?.DeliveryLater)(event, isExpanded)
              }}
              elevation={0}
            >
              <AccordionSummary
                id="panel2bh-header"
                sx={
                  deliveryChoice === DeliveryOptions.DeliveryLater
                    ? classes.activeAccordion
                    : {}
                }
              >
                <Grid container spacing={2}>
                  <Grid item>
                    <Typography
                      component="h6"
                      variant="body1"
                      fontWeight="bold"
                    >
                      Delivery Later Today
                    </Typography>
                    <Typography
                      component="p"
                      variant="subtitle2"
                      color="secondary"
                    >
                      Book the order, deliver it later.{" "}
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <TimeField
                  defaultTime={deliveryLaterTiming}
                  hours={delveryHours}
                  setHours={setDeliveryHours}
                  minutes={deliveryMinutes}
                  setMinutes={setDeliveryMinutes}
                  meridiem={deliveryMeridiem}
                  setMeridiem={setDeliveryMeridiem}
                />
                {!isDeliveryLaterValid ? (
                  <Typography variant="body1" sx={classes.error}>
                    Please pick a later time for delivery
                  </Typography>
                ) : (
                  <></>
                )}
                <Grid sx={classes.saveButton}>
                  <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={() => setExpanded(false)}
                  >
                    Save
                  </Button>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion
              disableGutters
              expanded={deliveryChoice === DeliveryOptions?.DeliveryMuchLater}
              onChange={(event, isExpanded) => {
                handleDate(null)
                handleChange(DeliveryOptions?.DeliveryMuchLater)(
                  event,
                  isExpanded
                )
              }}
              elevation={0}
            >
              <AccordionSummary
                id="panel3bh-header"
                sx={
                  deliveryChoice === DeliveryOptions.DeliveryMuchLater
                    ? classes.activeAccordion
                    : {}
                }
              >
                <Grid container spacing={2}>
                  <Grid item>
                    <Typography
                      component="h6"
                      variant="body1"
                      fontWeight="bold"
                    >
                      Deliver Much Later{" "}
                      {deliveryDate && showTime ? (
                        <IconButton
                          size="small"
                          onClick={() => {
                            setShowTime(false)
                          }}
                        >
                          <Edit sx={classes.editButton} />
                        </IconButton>
                      ) : (
                        <></>
                      )}
                    </Typography>
                    <Typography
                      component="p"
                      variant="subtitle2"
                      color="secondary"
                    >
                      Book delivery for a specific date.{" "}
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                {showTime && deliveryDate ? (
                  <>
                    <TimeField
                      defaultTime={deliveryLaterTiming}
                      hours={delveryHours}
                      setHours={setDeliveryHours}
                      minutes={deliveryMinutes}
                      setMinutes={setDeliveryMinutes}
                      meridiem={deliveryMeridiem}
                      setMeridiem={setDeliveryMeridiem}
                    />
                    {!isDeliveryLaterValid ? (
                      <Typography variant="body1" sx={classes.error}>
                        Please pick a later date and time for delivery
                      </Typography>
                    ) : (
                      <></>
                    )}
                    <Grid sx={classes.saveButton}>
                      <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={() => setExpanded(false)}
                      >
                        Save
                      </Button>
                    </Grid>
                  </>
                ) : (
                  <>
                    <DateCalendar disablePast handleChange={handleDate} />
                  </>
                )}
              </AccordionDetails>
            </Accordion>
          </>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default DeliveryChoices
