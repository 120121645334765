import React, { useContext, useState } from "react"
import { MenuItem, TextField } from "@mui/material"
import BranchContext from "main/context/branch/BranchContext"
import {
  BranchContextType,
  Branch
} from "main/context/branch/BranchContextTypes"
import { PosProductType } from "main/context/pos/PosProduct/PosProductTypes"
import PosProductContext from "main/context/pos/PosProduct/PosProductContext"
import ConnectionHooks from "main/context/connection/ConnectionHook"

const BranchSelect = () => {
  const { branchesList, handleSelectedBranchChange, isBranchSelected } =
    useContext(BranchContext) as BranchContextType
  const productContext = useContext(PosProductContext) as PosProductType
  const [showSelectedBranch, setShowSelectedBranch] = useState<string>("")
  const handleBranchChange = (event: React.ChangeEvent<{ value: string }>) => {
    const { value } = event.target as HTMLSelectElement
    productContext.resetPosProductValues()
    const selectedBranch = branchesList.find(
      (item: Branch) => item.storeName === value
    )
    setShowSelectedBranch(value)
    handleSelectedBranchChange(selectedBranch as Branch)
  }
  const { isInternetOn } = ConnectionHooks()
  return (
    <TextField
      select
      fullWidth
      variant="outlined"
      label="Branch"
      disabled={!isInternetOn}
      value={isBranchSelected() ? showSelectedBranch : ""}
      onChange={handleBranchChange}
    >
      {branchesList?.map((branch: Branch, index: number) => (
        <MenuItem key={index} value={branch.storeName}>
          {branch.storeName}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default BranchSelect
