import React, { useContext, useEffect, useState } from "react"
import { Button, Grid } from "@mui/material"
import MainOptions from "main/pos/product/product_option/sub_options/MainOptions"
import MainVariants from "main/pos/product/product_option/sub_options/MainVariants"
import {
  convertSubVariantDetails,
  extractProductData
} from "main/pos/helpers/ProductHelpers"
import ProductComments from "main/pos/product/product_option/ProductComments"
import SpecialInstruction from "main/common/SpecialInstruction"
import PosProductContext from "main/context/pos/PosProduct/PosProductContext"
import { PosProductType } from "main/context/pos/PosProduct/PosProductTypes"
import { getActiveOptionAndVariant } from "main/pos/helpers/ProductHelpers"
import ConfigContext from "main/context/config/ConfigContext"
import { ConfigContextTypes } from "main/context/config/ConfigContextTypes"
import CommonSnackbar from "main/common/common_snackbar/CommonSnackbar"

const styles = () => ({
  buttonMargin: {
    margin: "16px 0px"
  },
  loading: {
    marginBottom: 20
  },
  productOptionContainer: {
    margin: "12px 0px"
  }
})

const ProductOptions = () => {
  const classes = styles()
  const productContext = useContext(PosProductContext) as PosProductType
  const { isRestaurantMode } = useContext(ConfigContext) as ConfigContextTypes
  const [productComment, setProductComment] = useState("")
  const [snackBarState, setSnackBarState] = useState(false)
  const [message, setMessage] = useState("")
  const productOptionsList = getActiveOptionAndVariant(
    productContext.selectedProduct.mainOptions
  )
  const productVariantsList = getActiveOptionAndVariant(
    productContext.selectedProduct.variantOptions
  )
  const determinedVariant = convertSubVariantDetails(
    productContext.selectedProduct,
    productContext?.selectedVariants
  )
  const isSelectedVariantActive = determinedVariant.isActive

  useEffect(() => {
    if (productContext.isProductUpdate) {
      const product = productContext.selectedProduct
      setProductComment(product.comment || "")
      if (isRestaurantMode()) {
        productContext.setSelectedOptions(product.subItems)
      } else {
        productContext.setSelectedVariants(product.subItems)
      }
    }
  }, [productContext.isProductUpdate])

  function validateOptionQty() {
    for (const productOption of productOptionsList ?? []) {
      const option: any = productOption
      let optionQty = 0
      if (!option?.quantity) {
        continue
      }
      option.mainOptionValues.forEach((value: any) => {
        const isSelected = productContext?.selectedOptions?.find(
          (selectedOption: any) => selectedOption?.bvid === value.bvid
        )
        optionQty += isSelected ? value?.qty ?? 1 : 0
      })

      if (optionQty > 0 && optionQty < option.quantity) {
        setMessage(
          `Please select total ${option.quantity} option value for ${option.title}`
        )
        setSnackBarState(true)
        return true
      }
    }

    return false
  }

  function handleSaveOptions() {
    if (validateOptionQty()) return
    if (productContext.isProductUpdate) {
      const productVariables = {
        comment: productComment,
        subItems: isRestaurantMode()
          ? productContext.selectedOptions
          : productContext.selectedVariants
      }
      productContext.selectedProductsList.splice(
        productContext.productEditIndex,
        0,
        extractProductData(
          productContext.selectedProduct,
          productVariables,
          productContext.isProductUpdate
        )
      )
      const updatedList = productContext.selectedProductsList.filter(
        (item: any) => item !== productContext.selectedProduct
      )

      productContext.setSelectedProductsList(updatedList)
    } else {
      const productVariables = {
        comment: productComment,
        subItems: isRestaurantMode()
          ? productContext.selectedOptions
          : productContext.selectedVariants
      }
      productContext.handleProduct(
        extractProductData(productContext.selectedProduct, productVariables)
      )
    }

    productContext.optionDialogClose()
  }

  function handleOptions(optionDetails: any) {
    if (optionDetails?.parent?.isMultiSelect) {
      if (!productContext.checkAndRemoveOptions(optionDetails)) {
        productContext.setSelectedOptions((prev: any) => [
          ...prev,
          optionDetails
        ])
      }
    } else {
      productContext.checkAndRemoveOptions(optionDetails)
      productContext.setSelectedOptions((prev: any) => [...prev, optionDetails])
    }
  }

  function handleVariants(optionDetails: any) {
    productContext.checkAndRemoveVariants(optionDetails)
    productContext.setSelectedVariants((prev: any) => [...prev, optionDetails])
  }

  function checkOptionsDisabled() {
    let disabled = true

    const mandatoryOptions =
      productOptionsList?.filter((productOption: any) => {
        const mainOptionCheck = productOption?.mainOptionValues?.filter(
          (mainnOptionValue: any) => mainnOptionValue.isActive
        )
        return productOption.isMandatory && mainOptionCheck?.length
      }) ?? []
    disabled = mandatoryOptions?.length > 0
    productContext.selectedOptions.forEach((option: any) => {
      return option?.subOptions?.forEach((subOption: any) => {
        const subOptionCheck = subOption?.subOptionValues.filter(
          (subOptionValue: any) => subOptionValue.isActive
        )

        if (subOption?.isMandatory && subOptionCheck?.length) {
          mandatoryOptions.push(subOption)
        }
      })
    })

    const filterSelectedOptions: any = []
    productContext.selectedOptions.filter((item: any) => {
      if (
        !filterSelectedOptions.includes(item?.parent) &&
        item?.parent?.isMandatory
      )
        filterSelectedOptions.push(item?.parent)
      return item
    })
    disabled = mandatoryOptions?.length !== filterSelectedOptions?.length
    return disabled
  }

  function checkVariantsDisabled() {
    return (
      productVariantsList?.length !== productContext.selectedVariants?.length
    )
  }

  function disableAddButton() {
    if (productContext.isProductUpdate) {
      return false
    } else if (
      productContext.isQuantityInLimit(
        extractProductData(productContext.selectedProduct)
      )
    ) {
      return isRestaurantMode()
        ? checkOptionsDisabled()
        : checkVariantsDisabled() || !isSelectedVariantActive
    }

    return true
  }
  function closeSnackbar() {
    setSnackBarState(false)
    setMessage("")
  }

  function handleCancelButton() {
    productContext.optionDialogClose()
  }
  return (
    <Grid container sx={classes.productOptionContainer}>
      {isRestaurantMode()
        ? productOptionsList &&
          productOptionsList?.map((mainOption: any) => {
            return mainOption?.mainOptionValues?.length ? (
              <MainOptions
                key={mainOption.bvid}
                mainOption={mainOption}
                handleOption={handleOptions}
                setSnackBarState={setSnackBarState}
                setMessage={setMessage}
              />
            ) : (
              <></>
            )
          })
        : productVariantsList &&
          productVariantsList?.map((mainVariants: any) => {
            return (
              <MainVariants
                disable={!checkVariantsDisabled() && !isSelectedVariantActive}
                key={mainVariants.bvid}
                mainVariants={mainVariants}
                handleOption={handleVariants}
              />
            )
          })}

      <Grid container item xs={12} spacing={2}>
        <Grid item xs={10}>
          <SpecialInstruction
            value={productComment}
            handleChange={setProductComment}
          />
          <ProductComments
            productComment={productComment}
            setProductComment={setProductComment}
          />
        </Grid>
        <Grid item xs={2}>
          <Grid container>
            <Button
              fullWidth
              onClick={handleSaveOptions}
              sx={classes.buttonMargin}
              color={disableAddButton() ? "secondary" : "primary"}
              variant="contained"
              size="large"
              id="add-update-product-btn"
              disabled={disableAddButton()}
            >
              {productContext.isProductUpdate ? "Update" : "Add"}
            </Button>
            <Button
              fullWidth
              onClick={handleCancelButton}
              id="cancel-options-modal-btn"
              color="primary"
              variant="outlined"
              size="large"
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <CommonSnackbar
        message={message}
        variant="error"
        autoHideDuration={4000}
        open={snackBarState}
        onClose={closeSnackbar}
      />
    </Grid>
  )
}

export default ProductOptions
