export const limitText = (
  text: string,
  limit: number,
  endPoint: string
): string => {
  if (text?.length > limit) return text?.slice(0, limit) + endPoint
  return text
}

export const wordSeparator = (index: number, separator: string) => {
  return index !== 0 ? separator : ""
}
