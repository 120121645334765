import React from "react"
import { useContext } from "react"
import { List, ListItem, ListItemText, Theme } from "@mui/material"
import { useTheme } from "@mui/styles"
import PosCustomerContext from "main/context/pos/PosCustomer/PosCustomerContext"
import { PosCustomerType } from "main/context/pos/PosCustomer/PosCustomerTypes"
import { PosProductType } from "main/context/pos/PosProduct/PosProductTypes"
import PosProductContext from "main/context/pos/PosProduct/PosProductContext"
import { useIsCustomerInfoValid } from "main/pos/hooks/PosHooks"

const styles = (theme: Theme) => ({
  addressList: {
    border: `2px solid ${theme.custom.whiteGrey}`,
    cursor: "pointer",
    padding: "12px",
    marginBottom: "8px",
    borderRadius: "18px",
    "&:hover": {
      borderColor: theme.palette.secondary.main
    }
  },
  addressActive: {
    borderColor: theme.palette.secondary.main
  }
})

const AddressList = () => {
  const classes = styles(useTheme())

  const {
    customerAddressList,
    customerAddressId,
    setCustomerAddressId,
    setCustomerAddressDetails,
    setShowSummary,
    email
  } = useContext(PosCustomerContext) as PosCustomerType
  const { isDigitalProductInCart } = useContext(
    PosProductContext
  ) as PosProductType
  const isCustomerInfoValid = useIsCustomerInfoValid()

  const handleAddressDetails = (address: { uid: string; details: string }) => {
    if (isCustomerInfoValid()) {
      setCustomerAddressDetails(address?.details)
      setCustomerAddressId(address?.uid)
      if (!isDigitalProductInCart() || email) {
        setShowSummary(true)
      }
    }
  }

  const getActiveClass = (id: string): object => {
    return customerAddressId === id ? classes.addressActive : {}
  }

  return (
    <List>
      {customerAddressList?.map((address: any) => (
        <ListItem
          key={address.bvid}
          sx={{ ...classes.addressList, ...getActiveClass(address?.uid) }}
          onClick={() => handleAddressDetails(address)}
        >
          <ListItemText primary={address.details} />
        </ListItem>
      ))}
    </List>
  )
}

export default AddressList
