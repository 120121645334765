import React, { useContext } from "react"
import { Grid, Typography } from "@mui/material"
import PosProductContext from "main/context/pos/PosProduct/PosProductContext"
import OptionButton from "main/common/option_button/OptionButton"
import { PosProductType } from "main/context/pos/PosProduct/PosProductTypes"

const MainVariants = ({
  mainVariants,
  handleOption,
  disable
}: {
  mainVariants: any
  handleOption: any
  disable: boolean
}) => {
  const productContext = useContext(PosProductContext) as PosProductType

  const selectedOptions = productContext.isProductUpdate
    ? productContext.selectedVariants
    : null

  return (
    <Grid container>
      <Grid container item xs={12} columnGap={1}>
        <Typography>{mainVariants.title}</Typography>
        <Typography color={"error"}>
          {disable ? " - Out of Stock" : null}
        </Typography>
      </Grid>
      <OptionButton
        mainValues={mainVariants}
        subValues={mainVariants?.variantOptionValues}
        selectedOptions={selectedOptions}
        handleSelection={handleOption}
      />
    </Grid>
  )
}

export default MainVariants
