import React, { Grid } from "@mui/material"
import PosContext from "main/context/pos/PosContext"
import { PosContextType } from "main/context/pos/PosContextTypes"
import PosProductContext from "main/context/pos/PosProduct/PosProductContext"
import { PosProductType } from "main/context/pos/PosProduct/PosProductTypes"
import { useContext, useEffect, useState } from "react"
import OptionButton from "main/common/option_button/OptionButton"
import { OrderTypes, PaymentMethodTypes } from "main/pos/constant/PosConstant"
import { getPaymentMethodConstant } from "constant/PaymentConstants"
import { fetchDataWithSearch } from "offline_database/queries/DbOperations"
import ConnectionHooks from "main/context/connection/ConnectionHook"

type DefaultPaymentMethodType = {
  title?: string
  value?: string
  isActive?: boolean
}

const styles = () => ({
  button: {
    margin: 0
  },
  paymentContainer: {
    marginBottom: 3
  }
})

const PaymentMethods = () => {
  const classes = styles()

  const { setPaymentMethod, orderType, paymentMethodsList } = useContext(
    PosContext
  ) as PosContextType
  const { isInternetOn } = ConnectionHooks()
  const { isDigitalProductInCart } = useContext(
    PosProductContext
  ) as PosProductType
  const [defaultPaymentMethod, setDefaultPaymentMethod] =
    useState<DefaultPaymentMethodType>({})
  const [offlinePaymentModes, setOfflinePaymentModes] = useState<any>([])

  useEffect(() => {
    filterPaymentMethod()
  }, [orderType])

  const fetchOfflinePaymentModes = async () => {
    const paymentModes = await fetchDataWithSearch("merchantPaymentModes")
    setOfflinePaymentModes(paymentModes)
  }

  useEffect(() => {
    fetchOfflinePaymentModes()
  }, [isInternetOn])

  const paymentMethods = getPaymentMethodConstant(
    orderType,
    paymentMethodsList,
    offlinePaymentModes,
    isDigitalProductInCart(),
    isInternetOn
  )

  function filterPaymentMethod() {
    if (orderType === OrderTypes.Delivery) {
      selectPaymentMethod(PaymentMethodTypes.CashOnDelivery)
    } else if (orderType === OrderTypes.PickUp) {
      selectPaymentMethod(PaymentMethodTypes.Cash)
    } else if (orderType === OrderTypes.WalkIn) {
      selectPaymentMethod(PaymentMethodTypes.Cash)
    }
  }

  function selectPaymentMethod(methodValue: string) {
    const defaultOption = paymentMethods?.find(
      (method) => method.value === methodValue
    ) as DefaultPaymentMethodType
    setPaymentMethod(methodValue)

    setDefaultPaymentMethod(defaultOption)
  }

  function handlePaymentMethod(method: any) {
    setPaymentMethod(method)
  }

  return (
    <Grid container sx={classes.paymentContainer}>
      <Grid item xs={12}>
        {paymentMethods && defaultPaymentMethod ? (
          <OptionButton
            singleDefaultOption={defaultPaymentMethod}
            subValues={paymentMethods}
            handleSelection={(value: any) => handlePaymentMethod(value.value)}
            buttonSpacing={1}
            optionButtonClass={classes.button}
          />
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  )
}
export default PaymentMethods
