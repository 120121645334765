import React, { useContext, useState } from "react"
import TextField from "@mui/material/TextField"
import Chip from "@mui/material/Chip"
import Grid from "@mui/material/Grid"
import Clear from "@mui/icons-material/Clear"
import PosContext from "main/context/pos/PosContext"
import { PosContextType } from "main/context/pos/PosContextTypes"
import TitleIcon from "main/common/TitleIcon"
import IconButton from "@mui/material/IconButton"
import InputAdornment from "@mui/material/InputAdornment"
import Validator from "main/util/Validator"

const createStyles = () => ({
  chip: { padding: "0px 4px", width: "100%" },
  chipContainer: { width: "20%" },
  tipContainer: {
    paddingBottom: "32px"
  }
})

const Tip = () => {
  const styles = createStyles()
  const { tip, setTip } = useContext(PosContext) as PosContextType
  const [showField, setShowField] = useState(false)
  const tips: string[] = ["25", "50", "75", "100", "Other"]

  const handleTip = (tipOption: string): void => {
    if (tipOption === tip || (tipOption === "Other" && showField)) {
      handleClear()
      return
    }
    if (tipOption === "Other") {
      setShowField(true)
      return
    }

    setTip(tipOption)
  }

  const handleClear = (): void => {
    setTip("")
    setShowField(false)
  }

  return (
    <>
      <Grid container rowSpacing={1} sx={styles.tipContainer}>
        <Grid item xs={12}>
          <TitleIcon icon={<></>} title="Tip" />
        </Grid>
        <Grid item xs={12}>
          {showField ? (
            <TextField
              fullWidth
              name="tip"
              value={tip}
              placeholder="eg: 200"
              onChange={(e) => {
                setTip(Validator.checkIsNumber(e?.target?.value))
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <Clear color="error" onClick={handleClear} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          ) : (
            <></>
          )}
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" spacing={1}>
            {tips?.map((tipOption) => (
              <Grid item key={tipOption} sx={styles.chipContainer}>
                <Chip
                  sx={styles.chip}
                  label={tipOption}
                  color="primary"
                  variant={
                    tipOption === tip || (showField && tipOption === "Other")
                      ? "filled"
                      : "outlined"
                  }
                  onClick={() => handleTip(tipOption)}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default Tip
