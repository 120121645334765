import { getBase64FromUrl } from "main/util/Base64Helper"

export const getBranchVariable = (branchId: string, key: string) => {
  return {
    variables: { ...(branchId && { [key]: branchId }) }
  }
}

const getUrl = async (url: string) => {
  const imageUrl: any = await getBase64FromUrl(url)
  return imageUrl
}

export const convertProductImages = async (products: any) => {
  const productWithImage64 = await Promise.all(
    products?.map(async (product: any) => {
      const newProduct = { ...product }
      const newImageList = await Promise.all(
        newProduct.images.map(async (img: any) => {
          return {
            ...img,
            thumbnailUrl: await getUrl(img?.thumbnailUrl)
          }
        })
      )

      newProduct.images = newImageList
      return newProduct
    })
  )
  return productWithImage64
}
