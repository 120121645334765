import React, { useMemo } from "react"
import { List, ListItem, Theme, Typography } from "@mui/material"
import { useTheme } from "@mui/styles"
import { caluclateUsedBatchList } from "../helpers/ProductHelpers"

const styles = (theme: Theme) => ({
  batchList: {
    padding: 0
  },
  batchItems: {
    padding: "4px 0px"
  },
  productError: {
    color: theme.custom.red,
    fontSize: 12,
    textAlign: "center"
  }
})

const CartItemsBatch = ({ product }: { product: any }) => {
  const classes = styles(useTheme())

  const { batches, quantity } = product

  const usedBatchList = useMemo(() => {
    const batchList = caluclateUsedBatchList(batches, quantity)
    return batchList
  }, [quantity, batches])

  return (
    <List sx={classes.batchList}>
      {usedBatchList.map((batch, index) => (
        <ListItem sx={classes.batchItems} key={index}>
          {batch.batchName} ({batch.utilizedQuantity} x{" "}
          {batch?.discountedPrice || batch?.price} =
          {batch.utilizedQuantity * batch?.discountedPrice || batch?.price})
        </ListItem>
      ))}
      {usedBatchList.length && product.quantity > product.batchQuantity ? (
        <Typography sx={classes.productError}>
          {`${product.name} cannot be added more than
          ${product.batchQuantity}`}
        </Typography>
      ) : (
        <></>
      )}
    </List>
  )
}

export default CartItemsBatch
