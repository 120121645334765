import { sum } from "lodash-es"

type calculateTaxType = {
  taxRate: number
  subTotal: number[]
}

export const calculateTax = ({ taxRate, subTotal }: calculateTaxType) => {
  if (!taxRate) return 0
  const taxValue: number = sum(subTotal) * (taxRate / 100)
  return parseFloat(taxValue.toFixed(2))
}

export const inclusiveTaxValue = (taxRate: number, total: number) => {
  const taxValue: number = total - total / (1 + taxRate / 100)
  return parseFloat(taxValue.toFixed(2))
}

export const calculateInclusiveTax = ({
  taxRate,
  subTotal
}: calculateTaxType) => {
  const total = sum(subTotal)
  if (!taxRate) return 0
  return inclusiveTaxValue(taxRate, total)
}
