import React from "react"
import { MenuItem, TextField } from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import SimpleDialog from "main/common/simple_dialog/SimpleDialog"
import PosContext from "main/context/pos/PosContext"
import { PosContextType } from "main/context/pos/PosContextTypes"
import { useContext } from "react"
import { useDispatch } from "react-redux"
import { setFBRPosId } from "main/context/connection/ConnectionSlice"
import ConnectionHooks from "main/context/connection/ConnectionHook"

const styles = () => ({
  posIdField: {
    marginTop: 1
  }
})

const FbrPosIdDialog = () => {
  const classes = styles()
  const dispatch = useDispatch()
  const { FBRPosId } = ConnectionHooks()
  const { fbrIdsList, fbrDialog, setFbrDialog } = useContext(
    PosContext
  ) as PosContextType
  return (
    <>
      <SimpleDialog
        fullWidth
        title="FBR"
        dialogOpen={fbrDialog}
        maxWidth="sm"
        body={
          <TextField
            select
            fullWidth
            variant="outlined"
            label="Select Pos Id"
            value={FBRPosId}
            sx={classes.posIdField}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              dispatch(setFBRPosId(e.target.value))
              setFbrDialog(false)
            }}
          >
            {fbrIdsList?.map((ids: string, index: number) => (
              <MenuItem key={index} value={ids}>
                {ids}
              </MenuItem>
            ))}
          </TextField>
        }
      />
    </>
  )
}

export default withStyles(styles)(FbrPosIdDialog)
