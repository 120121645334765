import React from "react"
import { Grid, Typography } from "@mui/material"
import { useProductContext } from "main/hooks/ContextHooks"
import {
  countTotalLotsQty,
  lotProductTotal
} from "../product/product_lots/helpers/ProductLotsHelper"
import QuantityBox from "./QuantityBox"
import { cloneDeep } from "lodash"
const styles = () => ({
  quantityField: {
    "& input": {
      fontSize: 14,
      fontWeight: 600,
      padding: "4px",
      textAlign: "center"
    }
  },
  quantityDelete: {
    margin: "4px 4px 4px 0px",
    "& svg": {
      fontSize: 20
    }
  },
  quantityPrice: {
    marginRight: 1
  }
})

const CartItemsLot = ({
  product,
  productIndex
}: {
  product: any
  productIndex: number
}) => {
  const productContext = useProductContext()
  const classes = styles()

  const { selectedLot, productLots } = product
  const hideQuantityBtn = false

  const productDelete = (index: any) => {
    productContext.setSelectedProductsList((previousProduct: any) => {
      const selectedProduct = previousProduct[productIndex]
      if (selectedProduct.selectedLot?.length > 1) {
        selectedProduct.selectedLot?.splice(index, 1)
      } else {
        selectedProduct.selectedLot?.splice(index, 1)
        previousProduct?.splice(productIndex, 1)
      }

      selectedProduct.lotTotal = lotProductTotal(selectedProduct)
      return [...previousProduct]
    })
  }

  const handleProductQuantity = (value: any, lotIndex: number) => {
    const selectedProducts = cloneDeep(productContext.selectedProductsList)
    const selectedProduct = selectedProducts[productIndex]
    const selectedLot = selectedProduct.selectedLot[lotIndex]

    const futureQty = parseInt(value || 0)
    if (futureQty <= selectedLot.quantityInside)
      selectedLot.usedQty = parseInt(value || 0)
    else selectedLot.qtyError = `Limit:${selectedLot.quantityInside}`

    if (selectedProduct?.selectedLot) {
      selectedProduct.lotTotal = lotProductTotal(selectedProduct)
    }

    productContext.setSelectedProductsList(selectedProducts)
  }

  return (
    <Grid>
      {selectedLot.map(
        (
          lot: {
            usedQty: number
            lotCode: string
            price: string
            qtyError: string
          },
          index: number
        ) => {
          const qty = lot?.usedQty || 1
          return (
            <Grid container columnSpacing={2} mt={1} key={index}>
              <Grid item xs={3}>
                {hideQuantityBtn ? (
                  <Typography variant="h6">{qty}x</Typography>
                ) : (
                  <QuantityBox
                    id={index}
                    productQuantity={qty}
                    deleteProduct={() => productDelete(index)}
                    handleQuantity={handleProductQuantity}
                    productType={product?.productType}
                    error={lot?.qtyError}
                    deleteStyle={classes.quantityDelete}
                    fieldStyle={classes.quantityField}
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                <Typography>{`Lot: ${lot.lotCode}`}</Typography>
              </Grid>
              <Grid item xs={3} textAlign="end">
                <Typography
                  id="cart-product-count-txt"
                  sx={classes.quantityPrice}
                >
                  {qty * +lot?.price}
                </Typography>
              </Grid>
            </Grid>
          )
        }
      )}
      <Grid container mt={1}>
        <Grid item xs={12} textAlign="center">
          <Typography>
            Available Quantity: {countTotalLotsQty(productLots)}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CartItemsLot
