import React, { useContext, useState } from "react"
import { Grid, Typography } from "@mui/material"
import SubOption from "main/pos/product/product_option/sub_options/SubOptions"
import OptionButton from "main/common/option_button/OptionButton"
import PosProductContext from "main/context/pos/PosProduct/PosProductContext"
import { PosProductType } from "main/context/pos/PosProduct/PosProductTypes"

const MainOptions = ({
  mainOption,
  handleOption,
  setSnackBarState,
  setMessage
}: {
  mainOption: any
  handleOption: any
  setMessage?: React.Dispatch<React.SetStateAction<string>>
  setSnackBarState?: React.Dispatch<React.SetStateAction<any>>
}) => {
  const productContext = useContext(PosProductContext) as PosProductType
  const [selectedOptionBvid, setSelectedOptionBvid] = useState("")

  const getSubOptions = () => {
    const mainOptionValue = mainOption?.mainOptionValues?.find(
      (product: any) => {
        return product.bvid === selectedOptionBvid
      }
    )
    return mainOptionValue?.subOptions || []
  }

  const selectOption = (value: any, isSubOption?: boolean) => {
    if (isSubOption) {
      setSelectedOptionBvid(value.bvid)
    } else {
      handleOption(value)
      setSelectedOptionBvid(value.bvid)
    }
  }

  const mainOptionCheck = mainOption?.mainOptionValues.filter(
    (mainOption: any) => mainOption.isActive
  )

  return (
    <>
      {mainOptionCheck?.length ? (
        <Grid container>
          <Grid item xs={12}>
            <Typography>
              {mainOption.title}
              {mainOption.isMultiSelect ? "" : " - Select 1"}
              {mainOption.isMandatory ? "" : " (optional)"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <OptionButton
              mainValues={mainOption}
              subValues={mainOption?.mainOptionValues}
              selectedOptions={productContext.selectedOptions}
              handleSelection={selectOption}
              setMessage={setMessage}
              setSnackBarState={setSnackBarState}
            />
          </Grid>
          <Grid item xs={12}>
            {!mainOption.isMultiSelect &&
              getSubOptions()?.map((subOption: any) => {
                return subOption?.isActive &&
                  subOption?.subOptionValues?.length ? (
                  <SubOption
                    key={subOption.bvid}
                    subOptions={subOption}
                    handleOption={handleOption}
                  />
                ) : (
                  <></>
                )
              })}
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
    </>
  )
}

export default React.memo(MainOptions)
