import React from "react"
import { useContext, useState } from "react"
import { Button, Grid } from "@mui/material"
import { useTheme } from "@mui/styles"
import { Theme } from "@mui/material"
import PosContext from "main/context/pos/PosContext"
import { PosContextType } from "main/context/pos/PosContextTypes"
import PosProductContext from "main/context/pos/PosProduct/PosProductContext"
import { PosProductType } from "main/context/pos/PosProduct/PosProductTypes"
import PosCustomerContext from "main/context/pos/PosCustomer/PosCustomerContext"
import { PosCustomerType } from "main/context/pos/PosCustomer/PosCustomerTypes"
import ConfirmDialog from "main/common/ConfirmDialog"

const styles = (theme: Theme) => ({
  clearOrderButton: {
    backgroundColor: theme.custom.white
  }
})

const ResetPos = () => {
  const classes = styles(useTheme())
  const { resetPosProductValues } = useContext(
    PosProductContext
  ) as PosProductType
  const { resetPosCustomerValues } = useContext(
    PosCustomerContext
  ) as PosCustomerType
  const { resetPosValues } = useContext(PosContext) as PosContextType
  const [confirmDialog, setConfirmDialog] = useState(false)

  const handleClearOrder = () => {
    setConfirmDialog(true)
  }

  const clearOrder = () => {
    resetPosProductValues()
    resetPosCustomerValues()
    resetPosValues()
    setConfirmDialog(false)
  }

  const cancelClearOrder = (value: any) => {
    setConfirmDialog(value)
  }

  return (
    <Grid item xs={6}>
      <Button
        fullWidth
        size="large"
        variant="outlined"
        color="primary"
        sx={classes.clearOrderButton}
        onClick={handleClearOrder}
      >
        Clear Order
      </Button>
      <ConfirmDialog
        title="Clear Order"
        message="Are you sure you want to clear this order?"
        onConfirm={clearOrder}
        openDialog={confirmDialog}
        closeDialog={cancelClearOrder}
      />
    </Grid>
  )
}
export default ResetPos
