import React, { Grid } from "@mui/material"
import OrderType from "main/pos/order/order_type/OrderType"
import OrderDetails from "main/pos/order/details/OrderDetails"
import Products from "main/pos/product/Products"
import Cart from "main/pos/cart/Cart"
import ResetPos from "main/pos/reset/ResetPos"
import PosSyncButton from "main/pos/sync/PosSyncButton"
import PosBranch from "main/branches/PosBranch"
import Logout from "main/pos/logout/Logout"
import OrdersButton from "main/orders/OrdersButton"
import DraftedOrdersButton from "main/orders/DraftedOrdersButton"
import ShiftButtons from "main/pos/shifts/ShiftButtons"
import OrderReturn from "main/pos/order/order_return/OrderReturn"
import FbrPosIdDialog from "./fbr/FbrPosIdDialog"
import ProductSyncButton from "./sync/ProductsSyncButton"
import OrderIdentifierTextBox from "main/orders/OrderIdentifierTextBox"
import { getFromStorage } from "main/util/LocalStorageHelper"
import { useContext, useLayoutEffect } from "react"
import { useNavigate } from "react-router-dom"
import ConfigContext from "main/context/config/ConfigContext"
import { ConfigContextTypes } from "main/context/config/ConfigContextTypes"
import ConnectionHooks from "main/context/connection/ConnectionHook"
import OfflineModal from "./sync/OfflineModal"
import { PosContextType } from "main/context/pos/PosContextTypes"
import PosContext from "main/context/pos/PosContext"

const styles = () => ({
  container: {
    padding: 4
  },
  borderedGrid: {
    borderRadius: 2,
    marginTop: "12px",
    paddingTop: "20px",
    backgroundColor: "white",
    boxShadow:
      "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
  }
})

const PosWrapper = () => {
  const { isRestaurantMode } = useContext(ConfigContext) as ConfigContextTypes
  const { posConfigs } = useContext(PosContext) as PosContextType
  const { isInternetOn } = ConnectionHooks()

  const offlineMode = posConfigs?.offlineMode ?? true
  const classes = styles()
  const user = getFromStorage("user")
  const navigate = useNavigate()
  useLayoutEffect(() => {
    if (!user) {
      navigate("/reconnect")
    }
  }, [])

  return !user ? (
    <></>
  ) : (
    <Grid container spacing={2} sx={classes.container}>
      <Grid item xs={12} md={8}>
        <OrderType />
        <Products />
      </Grid>
      <Grid item xs={12} md={4}>
        <DraftedOrdersButton />
        <Grid sx={classes.borderedGrid}>
          <OrderIdentifierTextBox />
          <OrderDetails />
          <PosBranch />
          <Cart />
        </Grid>
        <Grid container spacing={1}>
          <ResetPos />
          <PosSyncButton />
          {!isRestaurantMode() ? <ProductSyncButton /> : <></>}
          <ShiftButtons />
          <OrderReturn />
          <OrdersButton />
          <Logout />
        </Grid>
        <Grid item xs={12}>
          <FbrPosIdDialog />
        </Grid>
      </Grid>
      <OfflineModal open={!offlineMode && !isInternetOn} />
    </Grid>
  )
}

export default PosWrapper
