import React from "react"
import { CardMedia } from "@mui/material"
import ChikooLogo from "assets/images/logos/chikoo_orange.png"

const styles = () => ({
  logo_image: {
    width: 120,
    paddingLeft: 8
  }
})

const Logo = ({ url = "" }) => {
  const classes = styles()
  return (
    <CardMedia title="Web Chikoo">
      <img
        src={url ? url : ChikooLogo}
        style={classes.logo_image}
        alt="logo"
      ></img>
    </CardMedia>
  )
}
export default Logo
