import React, { useContext, useRef, useState } from "react"
import { Typography, Button, Grid, Theme } from "@mui/material"
import { useTheme } from "@mui/styles"
import PosProductContext from "main/context/pos/PosProduct/PosProductContext"
import { PosProductType } from "main/context/pos/PosProduct/PosProductTypes"
import PriceSummary from "main/pos/cart/PriceSummary"
import SimpleDialog from "main/common/simple_dialog/SimpleDialog"
import CartItems from "main/pos/common/CartItems"
import ExtraItem from "main/pos/cart/ExtraItem"
import Checkout from "main/pos/checkout/Checkout"
import { scrollTopOnValidation } from "main/pos/helpers/PosHelpers"
import TitleIcon from "main/common/TitleIcon"
import CustomOrange from "styles/colors/CustomOrange"
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined"
import DraftKOT from "main/pos/receipt/DraftKOT"
import { useReactToPrint } from "react-to-print"
import {
  checkQuantityDetail,
  isProductQuantityValid
} from "main/util/ProductHelper"
import { Add } from "@mui/icons-material"
import CommonSnackbar from "main/common/common_snackbar/CommonSnackbar"
import UserContext from "main/context/user/UserContext"
import { UserContextType } from "main/context/user/UserContextTypes"
import { userTypesList } from "main/util/UserHelper"
import DraftedOrders from "main/orders/DraftedOrders"
import { usePosContext } from "main/hooks/ContextHooks"
import ConfirmDialog from "main/common/ConfirmDialog"
import { useDraftOrder } from "../hooks/DraftOrderHook"
import { useCustomerValidations } from "../hooks/PosHooks"
import ConfigContext from "main/context/config/ConfigContext"
import { ConfigContextTypes } from "main/context/config/ConfigContextTypes"
import ProvisionalReceipt from "../checkout/ProvisionalReceipt"
import { OrderTypes } from "../constant/PosConstant"

const styles = (theme: Theme) => ({
  container: {
    backgroundColor: theme.custom.white,
    borderRadius: 3,
    margin: "20px 0px",
    padding: 2
  },
  addItem: {
    margin: "8px 0"
  },
  proceedBtn: {
    background: `linear-gradient(88.73deg, ${CustomOrange[600]} -27.99%, ${CustomOrange[500]} 123.05%)`,
    margin: "8px"
  },

  draftOrderButton: {
    backgroundColor: theme.custom.white,
    margin: "8px"
  }
})

const Cart = () => {
  const classes = styles(useTheme())
  const productContext = useContext(PosProductContext) as PosProductType
  const { isRestaurantMode } = useContext(ConfigContext) as ConfigContextTypes

  const { isUserRole } = useContext(UserContext) as UserContextType

  const [extraItemDialog, setExtraItemDialog] = useState(false)
  const [snackBarVariant, setSnackBarVariant] = useState("success")
  const [errorMessage, setErrorMessage] = useState("")
  const [showSnackBar, setShowSnackBar] = useState(false)
  const [receiptData, setReceiptData] = useState<any>(null)
  const kitchenReceiptRef = useRef<any>()

  const {
    checkoutDialog,
    setCheckoutDialog,
    isShiftStarted,
    showDraftedOrders,
    setShowDraftedOrders,
    orderType,
    setIsHoldOrder,
    setPartnershipValue
  } = usePosContext()

  const checkValidations = useCustomerValidations()

  const {
    handleDraftOrder,
    createDraftOrder,
    showConfirmOverwriteDraftDialog,
    setShowConfirmOverwriteDraftDialog,
    draftAlreadyExists
  } = useDraftOrder()

  const productsLength = productContext?.selectedProductsList?.length ?? 0
  const isShiftUser = isUserRole(userTypesList.ShiftUser)
  const isProceedValidated = isShiftUser ? isShiftStarted : true

  const openCheckoutDialog = async () => {
    if (isProceedValidated) {
      const checkProductsQuantityLimit = checkQuantityDetail(productContext)
      const checkProductQuantity = await isProductQuantityValid(productContext)
      setCheckoutDialog(
        Boolean(
          checkValidations() &&
            checkProductQuantity &&
            checkProductsQuantityLimit
        )
      )
      scrollTopOnValidation(checkValidations())
    } else {
      setSnackBarVariant("error")
      setShowSnackBar(true)
      setErrorMessage("Please start shift to proceed")
    }
  }
  const handleCustomItem = () => {
    setExtraItemDialog(true)
  }

  const closeSnackbar = () => {
    setShowSnackBar(false)
  }

  function handlePromise(func: () => void) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(func())
      }, 2000)
    })
  }

  const handleKitchenReceipt = useReactToPrint({
    content: () => kitchenReceiptRef.current
  })

  function printReceipt() {
    handlePromise(handleKitchenReceipt)
  }

  const checkNewItem = (products: any) => {
    if (!products?.length) {
      return false
    }
    return products
  }

  const handleDraftSubmit = async () => {
    setIsHoldOrder(true)
    if (await draftAlreadyExists()) {
      setShowConfirmOverwriteDraftDialog(true)
    } else {
      const orderResponse: any = await handleDraftOrder()
      setReceiptData(orderResponse)
      if (isRestaurantMode() && checkNewItem(orderResponse.products)) {
        printReceipt()
      }
    }
  }

  const handleConfirmModify = async () => {
    const orderResponse: any = await createDraftOrder()
    setReceiptData(orderResponse)
    if (isRestaurantMode() && checkNewItem(orderResponse.products)) {
      printReceipt()
    }
  }

  const handleClose = () => {
    setCheckoutDialog(false)
    setPartnershipValue("")
  }

  return (
    <>
      <Grid container sx={classes.container}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <TitleIcon
                icon={<ShoppingBagOutlinedIcon />}
                title="Order Details"
              />
            </Grid>
            <Grid item>
              <Typography id="cart-products-count" variant="h6">
                {productsLength}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <CartItems />
        </Grid>
        <Grid item xs={12}>
          <Grid textAlign="center">
            <Button
              size="small"
              color="primary"
              variant="contained"
              sx={classes.addItem}
              data-id="customItem-btn"
              onClick={handleCustomItem}
            >
              <Add htmlColor="white" fontSize="small" /> Custom Item
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <PriceSummary />
        </Grid>

        {productsLength ? (
          <>
            <Grid
              item
              xs={12}
              container
              spacing={1}
              justifyContent={"space-between"}
            >
              <Grid item flexGrow={1}>
                <Button
                  fullWidth
                  size="large"
                  color="primary"
                  variant="outlined"
                  data-id="draft-btn"
                  onClick={handleDraftSubmit}
                >
                  Hold as Open
                </Button>
              </Grid>
              {isRestaurantMode() && orderType === OrderTypes.WalkIn ? (
                <Grid container item xs={3}>
                  <ProvisionalReceipt />
                </Grid>
              ) : (
                <></>
              )}
              <Grid item xs={12}>
                <Button
                  fullWidth
                  size="large"
                  color="primary"
                  variant="contained"
                  data-id="proceed-btn"
                  onClick={openCheckoutDialog}
                >
                  Bill Order
                </Button>
              </Grid>
            </Grid>
          </>
        ) : (
          <></>
        )}
      </Grid>
      <CommonSnackbar
        variant={snackBarVariant}
        open={showSnackBar}
        message={errorMessage}
        onClose={closeSnackbar}
      />
      <SimpleDialog
        fullWidth
        closeButton
        maxWidth="xl"
        title="Add Extra Item"
        dialogOpen={extraItemDialog}
        onHandleClose={() => setExtraItemDialog(false)}
        body={<ExtraItem setExtraItemDialog={setExtraItemDialog} />}
      />
      <SimpleDialog
        fullWidth
        maxWidth="xl"
        closeButton
        title={<Typography variant="h3">Checkout</Typography>}
        dialogOpen={checkoutDialog}
        onHandleClose={handleClose}
        body={<Checkout setCheckoutDialog={setCheckoutDialog} />}
      />
      <SimpleDialog
        fullWidth
        maxWidth="xl"
        closeButton
        title={<Typography variant="h3">All Opens Orders</Typography>}
        dialogOpen={showDraftedOrders}
        onHandleClose={() => setShowDraftedOrders(false)}
        body={<DraftedOrders />}
      />

      <ConfirmDialog
        title="Modify Order"
        message="Are you sure you want to modify this open order?"
        onConfirm={handleConfirmModify}
        openDialog={showConfirmOverwriteDraftDialog}
        closeDialog={() => {
          setShowConfirmOverwriteDraftDialog(false)
        }}
      />
      <Grid container style={{ display: "none" }}>
        <DraftKOT ref={kitchenReceiptRef} data={receiptData} />
      </Grid>
    </>
  )
}

export default Cart
