import configurations from "config/config.json"

const environment = process.env.REACT_APP_ENV || "local"
const config = configurations[environment as keyof typeof configurations]
if (!config) throw `Configuration not found for environment: ${environment}`

export default {
  ...config,
  name: environment
}
