import { extractActivePaymentMethod } from "main/pos/helpers/PosHelpers"
import { OrderTypes, PaymentMethodTypes } from "main/pos/constant/PosConstant"

export const getPaymentType = (posContext: any) => {
  switch (posContext.paymentMethod) {
    case PaymentMethodTypes.OnlinePayment:
      return extractActivePaymentMethod(posContext.paymentMethodsList)
    case PaymentMethodTypes.Cash:
      if (posContext.orderType === OrderTypes.Delivery)
        return PaymentMethodTypes.CashOnDelivery
      return posContext.paymentMethod
    default:
      return posContext.paymentMethod
  }
}
