import React, { useContext } from "react"
import { Grid } from "@mui/material"
import PosContext from "main/context/pos/PosContext"
import { PosContextType } from "main/context/pos/PosContextTypes"
import PosCustomerContext from "main/context/pos/PosCustomer/PosCustomerContext"
import { PosCustomerType } from "main/context/pos/PosCustomer/PosCustomerTypes"
import CommonAccordion from "main/common/common_accordion/CommonAccordion"
import CustomerDetailsHeader from "main/pos/order/details/common/CustomerDetailsHeader"
import DeliveryDetails from "main/pos/order/details/DeliveryDetails"
import { OrderTypes } from "main/pos/constant/PosConstant"
import PickupDetails from "main/pos/order/details/PickupDetails"
import WalkInDetails from "main/pos/order/details/WalkInDetails"

const OrderDetails = () => {
  const { orderType } = useContext(PosContext) as PosContextType
  const { customerAccordion, setCustomerAccordion } = useContext(
    PosCustomerContext
  ) as PosCustomerType

  return (
    <Grid container>
      <Grid item xs={12}>
        {orderType === OrderTypes.Delivery ? (
          <CommonAccordion
            isAccordionOpen={customerAccordion}
            setAccordionOpen={setCustomerAccordion}
            header={<CustomerDetailsHeader />}
            body={<DeliveryDetails />}
          />
        ) : (
          <></>
        )}
        {orderType === OrderTypes.PickUp ? (
          <CommonAccordion
            isAccordionOpen={customerAccordion}
            setAccordionOpen={setCustomerAccordion}
            header={<CustomerDetailsHeader />}
            body={<PickupDetails />}
          />
        ) : (
          <></>
        )}
        {orderType === OrderTypes.WalkIn ? (
          <CommonAccordion
            isAccordionOpen={customerAccordion}
            setAccordionOpen={setCustomerAccordion}
            header={<CustomerDetailsHeader />}
            body={<WalkInDetails />}
          />
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  )
}

export default OrderDetails
