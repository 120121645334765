import React, { useContext } from "react"
import { Divider } from "@mui/material"
import CartItems from "main/pos/common/CartItems"
import SpecialInstruction from "main/common/SpecialInstruction"
import PriceSummary from "main/pos/cart/PriceSummary"
import PosContext from "main/context/pos/PosContext"
import { PosContextType } from "main/context/pos/PosContextTypes"
import Tip from "../tip/Tip"
import ConfigContext from "main/context/config/ConfigContext"
import { ConfigContextTypes } from "main/context/config/ConfigContextTypes"

const styles = () => ({
  dividerSpace: {
    margin: "12px 0px"
  }
})

const Sale = () => {
  const classes = styles()
  const posContext = useContext(PosContext) as PosContextType
  const { isRestaurantMode } = useContext(ConfigContext) as ConfigContextTypes

  return (
    <>
      <CartItems hideQuantityBtn={true} />
      <SpecialInstruction
        value={posContext.orderInstruction}
        handleChange={posContext.setOrderInstruction}
      />
      <Divider sx={classes.dividerSpace} />
      {isRestaurantMode() ? <Tip /> : <></>}
      <PriceSummary hideTitle={true} />
    </>
  )
}

export default Sale
