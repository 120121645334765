import React from "react"
import { Grid, TextField } from "@mui/material"
import { NewAddressTypes } from "main/common/new_address/NewAddressTypes"
import City from "main/common/new_address/city/City"
import Area from "main/common/new_address/area/Area"

const NewAddress = ({
  selectedCity,
  setSelectedCity,
  cityError,
  setCityError,
  selectedArea,
  setSelectedArea,
  areaError,
  setAreaError,
  addressDetails,
  setAddressDetails,
  addressDetailsError,
  setAddressDetailsError,
  addressLabel,
  addressPlaceHolder
}: NewAddressTypes) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <City
          selectedCity={selectedCity}
          setSelectedCity={setSelectedCity}
          setSelectedArea={setSelectedArea}
          error={cityError}
          setCityError={setCityError}
        />
      </Grid>

      {selectedCity ? (
        <Grid item xs={12}>
          <Area
            cityBvid={selectedCity}
            selectedArea={selectedArea}
            setSelectedArea={setSelectedArea}
            error={areaError}
            setAreaError={setAreaError}
          />
        </Grid>
      ) : (
        <></>
      )}

      {selectedCity && selectedArea ? (
        <Grid item xs={12} md={12}>
          <TextField
            required
            fullWidth
            label={addressLabel}
            id="address-txt"
            variant="outlined"
            placeholder={addressPlaceHolder}
            value={addressDetails}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setAddressDetails(e.target.value)
              setAddressDetailsError(false)
            }}
            error={addressDetailsError}
            helperText={
              addressDetailsError ? "Address details cannot be empty" : ""
            }
          />
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  )
}

export default NewAddress
