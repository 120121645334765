import { useContext } from "react"
import UserContext from "main/context/user/UserContext"
import { UserContextType } from "main/context/user/UserContextTypes"
import BranchContainer from "main/context/branch/BranchContainer"
import ConfigContainer from "main/context/config/ConfigContainer"
import InternetConnection from "main/pos/connection/InternetConnection"
import FullPageLoader from "main/common/FullPageLoader"
import Main from "main/Main"

const MainWrapper = () => {
  const { loginLoading } = useContext(UserContext) as UserContextType

  return (
    <>
      {loginLoading ? (
        <FullPageLoader />
      ) : (
        <ConfigContainer>
          <BranchContainer>
            <InternetConnection />
            <Main />
          </BranchContainer>
        </ConfigContainer>
      )}
    </>
  )
}

export default MainWrapper
