import React from "react"
import { useContext, useEffect, useState } from "react"
import { Button, Divider, Grid, Typography } from "@mui/material"
import PosContext from "main/context/pos/PosContext"
import { PosContextType } from "main/context/pos/PosContextTypes"
import PosProductContext from "main/context/pos/PosProduct/PosProductContext"
import { PosProductType } from "main/context/pos/PosProduct/PosProductTypes"
import Discount from "main/pos/cart/Discount"
import SimpleDialog from "main/common/simple_dialog/SimpleDialog"
import ListElement from "main/common/ListElement"
import { numberRoundUp } from "main/util/NumberHelper"
import { DiscountTypes, OrderTypes } from "main/pos/constant/PosConstant"
import { useDefaultDeliveryFlag } from "main/pos/hooks/PosHooks"
import { BranchContextType } from "main/context/branch/BranchContextTypes"
import BranchContext from "main/context/branch/BranchContext"
import TitleIcon from "main/common/TitleIcon"
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined"
import Voucher from "main/pos/vouchers/Voucher"
import DiscountsDisplay from "main/pos/cart/Discount/DiscountsDisplay"
import { getApplicableTaxRate } from "main/util/ProductHelper"
import { getActiveRevenueIntegration } from "utils/fbrHelpers"
import ConnectionHooks from "main/context/connection/ConnectionHook"

const styles = () => ({
  cursorPointer: {
    cursor: "pointer"
  },
  discountButton: {
    margin: "8px 0px"
  }
})
const PriceSummary = ({
  hideTitle,
  hideDiscountBtn
}: {
  hideTitle?: boolean
  hideDiscountBtn?: boolean
}) => {
  const classes = styles()
  const posContext = useContext(PosContext) as PosContextType
  const productContext = useContext(PosProductContext) as PosProductType
  const { getBranchBvid } = useContext(BranchContext) as BranchContextType
  const { isInternetOn } = ConnectionHooks()
  const [discountDialog, setDiscountDialog] = useState(false)
  const defaultDeliveryFlag = useDefaultDeliveryFlag()
  const merchantBvid = getBranchBvid()
  const merchantIntegrations = posContext.merchantIntegrations
  const activeRevenueIntegration =
    getActiveRevenueIntegration(merchantIntegrations)?.partner

  const { taxRate, digitalTaxRate, isProductLotManagementAllowed } =
    posContext?.merchantProfileData?.config ?? {}

  const handleAddDiscount = () => {
    setDiscountDialog(true)
  }

  const { selectedProductsList, getProductsSubTotal } = productContext

  const addDiscount = () => {
    const {
      specialDiscount,
      flatDiscountPercentage,
      setFlatDiscount,
      voucherDiscount,
      setDiscountType,
      categoryDiscount,
      binDiscount
    } = posContext
    if (binDiscount) {
      return binDiscount.discountedAmount
    } else {
      if (voucherDiscount && selectedProductsList?.length) {
        return specialDiscount + voucherDiscount
      } else if (flatDiscountPercentage && selectedProductsList?.length) {
        setDiscountType(DiscountTypes.StoreFlatDiscount)

        const flatAmount =
          (getProductsSubTotal() * flatDiscountPercentage) / 100
        setFlatDiscount(flatAmount)
        return flatAmount + specialDiscount
      } else if (categoryDiscount) {
        setDiscountType(DiscountTypes.Category)
        return categoryDiscount + specialDiscount
      } else if (specialDiscount) return specialDiscount
      return 0
    }
  }
  const removeDiscount = () => {
    posContext.setSpecialDiscount(0)
  }

  useEffect(() => {
    posContext.setDiscount(addDiscount())
  }, [
    productContext.selectedProductsList,
    posContext.specialDiscount,
    posContext.voucherDiscount,
    posContext.flatDiscountPercentage,
    posContext.categoryDiscount,
    posContext.binDiscount,
    getProductsSubTotal()
  ])

  useEffect(() => {
    productContext.getProductsSubTotal()
    productContext.calculateProductsTotal()

    if (isProductLotManagementAllowed)
      posContext.saveProductLotTax(productContext.selectedProductsList)
    else
      posContext.taxGet({
        taxRate,
        digitalTaxRate,
        isInclusiveTax: productContext.isInclusiveTax,
        amount: productContext.getProductsSubTotalArray("originalPrice")
      })
  }, [
    productContext.selectedProductsList,
    posContext.paymentMethod,
    productContext.selectedProduct,
    merchantBvid,
    productContext.isInclusiveTax
  ])

  useEffect(() => {
    if (!isInternetOn) posContext.setVoucherDiscount(0)
  }, [isInternetOn])

  const showVoucher =
    productContext.selectedProductsList?.length && isInternetOn

  const tax = getApplicableTaxRate(
    posContext.isDigitalPayment,
    digitalTaxRate,
    taxRate
  )

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          {hideTitle ? (
            <></>
          ) : (
            <TitleIcon
              icon={<LocalOfferOutlinedIcon />}
              title="Price Summary"
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <ListElement
            label={
              productContext.isInclusiveTax ? (
                <span>
                  SubTotal{" "}
                  <Typography
                    variant="caption"
                    display="inline"
                    color="GrayText"
                  >
                    (Tax Inclusive)
                  </Typography>
                </span>
              ) : (
                "SubTotal"
              )
            }
            id="subtotal"
            value={numberRoundUp(productContext.getProductsSubTotal())}
          />

          <DiscountsDisplay />
          {posContext.tax ? (
            <ListElement
              label={`Tax ${tax ? `(GST)` : ""}`}
              id="tax-txt"
              value={posContext.tax}
            />
          ) : (
            <></>
          )}
          {posContext.FBRPosId && posContext.fbrCharges ? (
            <ListElement
              label={`${activeRevenueIntegration ? activeRevenueIntegration?.toUpperCase() : "POS"} Fee`}
              id="fbr-txt"
              value={posContext.fbrCharges}
            />
          ) : (
            <></>
          )}
          {posContext.orderType === OrderTypes.Delivery ? (
            <ListElement
              label="Delivery Fee"
              id="delivery-txt"
              value={
                defaultDeliveryFlag
                  ? "to be calculated"
                  : posContext.deliveryCharges
              }
            />
          ) : (
            <></>
          )}

          {posContext.tip ? (
            <ListElement label="Tip" id="tip-tetx" value={posContext.tip} />
          ) : (
            <></>
          )}
        </Grid>
        {showVoucher ? <Voucher /> : <></>}

        {hideDiscountBtn ? (
          <></>
        ) : (
          <Grid item xs={12}>
            {posContext.specialDiscount ? (
              <Button
                size="small"
                color="primary"
                variant="outlined"
                sx={classes.discountButton}
                onClick={removeDiscount}
              >
                Remove Discount
              </Button>
            ) : (
              <Button
                size="small"
                color="primary"
                variant="outlined"
                data-id="discount-btn"
                sx={classes.discountButton}
                onClick={handleAddDiscount}
              >
                Add Discounts
              </Button>
            )}
          </Grid>
        )}

        <Grid item xs={12}>
          <Divider />
          <ListElement
            bold
            label={"Total"}
            id="Total-bill"
            value={productContext.calculateProductsTotal()}
          />
        </Grid>
      </Grid>
      <SimpleDialog
        maxWidth="xs"
        closeButton={true}
        dialogOpen={discountDialog}
        onHandleClose={() => setDiscountDialog(false)}
        title={<Typography variant="h5">Add Discount</Typography>}
        body={
          <Discount
            handleDiscount={posContext.setSpecialDiscount}
            setDiscountDialog={setDiscountDialog}
          />
        }
      />
    </>
  )
}

export default PriceSummary
