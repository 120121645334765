import {
  addToStorage,
  getFromStorage,
  keys
} from "main/util/LocalStorageHelper"

export const saveShiftData = (key: string, value: string | number) => {
  const shifts = getFromStorage(keys.SHIFT)
  if (shifts) {
    if (typeof value === "string") {
      shifts[key] = value
    } else {
      let newValue = shifts[key] || 0
      newValue += value
      shifts[key] = newValue
    }
    addToStorage(keys.SHIFT, shifts)
    return
  } else {
    const shift = {
      [key]: value
    }
    addToStorage(keys.SHIFT, shift)
  }
}
