import React, { useContext } from "react"
import { Grid } from "@mui/material"
import ProductOptionDialog from "main/pos/product/product_option/ProductOptionDialog"
import { PosProductType } from "main/context/pos/PosProduct/PosProductTypes"
import PosProductContext from "main/context/pos/PosProduct/PosProductContext"
import { useProductScanner } from "main/pos/hooks/ProductHooks"
import {
  getActiveOptionAndVariant,
  extractProductData
} from "main/pos/helpers/ProductHelpers"
import ProductsList from "main/pos/product/product_list/ProductsList"

import ProductLotsDialog from "../product_lots/product_lots_dialog/ProductLotsDialog"
import ConfigContext from "main/context/config/ConfigContext"
import { ConfigContextTypes } from "main/context/config/ConfigContextTypes"

const ProductsSection = ({
  offlineProductData,
  fetchOfflineProducts
}: {
  offlineProductData: any
  fetchOfflineProducts: any
}) => {
  const productContext = useContext(PosProductContext) as PosProductType
  const { isProductLotOn } = useContext(ConfigContext) as ConfigContextTypes

  const selectProduct = (product: any, isFromList = false) => {
    const mainOptionNestedSorting = (product: any) => {
      if (!product?.mainOptions?.length) return product

      product.mainOptions = product?.mainOptions
        ?.sort(
          (optionA: any, optionB: any) => optionA.sortOrder - optionB.sortOrder
        )
        ?.map((mainOption: any) => {
          mainOption?.mainOptionValues
            ?.sort(
              (optionA: any, optionB: any) =>
                optionA?.sortOrder - optionB?.sortOrder
            )
            ?.map((mainOptionValue: any) => {
              mainOptionValue?.subOptions
                ?.sort(
                  (optionA: any, optionB: any) =>
                    optionA?.sortOrder - optionB?.sortOrder
                )
                ?.map((subOption: any) => {
                  subOption?.subOptionValues?.sort(
                    (optionA: any, optionB: any) =>
                      optionA?.sortOrder - optionB?.sortOrder
                  )
                  return subOption
                })

              return mainOptionValue
            })

          return mainOption
        })
      return product
    }

    productContext.setSelectedProduct(mainOptionNestedSorting(product))

    if (
      (getActiveOptionAndVariant(product.mainOptions)?.length ||
        getActiveOptionAndVariant(product.variantOptions)?.length) &&
      !isProductLotOn
    ) {
      productContext.setOpenOptionDialog(true)
    } else if (product?.productLots?.length && isFromList)
      productContext.setOpenLotsDialog(true)
    else {
      productContext.handleProduct(extractProductData(product))
    }
  }

  useProductScanner(offlineProductData, selectProduct, fetchOfflineProducts)
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <ProductsList
            products={offlineProductData}
            handleProduct={selectProduct}
          />
        </Grid>
      </Grid>
      <ProductOptionDialog />
      <ProductLotsDialog />
    </>
  )
}
export default React.memo(ProductsSection)
