import React, { useContext } from "react"
import SimpleDialog from "main/common/simple_dialog/SimpleDialog"
import PosProductContext from "main/context/pos/PosProduct/PosProductContext"
import ProductOptions from "main/pos/product/product_option/ProductOptions"
import ProductOptionTitle from "main/pos/product/product_option/ProductOptionTitle"
import { PosProductType } from "main/context/pos/PosProduct/PosProductTypes"

const ProductOptionDialog = () => {
  const productContext = useContext(PosProductContext) as PosProductType

  return (
    <SimpleDialog
      fullWidth
      closeButton
      maxWidth="xl"
      dialogOpen={productContext.openOptionDialog}
      onHandleClose={productContext.optionDialogClose}
      title={<ProductOptionTitle />}
      body={<ProductOptions />}
    />
  )
}
export default ProductOptionDialog
