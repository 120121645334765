import React, { useEffect, useState } from "react"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"
import { usePosContext, useConfigContext } from "main/hooks/ContextHooks"

const styles = () => ({
  edit: {
    position: "absolute",
    right: "22px",
    top: "6px"
  }
})

const OrderIdentifierTextBox = () => {
  const classes = styles()

  const {
    orderIdentifier,
    setOrderIdentifier,
    isDraftOrderLoaded: isdraftOrderLoaded
  } = usePosContext()
  const { isRestaurantMode } = useConfigContext()

  const identifierText = isRestaurantMode()
    ? "Table Number"
    : "Order Identifier"

  const [isEditable, setIsEditable] = useState(false)

  useEffect(() => {
    setIsEditable(!isdraftOrderLoaded)
  }, [isdraftOrderLoaded])

  const buttonCondition = isdraftOrderLoaded && !isEditable
  return (
    <Grid container item xs={12} paddingX={2} position="relative">
      <Grid item flexGrow={1}>
        <TextField
          fullWidth
          name="order-identifier"
          label={identifierText}
          id="order-identifier"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          disabled={!isEditable}
          value={orderIdentifier}
          onChange={(e) => setOrderIdentifier(e.target.value)}
        />
      </Grid>

      {buttonCondition ? (
        <Grid item sx={classes.edit}>
          <Button
            size="large"
            color="primary"
            variant="contained"
            onClick={() => setIsEditable(true)}
          >
            Edit
          </Button>
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  )
}
export default OrderIdentifierTextBox
