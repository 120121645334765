import { Dispatch } from "@reduxjs/toolkit"
import { setIsInternetOn } from "main/context/connection/ConnectionSlice"

export const checkOnlineStatus = (dispatch: Dispatch) => {
  const condition = navigator.onLine ? "online" : "offline"
  if (condition === "online") {
    fetch("https://google.com", {
      mode: "no-cors"
    })
      .then(() => {
        dispatch(setIsInternetOn(true))
      })
      .catch((error) => {
        dispatch(setIsInternetOn(false))
        console.error(error)
      })
    return
  }

  return dispatch(setIsInternetOn(false))
}
