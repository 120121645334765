export const allowOnlyNumbers = (value: string): string => {
  return value?.replace(/[^0-9]/g, "")
}

export const allowPositiveNumbers = (value: string) => {
  const regex = /^[1-9]\d*$/
  return regex.test(value) ? value : "1"
}

export const convert03to92 = (number: string): string => {
  if (number[0] === "0") {
    return number.replace("0", "+92")
  }
  return number
}

export const numberRoundUp = (price: number): string | number => {
  if (price === 0) return "0"
  return Math.round(price)
}
