import React from "react"
import { Grid, Typography, Theme } from "@mui/material"
import { useTheme } from "@mui/styles"

const styles = (theme: Theme) => ({
  labelContainer: {
    paddingBottom: 3
  },
  title: {
    fontSize: "18px"
  },
  subTitle: {
    fontSize: "15px",
    color: theme.custom.lightgrey
  }
})

type TitleTypes = {
  labelClass?: object
  title?: string
  titleClass?: object
  subtitle?: string
  subTitleClass?: object
  handleClick?: () => void
}

const Title = ({
  labelClass,
  title,
  titleClass,
  subtitle,
  subTitleClass,
  handleClick
}: TitleTypes) => {
  const classes = styles(useTheme())
  return (
    <Grid
      sx={{ ...classes.labelContainer, ...labelClass }}
      onClick={handleClick}
    >
      <Typography
        id="title"
        variant="h4"
        sx={{ ...classes.title, ...titleClass }}
      >
        {title}
      </Typography>
      {subtitle ? (
        <Typography
          id="subtitle"
          component="p"
          sx={{ ...classes.subTitle, ...subTitleClass }}
        >
          {subtitle}
        </Typography>
      ) : (
        <></>
      )}
    </Grid>
  )
}

export default Title
