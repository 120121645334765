import React, { useContext, useState } from "react"
import { TextField, Button, Grid, IconButton } from "@mui/material"
import PosProductContext from "main/context/pos/PosProduct/PosProductContext"
import { PosProductType } from "main/context/pos/PosProduct/PosProductTypes"
import { allowOnlyNumbers } from "main/util/NumberHelper"
import { getExtraItemData } from "main/pos/helpers/ProductHelpers"
import { Add } from "@mui/icons-material"

const styles = () => ({
  extraItemContainer: {
    marginTop: 3
  },
  addButtonContainer: {
    textAlign: "center"
  }
})

const ExtraItem = ({ setExtraItemDialog }: { setExtraItemDialog: any }) => {
  const classes = styles()
  const [extraItems, setExtraItems] = useState<any>([getExtraItemData()])

  const productContext = useContext(PosProductContext) as PosProductType

  const incrementField = () => {
    setExtraItems((prevExtraItems: any) => [
      ...prevExtraItems,
      getExtraItemData()
    ])
  }

  const checkFieldsEmpty = () => {
    let checkEmptyItem = false
    extraItems.forEach((item: any, index: number) => {
      if (item.name === "") {
        extraItems[index].nameError = true
        setExtraItems([...extraItems])
        checkEmptyItem = true
      }
      if (item.price === "") {
        extraItems[index].priceError = true
        setExtraItems([...extraItems])
        checkEmptyItem = true
      }
      if (item.quantity === "" || item.quantity === "0") {
        extraItems[index].quantityError = true
        setExtraItems([...extraItems])
        checkEmptyItem = true
      }
    })
    return checkEmptyItem
  }

  const handleChange = (e: any, index: number) => {
    const { name, value } = e.target
    const tempExtraItems = [...extraItems]

    tempExtraItems[index][name] =
      name === "price" || name === "quantity" ? allowOnlyNumbers(value) : value

    const errorName = `${name}Error`
    extraItems[index][errorName] = false

    setExtraItems([...tempExtraItems])
  }

  const handleSave = () => {
    if (!checkFieldsEmpty()) {
      productContext.handleExtraItems(extraItems)
      setExtraItemDialog(false)
    }
  }

  return (
    <>
      {extraItems?.map((item: any, index: any) => {
        const showAddButton = index === extraItems.length - 1
        return (
          <Grid
            container
            sx={classes.extraItemContainer}
            key={index}
            id={`extra-item-row-${index}`}
          >
            <Grid item xs={1}>
              {showAddButton ? (
                <Grid sx={classes.addButtonContainer}>
                  <IconButton
                    id="more-custom-item-btn"
                    onClick={incrementField}
                  >
                    <Add color="primary" fontSize="large" />
                  </IconButton>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
            <Grid container item spacing={2} xs={11}>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="name"
                  label="Name"
                  placeholder="Extra Raita"
                  id="custom-item-name-text"
                  error={item.nameError}
                  helperText={item.nameError ? "Field cannot be empty." : ""}
                  value={item.name}
                  onChange={(e) => handleChange(e, index)}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="price"
                  label="Price"
                  placeholder="200"
                  id="custom-item-price-text"
                  error={item.priceError}
                  helperText={item.priceError ? "Field cannot be empty." : ""}
                  value={item.price}
                  onChange={(e) => handleChange(e, index)}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="quantity"
                  label="Quantity"
                  placeholder="1"
                  id="custom-item-quantity-text"
                  error={item.quantityError}
                  helperText={
                    item.quantityError ? "Field cannot be empty or 0" : ""
                  }
                  value={item.quantity}
                  onChange={(e) => handleChange(e, index)}
                />
              </Grid>
            </Grid>
          </Grid>
        )
      })}

      <Grid
        container
        spacing={2}
        justifyContent="flex-end"
        sx={classes.extraItemContainer}
      >
        <Grid item xs={3}>
          <Button
            fullWidth
            onClick={handleSave}
            id="add-custom-items-done-btn"
            color="primary"
            variant="contained"
            size="large"
          >
            Done
          </Button>
        </Grid>
      </Grid>
    </>
  )
}
export default ExtraItem
