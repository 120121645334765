import React from "react"
import { Button, Grid, Typography, Theme, useTheme } from "@mui/material"
import Logo from "main/common/CommonLogo"
import Title from "main/common/Title"
import Chikoo_Orange from "assets/images/logos/chikoo_orange.png"
import LaptopImage from "assets/images/general/laptop.svg"

const styles = (theme: Theme) => ({
  container: {
    minHeight: "100vh",
    backgroundColor: theme.custom.bgGrey,
    padding: 20
  },
  btn: {
    margin: "12px 0",
    borderRadius: 15
  },
  image: {
    width: 300
  },
  text: {
    fontSize: 18,
    textAlign: "center"
  }
})

function MobileView() {
  const classes = styles(useTheme())
  function download() {
    const link = "https://chikoo.page.link/chikooweb"
    window.open(link, "__blank")
  }

  return (
    <Grid
      container
      sx={classes.container}
      justifyContent="center"
      alignItems="center"
      direction="column"
      spacing={2}
    >
      <Grid item>
        <img src={LaptopImage} alt="Laptop" style={classes.image} />
      </Grid>
      <Grid item>
        <Title title="You’re all set!" />
      </Grid>
      <Grid item>
        <Typography sx={classes.text}>
          To get started with our web version, come back on desktop or download
          the app.
        </Typography>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={download}
          sx={classes.btn}
        >
          Download App
        </Button>
      </Grid>
      <Grid item>
        <Logo url={Chikoo_Orange} />
      </Grid>
    </Grid>
  )
}

export default MobileView
