import React, { useContext } from "react"
import { Typography, Grid, Theme } from "@mui/material"
import { useTheme } from "@mui/styles"
import PosProductContext from "main/context/pos/PosProduct/PosProductContext"
import { PosProductType } from "main/context/pos/PosProduct/PosProductTypes"
import {
  convertSubVariantDetails,
  extractProductData,
  getProductPrice
} from "main/pos/helpers/ProductHelpers"
import { numberRoundUp } from "main/util/NumberHelper"
import ConfigContext from "main/context/config/ConfigContext"
import { ConfigContextTypes } from "main/context/config/ConfigContextTypes"
import { getPriceIfVariant, lowestPriceVariant } from "main/util/ProductHelper"

const styles = (theme: Theme) => ({
  red: {
    color: theme.custom.red
  },
  quantityErrorMessage: {
    color: theme.custom.red,
    fontSize: 12,
    fontWeight: 900
  }
})

const ProductOptionTitle = () => {
  const classes = styles(useTheme())
  const productContext = useContext(PosProductContext) as PosProductType
  const { isRestaurantMode, currency } = useContext(
    ConfigContext
  ) as ConfigContextTypes

  const product: any = productContext.selectedProduct
  const productDetails = extractProductData(product)

  const productQuantityMessage = `${product.name} cannot aaaa be added more than ${productContext.getProductLimit(product)}`
  const variantProductDetail = convertSubVariantDetails(
    product,
    productContext?.selectedVariants
  )

  const variant = lowestPriceVariant(product)
  const variantProduct = getPriceIfVariant(variant, product)

  function getQuantityErrorMessage() {
    return !productContext.isProductUpdate
      ? !productContext.isQuantityInLimit(productDetails)
        ? productQuantityMessage
        : ""
      : ""
  }

  return (
    <Grid container>
      <Grid item>
        <Typography variant="h5">
          {`${product.name} - ${currency} `}

          {isRestaurantMode() ? (
            <Typography
              component="span"
              color={product?.discountedPrice < product.price ? "error" : ""}
            >
              {numberRoundUp(getProductPrice(product, "price", true))}
            </Typography>
          ) : (
            <Typography
              component="span"
              color={
                variantProductDetail?.price <
                variantProductDetail?.originalPrice
                  ? "error"
                  : ""
              }
            >
              {numberRoundUp(
                variantProductDetail?.price ||
                  getProductPrice(variantProduct, "variantPrice")
              )}
            </Typography>
          )}

          <Typography sx={classes.quantityErrorMessage}>
            {getQuantityErrorMessage()}
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  )
}
export default React.memo(ProductOptionTitle)
