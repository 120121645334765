import React from "react"
import { forwardRef, useContext } from "react"
import { Grid, Typography } from "@mui/material"
import QRCode from "react-qr-code"
import RowItem from "main/pos/receipt/common/RowItem"
import CustomerProductList from "main/pos/receipt/product_list/CustomerProductList"
import RowThreeCols from "main/pos/receipt/common/RowThreeCols"
import { numberRoundUp } from "main/util/NumberHelper"
import { getDateAndTime } from "main/util/DateTimeHelper"
import ConfigContext from "main/context/config/ConfigContext"
import { ConfigContextTypes } from "main/context/config/ConfigContextTypes"
import Barcode from "react-barcode"
import FBRReceiptDetails from "main/pos/receipt/FBRReceiptDetails"
import PosContext from "main/context/pos/PosContext"
import { PosContextType } from "main/context/pos/PosContextTypes"
import { getApplicableTaxRate } from "main/util/ProductHelper"
import {
  DisplayPaymentTypes,
  PaymentStatusType,
  DisplayTypes,
  webLinks
} from "./helpers"
import RouteHelpers from "utils/RouteHelpers"
import { getFromStorage } from "main/util/LocalStorageHelper"
import { lotProductTotal } from "../product/product_lots/helpers/ProductLotsHelper"
import { DiscountTypes, OrderTypes } from "../constant/PosConstant"
import chikooBlackLogosvg from "assets/images/logos/chikoo_black_logo_svg.svg"
import UserContext from "main/context/user/UserContext"
import { UserContextType } from "main/context/user/UserContextTypes"
import { getActiveRevenueIntegration } from "utils/fbrHelpers"
import { CartSubItems } from "../common/CartItemTypes"
import {
  calculateSubItemsTotal,
  convertSubVariantDetails,
  getProductPrice
} from "../helpers/ProductHelpers"
import PosProductContext from "main/context/pos/PosProduct/PosProductContext"
import { PosProductType } from "main/context/pos/PosProduct/PosProductTypes"
import { ProductDataType } from "../product/product_list/ProductListTypes"
import { useCustomerChange } from "../hooks/CheckoutHooks"
import RowThreeItem from "./common/RowThreeItem"
import ConnectionHooks from "main/context/connection/ConnectionHook"

const styles = () => ({
  container: {
    width: 300,
    padding: 1
  },
  logo: {
    width: 120
  },
  fbrLogo: {
    width: 80
  },
  fontSmall: {
    fontSize: 12,
    margin: 0,
    height: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  space: {
    margin: "8px 0px"
  },
  productspace: {
    margin: "8px 0px 4px 0px"
  },
  divider: {
    margin: "8px 0",
    border: "2px solid"
  },
  fontBold: {
    fontWeight: 700
  },
  divDivider: {
    backgroundColor: "black",
    height: 1,
    margin: "8px 0px"
  },
  greyDivider: {
    backgroundColor: "#c8c8c8",
    height: 1,
    margin: "8px 0px 2px"
  },
  saleTaxInvoice: {
    fontSize: 14,
    fontWeight: 700,
    textAlign: "center"
  },
  invoiceTitle: {
    fontWeight: 700,
    textAlign: "center"
  },
  prdTitle: {
    fontWeight: 900,
    textAlign: "center",
    color: "white",
    backgroundColor: "black",
    marginBottom: 2
  }
})

function CustomerReceipt(props: any, ref: any) {
  const classes = styles()
  const { currency } = useContext(ConfigContext) as ConfigContextTypes
  const posContext = useContext(PosContext) as PosContextType
  const { flatDiscount, binDiscount, categoryVoucherId, isHoldOrder } =
    posContext
  const {
    masterLogo: logoMasterFromConfig,
    logoImageUrl: logoFromConfig,
    isProductLotOn,
    isRestaurantMode
  } = useContext(ConfigContext) as ConfigContextTypes
  const productContext = useContext(PosProductContext) as PosProductType
  const { isInternetOn } = ConnectionHooks()

  const { getName } = useContext(UserContext) as UserContextType

  const {
    data,
    merchantData,
    isFBRIntegrated,
    masterData,
    merchantIntegrations,
    prd = false
  } = props

  const {
    createdAt,
    offlineOrderDate,
    orderNumber,
    deliveryDetails,
    customerDetails,
    deliveryCharges,
    subTotal,
    taxAmount,
    totalAmount,
    discountLine,
    comment,
    orderedProducts,
    orderLineItems = [],
    orderLines,
    temp = {},
    fbrInvoiceNo,
    fbrPosCharges,
    paymentType,
    orderType,
    orderIdentifier,
    tip
  } = data?.order ?? {}

  const customerChange = useCustomerChange()
  const cashReceived = posContext.cashReceived

  const { address, name, phoneNumber, alternatePhoneNumber } =
    deliveryDetails || customerDetails || {}
  const { voucherDiscount, voucherDetails, tip: tipFromApp } = posContext
  const { profile, config, storeName } = merchantData ?? {}
  const { taxRate, digitalTaxRate } = config || {}
  const { ntn } = profile || {}
  const { phones, address: merchantAddressDetails } = profile?.contacts[0] ?? {}
  const addressCoordinates =
    address?.location?.coordinates[1] && address?.location?.coordinates[0]
      ? `${address?.location?.coordinates[1]},${address?.location?.coordinates[0]}`
      : null

  const storeData = masterData || merchantData
  const printLogo = storeData?.config?.printLogoGreyScale
  const { receiptTitle } = storeData.config || {}
  const printCustomText = storeData?.config?.printCustomText

  const storeLogo = printLogo || logoFromConfig || logoMasterFromConfig

  const activeRevenueIntegration =
    getActiveRevenueIntegration(merchantIntegrations)?.partner

  const orderDate = createdAt
    ? getDateAndTime(createdAt)
    : offlineOrderDate || temp.offlineOrderDate

  const PhoneNumbers = ({ phones }: { phones: any }) => {
    return (
      <>
        {phones?.map((phone: any, index: any) => (
          <RowItem key={index} fontClass="fontNormal" label={phone} />
        ))}
      </>
    )
  }

  const Address = ({
    text = "",
    address
  }: {
    text?: string
    address?: any
  }) => {
    const addressDetails = `${address?.details}  ${address?.area?.name}, ${address?.area?.city?.name}`
    return (
      <RowItem
        fontClass="fontNormal"
        label={
          <span>
            {text}
            <span style={classes.fontBold}>{addressDetails}</span>
          </span>
        }
      />
    )
  }

  const generateAmount = (value: string | number) => {
    return `${currency} ${value}`
  }

  const tax = getApplicableTaxRate(
    posContext.isDigitalPayment,
    digitalTaxRate,
    taxRate
  )
  const partner = RouteHelpers.getWhiteLabelPartner()
  const checkUser = getFromStorage("user")
  const { tld, slug, bvid, hasDomain } =
    checkUser.user.userMerchant || checkUser.user.userMaster || {}
  const url = hasDomain ? slug : `${slug}.${tld}`
  const websiteUrl = webLinks(url, checkUser?.user?.userMaster?.bvid || bvid)
  const offlineProductLots = temp?.orderedProducts?.productLots?.length
  const finalTax = !isInternetOn ? temp?.taxAmount : taxAmount
  const finalTotalAmount = !isInternetOn
    ? offlineProductLots
      ? lotProductTotal(temp?.orderedProducts, "productLots", "quantityInside")
      : temp?.totalAmount
    : totalAmount

  const taxToShow = generateAmount(numberRoundUp(finalTax))

  const bothTips = tip || parseInt(tipFromApp || "0")
  const finalTip = isRestaurantMode() ? bothTips || 0 : 0

  const total = generateAmount(Math.round(finalTotalAmount))

  const getProductTotal = (product: ProductDataType) => {
    const productTotal =
      parseInt(product.quantity as string) * getProductPrice(product) || 0

    const subItemsTotal =
      calculateSubItemsTotal(product) * Number(product.quantity as string) || 0

    if (!isRestaurantMode())
      return (
        convertSubVariantDetails(product, product.subItems)?.price *
        Number(product.quantity as string)
      )
    return productTotal + subItemsTotal
  }
  const getTotal = (product: any) => {
    if (product?.productLots?.length) return numberRoundUp(product?.lotTotal)
    else if (product?.batches?.length)
      return numberRoundUp(product.batchedTotal)
    return numberRoundUp(getProductTotal(product) || 0)
  }

  const calculateAndShowVoucherDiscount = (
    product: {
      price: number
      flatDiscount: number
      quantity: number
      discountedPrice: number
      lotTotal: number
      categoryId: string
      subItems: Array<CartSubItems>
      variants: Array<any>
      discountedAmount: number
    },
    voucherDetails: {
      categoryXid: string
      maxDiscount: number
      discount: number
    },
    voucherDiscount: number
  ): number => {
    const productsList = data?.order?.orderedProducts?.length
      ? data?.order?.orderedProducts
      : productContext?.selectedProductsList
    const getProductsTotal = productsList.reduce(
      (
        acc: number,
        currentItem: {
          categoryId: string
          price: number
          lotTotal?: number
          subItems: Array<CartSubItems>
          quantity: number
          discountedPrice: number
          variants: Array<any>
          variantOptionValues: Array<any>
        }
      ) => {
        if (
          currentItem.categoryId ===
          (voucherDetails?.categoryXid || categoryVoucherId)
        ) {
          // INFO this is specific for meriPharmacy
          if (isProductLotOn && currentItem.lotTotal) {
            acc += currentItem.lotTotal
          } else {
            acc += Number(getTotal(currentItem))
          }
        }

        return acc
      },
      0
    )

    const discountType = discountLine?.discountType
    const showZeroDiscount =
      binDiscount?.discountedAmount ||
      (discountType === DiscountTypes.StoreFlatDiscount &&
        DiscountTypes.Category) ||
      discountType === DiscountTypes.StoreFlatDiscount ||
      (discountType === DiscountTypes.Flat &&
        product.categoryId !== categoryVoucherId) ||
      (discountType === DiscountTypes.Percent &&
        product.categoryId !== categoryVoucherId) ||
      (discountType === DiscountTypes.Voucher &&
        product.categoryId !== categoryVoucherId)

    if (data?.order?.orderNumber && showZeroDiscount) {
      return 0
    }
    const productPrice = Number(getTotal(product))
    const orderProductsLength = data?.order?.orderedProducts?.length

    const isDraftOrder =
      orderProductsLength &&
      Math.round(discountLine?.discountedAmount) >= voucherDetails?.maxDiscount

    const voucherCategoryProduct = product.categoryId === categoryVoucherId

    // INFO voucher has more priority than category discount
    if (
      (isRestaurantMode() && (voucherDiscount || voucherCategoryProduct)) ||
      voucherCategoryProduct
    ) {
      if (voucherDiscount >= voucherDetails?.maxDiscount || isDraftOrder) {
        const productsTotalPrice = getProductsTotal

        // INFO this is spefic for Meri Pharmacy
        if (isProductLotOn) {
          return (
            (product.lotTotal / productsTotalPrice) * voucherDetails.maxDiscount
          )
        }

        return voucherCategoryProduct
          ? (productPrice / productsTotalPrice) * voucherDetails.maxDiscount
          : product?.discountedAmount || 0
      } else if (voucherDiscount < voucherDetails?.maxDiscount) {
        return voucherCategoryProduct
          ? productPrice * (voucherDetails?.discount / 100)
          : product?.discountedAmount || 0
      } else {
        // INFO this is specific for Meri Pharmacy
        if (isProductLotOn) {
          return (product.lotTotal * voucherDetails.discount) / 100
        }
        return (
          (productPrice *
            (voucherDetails.discount
              ? voucherDetails.discount
              : product?.flatDiscount)) /
          100
        )
      }
    }
    if (!isDraftOrder && isRestaurantMode()) {
      return (
        product?.discountedAmount ||
        (productPrice *
          (voucherDetails.discount
            ? voucherDetails.discount
            : product?.flatDiscount)) /
          100
      )
    }

    // INFO this is where I am checking for Category Discount
    return isProductLotOn
      ? product.lotTotal * (product.flatDiscount / 100)
      : product?.discountedAmount || productPrice * (product.flatDiscount / 100)
  }
  const showDiscountedAmount = (
    product: {
      price: number
      flatDiscount: number
      quantity: number
      discountedPrice: number
      lotTotal: number
      categoryId: string
      subItems: Array<CartSubItems>
      variants: Array<any>
      discountedAmount: number
    },
    voucherDetails: {
      categoryXid: string
      maxDiscount: number
      discount: number
    },
    voucherDiscount: number
  ) => {
    if ((flatDiscount && !(voucherDiscount || isHoldOrder)) || binDiscount)
      return ""

    return isNaN(
      calculateAndShowVoucherDiscount(product, voucherDetails, voucherDiscount)
    )
      ? ""
      : calculateAndShowVoucherDiscount(
            product,
            voucherDetails,
            voucherDiscount
          ) > 0
        ? Number(
            calculateAndShowVoucherDiscount(
              product,
              voucherDetails,
              voucherDiscount
            ).toFixed(2)
          )
        : ""
  }
  const getTotalItems = () => {
    if (!isInternetOn) {
      if (orderLineItems) {
        return temp?.orderedProducts?.length + orderLineItems?.length
      }
      return temp?.orderedProducts?.length
    }
    if (orderLines) {
      return orderedProducts?.length + orderLines?.length
    }
    return orderedProducts?.length
  }
  const getProductQuantity = () => {
    let productOrdered = []
    if (!isInternetOn) {
      if (orderLineItems) {
        productOrdered = temp?.orderedProducts.concat(orderLineItems)
      } else {
        productOrdered = temp?.orderedProducts
      }
    } else {
      if (orderLines) {
        productOrdered = orderedProducts?.concat(orderLines)
      } else {
        productOrdered = orderedProducts
      }
    }

    return productOrdered?.reduce((accumulator: any, currentValue: any) => {
      return accumulator + currentValue?.quantity
    }, 0)
  }
  const getProductsTotal = (product: any) => {
    if (product?.productLots?.length) {
      return numberRoundUp(
        lotProductTotal(product, "productLots", "quantityInside")
      )
    }

    return numberRoundUp(
      !isInternetOn
        ? Number(calculateSubItemsTotal(product))
          ? (Number(product.discountedPrice || product.price) +
              Number(calculateSubItemsTotal(product))) *
            product.quantity
          : Number(product.discountedPrice || product.price) * product.quantity
        : (Number(product.discountedPrice || product.price) +
            Number(calculateSubItemsTotal(product))) *
            product.quantity
    )
  }

  const getDiscountedSubTotal = () => {
    let productsOrdered = []
    if (!isInternetOn) {
      productsOrdered = temp?.orderedProducts?.concat(orderLineItems)
    } else {
      if (orderLines) {
        productsOrdered = orderedProducts?.concat(orderLines)
      } else {
        productsOrdered = orderedProducts
      }
    }

    const totalArray = productsOrdered?.map((product: any) => {
      const categoryVoucherDiscount = Number(
        showDiscountedAmount(product, voucherDetails, voucherDiscount)
      )
      return Number(getProductsTotal(product)) - categoryVoucherDiscount
    })
    return numberRoundUp(
      totalArray?.reduce((acc: number, current: number) => acc + current, 0)
    )
  }
  return (
    <>
      <Grid container ref={ref} sx={classes.container}>
        <Grid item xs={12} textAlign="center" sx={classes.space}>
          {storeLogo ? (
            <img src={storeLogo} alt="logo" style={classes.logo} />
          ) : (
            <></>
          )}

          <RowItem labelBold fontClass="fontNormal" label={storeName} />
          {ntn && !prd ? (
            <RowItem labelBold fontClass="fontNormal" label={`NTN: ${ntn}`} />
          ) : (
            <></>
          )}
          <Grid textAlign="center" sx={classes.space}>
            <RowItem fontClass="fontNormal" label="Shop online at" />
            <RowItem labelBold fontClass="fontNormal" label={websiteUrl} />
          </Grid>
          {merchantAddressDetails ? (
            <Address address={merchantAddressDetails} />
          ) : (
            <></>
          )}

          {phones ? <PhoneNumbers phones={phones} /> : <></>}
          <hr style={classes.divider} />
        </Grid>
        <Grid item xs={12} sx={classes.space}>
          <RowItem
            fontClass="fontNormal"
            label={
              <span>
                Order date: <span style={classes.fontBold}>{orderDate}</span>
              </span>
            }
          />
          <RowItem
            fontClass="fontNormal"
            label={
              <span>
                Order type: <span style={classes.fontBold}>{orderType}</span>
              </span>
            }
          />
          {!prd ? (
            <RowItem
              fontClass="fontNormal"
              label={
                <span>
                  Order#: <span style={classes.fontBold}>{orderNumber}</span>
                </span>
              }
            />
          ) : (
            <></>
          )}
          <RowItem
            fontClass="fontNormal"
            label={
              <span>
                Cashier: <span style={classes.fontBold}>{getName()}</span>
              </span>
            }
          />
          {orderIdentifier ? (
            <RowItem
              fontClass="fontNormal"
              label={
                <span>
                  Table#:{" "}
                  <span style={classes.fontBold}>{orderIdentifier}</span>
                </span>
              }
            />
          ) : (
            <></>
          )}
        </Grid>
        <Grid item xs={12} sx={classes.space}>
          {!prd ? (
            <RowItem
              fontClass="fontNormal"
              labelClass={classes.saleTaxInvoice}
              label={receiptTitle}
            />
          ) : (
            <></>
          )}
          {name ? (
            <RowItem
              fontClass="fontNormal"
              label={
                <span>
                  Customer name: <span style={classes.fontBold}>{name}</span>
                </span>
              }
            />
          ) : (
            <></>
          )}
          {phoneNumber ? (
            <RowItem
              fontClass="fontNormal"
              label={
                <span>
                  Customer phone:{" "}
                  <span style={classes.fontBold}>{phoneNumber}</span>
                </span>
              }
            />
          ) : (
            <></>
          )}
          {alternatePhoneNumber ? (
            <RowItem
              fontClass="fontNormal"
              label={
                <span>
                  Customer Alternative Phone:{" "}
                  <span style={classes.fontBold}>{alternatePhoneNumber}</span>
                </span>
              }
            />
          ) : (
            <></>
          )}
          {address ? (
            <Address text="Delivery address: " address={address} />
          ) : (
            <></>
          )}
          {prd && (
            <>
              <RowItem
                fontClass="fontNormal"
                labelClass={classes.prdTitle}
                label="PROVISIONAL BILL"
              />
              <RowItem
                fontClass="fontNormal"
                labelClass={classes.invoiceTitle}
                label="This is a provisional bill and not a tax invoice. The final invoice will be presented after payment."
              />
            </>
          )}
        </Grid>
        {addressCoordinates ? (
          <Grid item xs={12} sx={classes.space} textAlign="center">
            <RowItem fontClass="fontNormal" label="Scan for pin location" />
            <QRCode
              value={`http://maps.google.com/?q=${addressCoordinates}`}
              size={80}
            />
          </Grid>
        ) : (
          <></>
        )}

        {paymentType && !prd ? (
          <RowItem
            fontClass="fontNormal"
            label={`Payment Type: ${
              orderType === "PickUp" &&
              paymentType === PaymentStatusType.CashOnDelivery
                ? DisplayPaymentTypes.PickUp
                : DisplayPaymentTypes[paymentType as keyof DisplayTypes]
            }`}
          />
        ) : (
          <></>
        )}

        {comment ? (
          <Grid item xs={12} sx={classes.space}>
            <hr style={classes.divider} />
            <RowItem
              fontClass="fontNormal"
              label={`Special Instructions: ${comment}`}
            />
          </Grid>
        ) : (
          <></>
        )}

        <Grid item xs={12} sx={classes.productspace}>
          <hr style={classes.divider} />
          <RowThreeCols
            fontClass="fontHeading"
            label="Items"
            midValue="Price"
            value="Total"
            discountedAmount="Disc"
          />
          <hr style={classes.divider} />
          <CustomerProductList
            products={!isInternetOn ? temp?.orderedProducts : orderedProducts}
            showDiscountedAmount={showDiscountedAmount}
          />
          <CustomerProductList
            products={!isInternetOn ? orderLineItems : orderLines}
            showDiscountedAmount={showDiscountedAmount}
          />
        </Grid>
        <RowThreeItem
          fontClass="fontNormal"
          firstValue={`Number of item(s)= ${getTotalItems()}`}
          midValue={`Total Quantity = ${getProductQuantity()}`}
          lastValue={getDiscountedSubTotal()}
        />
        <Grid item xs={12} sx={classes.space}>
          <RowItem
            labelBold
            valueBold
            fontClass="fontNormal"
            label={
              productContext.isInclusiveTax ? (
                <span>
                  SubTotal{" "}
                  <Typography
                    variant="caption"
                    display="inline"
                    color="GrayText"
                  >
                    (Tax Inclusive)
                  </Typography>
                </span>
              ) : (
                "SubTotal"
              )
            }
            value={generateAmount(
              numberRoundUp(!isInternetOn ? temp?.subTotal : subTotal)
            )}
          />
          {taxToShow ? (
            <RowItem
              labelBold
              valueBold
              fontClass="fontNormal"
              label={`Tax ${tax ? `(GST - ${tax}%)` : ""}`}
              value={taxToShow}
            />
          ) : (
            <></>
          )}
          <RowItem
            labelBold
            valueBold
            fontClass="fontNormal"
            label="Discount"
            value={generateAmount(
              discountLine
                ? `-${Math.round(discountLine?.discountedAmount)}`
                : "0"
            )}
          />
          {orderType === OrderTypes.Delivery ? (
            <RowItem
              labelBold
              valueBold
              fontClass="fontNormal"
              label="Delivery fee"
              value={generateAmount(numberRoundUp(deliveryCharges))}
            />
          ) : (
            <></>
          )}
          {isFBRIntegrated && fbrPosCharges && !prd ? (
            <RowItem
              labelBold
              valueBold
              fontClass="fontNormal"
              label={`${activeRevenueIntegration ? activeRevenueIntegration?.toUpperCase() : "POS"} Fee`}
              value={generateAmount(numberRoundUp(fbrPosCharges))}
            />
          ) : (
            <></>
          )}
          <Grid item xs={12} sx={classes.space}>
            {isRestaurantMode() && (finalTip || prd) ? (
              <RowItem
                labelBold
                valueBold
                fontClass="fontNormal"
                label="Tip"
                value={prd ? "______" : generateAmount(finalTip)}
              />
            ) : (
              <></>
            )}
          </Grid>

          <hr style={classes.divider} />
          <RowItem fontClass="fontHeading" label="Total" value={total} />
        </Grid>
        <Grid item xs={12}>
          <div style={classes.greyDivider}></div>
        </Grid>
        {Boolean(Number(cashReceived)) || Boolean(Number(customerChange)) ? (
          <>
            <RowItem
              labelBold
              valueBold
              fontClass="fontNormal"
              label="Cash Received"
              value={cashReceived}
            />
            <RowItem
              labelBold
              valueBold
              fontClass="fontNormal"
              label="Cash Returned"
              value={customerChange || "0"}
            />
          </>
        ) : (
          <></>
        )}
        <Grid item xs={12}>
          <Typography textAlign="center" variant="subtitle2" fontWeight={900}>
            {printCustomText}
          </Typography>
        </Grid>
        {!prd ? (
          <Grid item xs={12} textAlign="center">
            <Barcode
              value={orderNumber}
              height={30}
              width={1}
              displayValue={false}
            />
          </Grid>
        ) : (
          <></>
        )}

        {isFBRIntegrated && !prd ? (
          <FBRReceiptDetails
            fbrInvoiceNo={fbrInvoiceNo}
            merchantIntegrations={merchantIntegrations}
          />
        ) : (
          <></>
        )}

        <Grid item xs={12}>
          <div style={classes.divDivider}></div>
        </Grid>
        <Grid item xs={12} textAlign="center">
          {partner ? (
            <p style={classes.fontSmall}>
              Powered by{" "}
              <img
                src={
                  partner.website.includes("chikoo")
                    ? chikooBlackLogosvg
                    : partner.logo
                }
                alt="chikoo"
                width="60"
                height={"30"}
              />
            </p>
          ) : (
            <></>
          )}
          {partner && partner.website.includes("chikoo") ? (
            <RowItem fontClass="fontNormal" label={partner.website} />
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default forwardRef(CustomerReceipt)
