import React from "react"
import { Grid, Typography, Theme } from "@mui/material"
import { useTheme } from "@mui/styles"
import { ListElementType } from "./ListElementTypes"

const styles = (theme: Theme) => ({
  key: {
    fontSize: 14,
    color: theme.custom.lightgrey
  },
  caption: {
    fontSize: 16,
    fontWeight: "bold"
  },
  bold: {
    fontWeight: "bold",
    fontSize: 18
  }
})

const ListElement = ({
  label,
  value,
  bold = false,
  id,
  valueColor
}: ListElementType) => {
  const classes = styles(useTheme())

  const classBold = bold ? classes.bold : {}
  return (
    <Grid container justifyContent="space-between">
      <Grid item>
        <Typography sx={{ ...classes.key, ...classBold }} variant="caption">
          {label}
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          data-Id={id}
          sx={{
            ...classes.caption,
            ...classBold,
            color: valueColor ? valueColor : "black"
          }}
          variant="caption"
        >
          {value}
        </Typography>
      </Grid>
    </Grid>
  )
}
export default ListElement
