import React, { useState, useEffect, useContext } from "react"
import { BranchProvider } from "./BranchContext"
import UserContext from "main/context/user/UserContext"
import { gql, useLazyQuery } from "graphql/apollo"
import {
  keys,
  addToStorage,
  getFromStorage
} from "main/util/LocalStorageHelper"
import { userTypesList } from "main/util/UserHelper"
import ConfigContext from "main/context/config/ConfigContext"
import { ConfigContextTypes } from "main/context/config/ConfigContextTypes"
import { UserContextType } from "main/context/user/UserContextTypes"
import gqlClient from "graphql/pulse"
import pulseClient from "graphql/pulse"

const master = {
  bvid: "all",
  storeName: "All",
  country: {
    currency: "Rs."
  }
}

function BranchContainer({ children }: any) {
  const { setStoreMode, setCurrency } = useContext(
    ConfigContext
  ) as ConfigContextTypes
  const { isUserRole } = useContext(UserContext) as UserContextType
  const isMasterAdmin = isUserRole(userTypesList.MasterAdmin)
  const isMasterPos = isUserRole(userTypesList.MasterPOS)

  const [fetchBranches, { data, loading, refetch }] = useLazyQuery(
    GET_BRANCHES,
    {
      fetchPolicy: "network-only",
      client: gqlClient
    }
  )

  const [fetchMerchantByArea] = useLazyQuery(GET_MERCHANT_BY_AREA, {
    fetchPolicy: "network-only",
    client: pulseClient,
    onCompleted: updateBranchByArea
  })

  const { getUserMerchantBvid } = useContext(UserContext) as UserContextType
  const merchantBvid = getUserMerchantBvid()
  const branchFromLocalStorage = getFromStorage(keys.BRANCH)
  const branch = branchFromLocalStorage?.bvid || master.bvid
  const [selectedBranch, setSelectedBranch] = useState(branch)
  const branchesList = data?.getAllBranches?.length ? data?.getAllBranches : []

  useEffect(() => {
    const checkUser = getFromStorage("user")
    if (checkUser) {
      const { role } = checkUser?.user ?? {}
      const { MasterAdmin, MasterPOS } = userTypesList
      if (role === MasterAdmin || role === MasterPOS) {
        fetchBranches()
      }
    }
  }, [isMasterAdmin, isMasterPos])

  useEffect(() => {
    addStoreModeInConfig()
  }, [selectedBranch, branchesList])

  function handleSelectedBranchChange(value: any) {
    addToStorage(keys.BRANCH, { bvid: value?.bvid })
    setCurrency(value?.country?.currency)
    setSelectedBranch(value?.bvid)
  }

  function isBranchSelected() {
    return selectedBranch !== "all"
  }

  function getStoreBvid() {
    return isBranchSelected() ? selectedBranch : merchantBvid
  }

  function getBranchBvid() {
    const checkUser = getFromStorage("user")
    if (checkUser) {
      const { role } = checkUser?.user ?? {}
      const { MasterAdmin, MasterPOS } = userTypesList
      return (role === MasterAdmin || role === MasterPOS) && isBranchSelected()
        ? selectedBranch
        : null
    }
  }

  function getBranchDetails(branchBvid = selectedBranch) {
    return branchesList?.find((branch: any) => branch.bvid === branchBvid)
  }

  function addStoreModeInConfig() {
    if (selectedBranch) {
      const branch = getBranchDetails(selectedBranch)
      const { storeMode } = branch?.storeType ?? {}
      storeMode && setStoreMode(storeMode)
    }
  }

  function updateBranchByArea(data: any) {
    if (data?.getMerchantByArea)
      setSelectedBranch(data?.getMerchantByArea?.bvid)
  }

  return (
    <BranchProvider
      value={{
        fetchBranches,
        loading,
        refetch,
        branchesList,
        data: [master, ...branchesList],
        selectedBranch,
        setSelectedBranch,
        handleSelectedBranchChange,
        isBranchSelected,
        getStoreBvid,
        getBranchBvid,
        getBranchDetails,
        fetchMerchantByArea,
        isMasterAdmin,
        isMasterPos
      }}
    >
      {children}
    </BranchProvider>
  )
}

export default BranchContainer

const GET_BRANCHES = gql`
  query getAllBranches {
    getAllBranches {
      bvid
      storeName
      status
      storeType {
        bvid
        title
        storeMode
      }
      country {
        bvid
        name
        currency
      }
    }
  }
`

const GET_MERCHANT_BY_AREA = gql`
  query getMerchantByArea($areaBvid: ID, $cityBvid: ID) {
    getMerchantByArea(areaBvid: $areaBvid, cityBvid: $cityBvid) {
      bvid
      storeName
    }
  }
`
