import React from "react"
import { useTheme } from "@mui/styles"
import { Theme } from "@mui/material"
import { Grid } from "@mui/material"

const styles = (theme: Theme) => ({
  titleTypo: {
    fontWeight: "bold",
    fontSize: 20,
    marginLeft: 1,
    color: theme.palette.secondary.main
  }
})

type TitleIconTypes = {
  icon: React.ReactElement
  title: string
}

const TitleIcon = ({ icon, title }: TitleIconTypes) => {
  const classes = styles(useTheme())
  return (
    <Grid container alignItems="center">
      <Grid item>{icon}</Grid>
      <Grid item sx={classes.titleTypo}>
        {title}
      </Grid>
    </Grid>
  )
}

export default TitleIcon
