import React, { useContext, useEffect, useState } from "react"
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from "@mui/material"
import { PartnershipType } from "offline_database/DbTypes"
import PosContext from "main/context/pos/PosContext"
import { PosContextType } from "main/context/pos/PosContextTypes"
import { DisplayPaymentTypes, DisplayTypes } from "main/pos/receipt/helpers"
import { fetchDataWithSearch } from "offline_database/queries/DbOperations"
import ConnectionHooks from "main/context/connection/ConnectionHook"

const styles = () => ({
  selectContainer: {
    padding: "4px 0px 8px 0px"
  }
})
const PartnershipDropdown = () => {
  const classes = styles()
  const { isInternetOn, firstSync } = ConnectionHooks()
  const [partnershipList, setPartnershipList] = useState<PartnershipType[]>([])
  const { partnershipValue, setPartnershipValue, paymentMethod } = useContext(
    PosContext
  ) as PosContextType

  const fetchOfflinePartnership = async () => {
    const partnerList = await fetchDataWithSearch("partnershipList")
    filterData(partnerList)
  }

  const filterData = (list: []) => {
    let getPaymentType = ""
    for (const key in DisplayPaymentTypes) {
      if (key === paymentMethod) {
        getPaymentType = DisplayPaymentTypes[key as keyof DisplayTypes]
        break
      }
    }
    const checkPaymentType =
      getPaymentType === DisplayPaymentTypes.Cash
        ? DisplayPaymentTypes.CashOnDelivery
        : getPaymentType
    const filteredPartnershipData = list?.filter((item: PartnershipType) => {
      return item.paymentTypes.includes(checkPaymentType)
    })

    setPartnershipList([
      {
        bvid: "",
        partnershipName: "Select Option",
        paymentTypes: []
      },
      ...filteredPartnershipData
    ])
  }
  const handlePartnership = (e: SelectChangeEvent<string>) => {
    setPartnershipValue(e.target.value)
  }
  useEffect(() => {
    fetchOfflinePartnership()
  }, [firstSync, isInternetOn, paymentMethod])

  return (
    <>
      {partnershipList.length > 1 ? (
        <FormControl fullWidth>
          <InputLabel>Select Partnership</InputLabel>
          <Select
            label={"Select Partnership"}
            variant="outlined"
            size="small"
            value={partnershipValue}
            sx={classes.selectContainer}
            onChange={handlePartnership}
          >
            {partnershipList?.map((option: PartnershipType, index: number) => (
              <MenuItem key={index} value={option?.bvid}>
                {option?.partnershipName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <></>
      )}
    </>
  )
}

export default PartnershipDropdown
