import React, { useState, useEffect, useContext, useRef } from "react"
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Grid,
  Button,
  IconButton,
  Box
} from "@mui/material"
import {
  deleteSingleData,
  fetchDataWithSearch
} from "offline_database/queries/DbOperations"
import { formatDateTime } from "main/util/DateTimeHelper"
import CustomCard from "main/common/CustomCard"
import Search from "main/common/Search"
import EmptySearch from "main/common/Empty"
import DatabaseTables from "../../constant/DatabaseTables"
import {
  useCustomerContext,
  usePosContext,
  useProductContext
} from "main/hooks/ContextHooks"
import ConfirmDialog from "main/common/ConfirmDialog"
import DeleteIcon from "@mui/icons-material/Delete"
import ProvisionalReceipt from "main/pos/checkout/ProvisionalReceipt"
import { OrderTypes } from "main/pos/constant/PosConstant"
import ConfigContext from "main/context/config/ConfigContext"
import { ConfigContextTypes } from "main/context/config/ConfigContextTypes"
import UserContext from "main/context/user/UserContext"
import { UserContextType } from "main/context/user/UserContextTypes"
import { userTypesList } from "main/util/UserHelper"
import printIcon from "assets/images/icons/print-fill.svg"
import DraftKOT from "main/pos/receipt/DraftKOT"
import { useReactToPrint } from "react-to-print"

const styles = () => ({
  posGoBtn: {
    margin: "20px 20px 0px 80px"
  },
  customCard: {
    marginTop: 0
  },
  receiptWrapper: {
    display: "none"
  }
})

const KOTReciept = ({ order }: { [key: string]: any }) => {
  const classes = styles()

  const kitchenReceiptRef = useRef<HTMLDivElement>(null)

  const handleKitchenReceipt = useReactToPrint({
    content: () => kitchenReceiptRef.current
  })

  return (
    <>
      <Button size="small" variant="outlined" onClick={handleKitchenReceipt}>
        <Box component={"img"} width={20} src={printIcon} mr={1} />
        KOT
      </Button>
      <Grid container sx={classes.receiptWrapper}>
        <DraftKOT ref={kitchenReceiptRef} data={order?.data} />
      </Grid>
    </>
  )
}

const DraftedOrders = () => {
  const classes = styles()
  const [draftOrderList, setDraftOrderList] = useState([])
  const [searchOrder, setSearchOrder] = useState<string>("")
  const [showConfirmDeleteDraftDialog, setShowConfirmDeleteDraftDialog] =
    useState(false)
  const [selectedOrderNumber, setSelectedOrderNumber] = useState("")
  const { isRestaurantMode } = useContext(ConfigContext) as ConfigContextTypes
  const { isUserRole } = useContext(UserContext) as UserContextType
  const isMasterAdmin = isUserRole(userTypesList.MasterAdmin)
  const { resetPosProductValues, applyPosProductValues } = useProductContext()
  const { resetPosCustomerValues, applyPosCustomerValues } =
    useCustomerContext()
  const {
    resetPosValues,
    applyPosValues,
    setShowDraftedOrders,
    setIsHoldOrder
  } = usePosContext()
  const deleteOrderAfterSync = async (orderNumber: string) => {
    await deleteSingleData(
      DatabaseTables.draftOrderTable,
      "orderNumber",
      orderNumber
    )
    await getDraftedOrders()
  }

  const getDraftedOrders = async () => {
    let draftOrdersList = await fetchDataWithSearch(
      DatabaseTables.draftOrderTable,
      "orderNumber",
      searchOrder
    )

    let sortedDraftOrderlist = []
    if (draftOrdersList.length) {
      draftOrdersList = draftOrdersList?.sort((optionA: any, optionB: any) => {
        if (optionA?.orderNumber > optionB?.orderNumber) return 1
        if (optionA?.orderNumber < optionB?.orderNumber) return -1
        return 0
      })

      sortedDraftOrderlist = draftOrdersList
    }

    setDraftOrderList(sortedDraftOrderlist)
  }

  useEffect(() => {
    getDraftedOrders()
  }, [searchOrder])

  const applyOrder = (orderData: any) => {
    resetPosProductValues()
    resetPosCustomerValues()
    resetPosValues()

    const {
      discount,
      discountType,
      flatDiscountPercentage,
      flatDiscount,
      specialDiscount,
      voucherDiscount,
      voucherCode
    } = orderData.discountLine

    const posValues = {
      discount,
      discountType,
      flatDiscountPercentage,
      flatDiscount,
      specialDiscount,
      voucherDiscount,
      voucherCode,

      deliveryLaterTiming: orderData.deliveryLaterTiming,
      isDeliveryLater: orderData.isDeliveryLater,
      deliveryChoice: orderData.deliveryChoice,
      orderType: orderData.orderType,
      orderNumber: orderData.orderNumber
    }

    applyPosValues(posValues)
    if (!isMasterAdmin) applyPosCustomerValues(orderData.customer)
    applyPosProductValues(orderData)
  }
  const modifyDraftOrder = (order: any) => {
    applyOrder(order?.data)
    setShowDraftedOrders(false)
    setIsHoldOrder(false)
  }
  function handleRemoveOrder(orderNumber: string) {
    setSelectedOrderNumber(orderNumber)
    setShowConfirmDeleteDraftDialog(true)
  }
  const removeDraftOrder = () => {
    deleteOrderAfterSync(selectedOrderNumber)
    setShowConfirmDeleteDraftDialog(false)
  }

  return (
    <>
      <CustomCard customCardClass={classes.customCard}>
        <Grid container justifyContent={"right"}>
          <Grid item xs={3}>
            <Search
              focus
              label="Search"
              searchSize="small"
              placeholder="Eg: Identifier# ABD-124"
              searchVariant="standard"
              searchValue={searchOrder}
              setSearchValue={setSearchOrder}
            />
          </Grid>
          <Grid item xs={12}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Identifier</TableCell>
                  <TableCell>Order Type</TableCell>
                  <TableCell>Source</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Contact</TableCell>
                  <TableCell>ALternative Contact</TableCell>
                  <TableCell>Products</TableCell>
                  <TableCell>Date Time</TableCell>
                </TableRow>
              </TableHead>
              {draftOrderList?.length ? (
                <TableBody>
                  {draftOrderList?.map((order: any, index) => {
                    const {
                      orderNumber: productOrderNumber,
                      orderTime,
                      products,
                      customer,
                      orderType
                    } = order?.data ?? {}
                    const {
                      customerName,
                      customerPhone,
                      alternatePhoneNumber,
                      orderSource
                    } = customer ?? {}

                    return (
                      <>
                        <TableRow key={index}>
                          <TableCell>{productOrderNumber || "-"}</TableCell>
                          <TableCell>{orderType || "-"}</TableCell>
                          <TableCell>
                            {(orderType !== "WalkIn" && orderSource) || "-"}
                          </TableCell>
                          <TableCell>{customerName || "-"}</TableCell>
                          <TableCell>{customerPhone || "-"}</TableCell>
                          <TableCell>{alternatePhoneNumber || "-"}</TableCell>
                          <TableCell>{products?.length || 0}</TableCell>
                          <TableCell>
                            {formatDateTime(orderTime) || "-"}
                          </TableCell>

                          <TableCell>
                            <Grid container gap={1}>
                              <Grid item>
                                <Button
                                  size="small"
                                  color="primary"
                                  variant="contained"
                                  onClick={() => modifyDraftOrder(order)}
                                >
                                  Modify
                                </Button>
                              </Grid>
                              {isRestaurantMode() &&
                              orderType === OrderTypes.WalkIn ? (
                                <Grid item>
                                  <ProvisionalReceipt holdOrder={order} />
                                </Grid>
                              ) : (
                                <></>
                              )}
                              {isRestaurantMode() ? (
                                <Grid item>
                                  <KOTReciept order={order} />
                                </Grid>
                              ) : (
                                <></>
                              )}
                            </Grid>
                          </TableCell>
                          <TableCell>
                            <IconButton
                              color="error"
                              onClick={() =>
                                handleRemoveOrder(order?.data?.orderNumber)
                              }
                              id="modal-remove-btn"
                            >
                              <DeleteIcon fontSize="large" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      </>
                    )
                  })}
                </TableBody>
              ) : (
                <></>
              )}
            </Table>
            {!draftOrderList.length ? <EmptySearch /> : <></>}
          </Grid>
        </Grid>

        <ConfirmDialog
          title="Delete Open Order"
          message={`Are you sure you want to delete this open order [ ${selectedOrderNumber} ]?`}
          onConfirm={removeDraftOrder}
          openDialog={showConfirmDeleteDraftDialog}
          closeDialog={() => {
            setShowConfirmDeleteDraftDialog(false)
          }}
        />
      </CustomCard>
    </>
  )
}

export default DraftedOrders
