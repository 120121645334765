import React, { useContext } from "react"
import { Button, Grid } from "@mui/material"
import PosCustomerContext from "main/context/pos/PosCustomer/PosCustomerContext"
import { PosCustomerType } from "main/context/pos/PosCustomer/PosCustomerTypes"
import { useCustomerValidations } from "main/pos/hooks/PosHooks"

const styles = () => ({
  container: {
    marginTop: "12px"
  }
})

const SummaryButtons = () => {
  const classes = styles()
  const { showSummary, setShowSummary } = useContext(
    PosCustomerContext
  ) as PosCustomerType
  const checkValidations = useCustomerValidations()

  const handleSave = () => {
    if (checkValidations()) {
      setShowSummary(true)
    }
  }

  return (
    <Grid container sx={classes.container}>
      {showSummary ? (
        <Grid item xs={12} textAlign="end">
          <Button
            onClick={() => setShowSummary(false)}
            color="primary"
            variant="outlined"
            size="small"
          >
            Edit
          </Button>
        </Grid>
      ) : (
        <Grid item xs={12} textAlign="end">
          <Button
            onClick={handleSave}
            color="primary"
            variant="outlined"
            size="small"
          >
            Save
          </Button>
        </Grid>
      )}
    </Grid>
  )
}

export default SummaryButtons
