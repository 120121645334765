import { gql } from "graphql/apollo"

export const CREATE_ORDER = gql`
  mutation createPosOrder(
    $products: [OrderProductParams]!
    $deliveryAddress: InputAddress
    $customerDetails: CustomerCheckoutDetails
    $addressUid: ID
    $comment: String
    $orderSource: OrderSource
    $paymentType: PaymentType
    $deliveryCharges: Float!
    $dispatchDetails: DispatchDetailsInput
    $orderLineItems: [OrderLineParams]
    $discountLine: DiscountLineInput
    $orderNumber: String
    $orderTime: String
    $merchantBvid: ID
    $fbrInvoiceNo: String
    $fbrPosCharges: Int
    $isLaterDelivery: Boolean
    $tip: Float
    $binBvid: String
    $shiftBvid: String
    $partnershipBvid: String
  ) {
    createPosOrder(
      products: $products
      deliveryAddress: $deliveryAddress
      customerDetails: $customerDetails
      addressUid: $addressUid
      comment: $comment
      orderSource: $orderSource
      paymentType: $paymentType
      deliveryCharges: $deliveryCharges
      dispatchDetails: $dispatchDetails
      orderLineItems: $orderLineItems
      discountLine: $discountLine
      orderNumber: $orderNumber
      orderTime: $orderTime
      merchantBvid: $merchantBvid
      fbrInvoiceNo: $fbrInvoiceNo
      fbrPosCharges: $fbrPosCharges
      isLaterDelivery: $isLaterDelivery
      tip: $tip
      binBvid: $binBvid
      shiftBvid: $shiftBvid
      partnershipBvid: $partnershipBvid
    )
  }
`
export const UPDATE_ORDER = gql`
  mutation updateOrderFbrInvoiceNo(
    $bvid: ID!
    $fbrInvoiceNo: String!
    $fbrPosCharges: Float!
  ) {
    updateOrderFbrInvoiceNo(
      bvid: $bvid
      fbrInvoiceNo: $fbrInvoiceNo
      fbrPosCharges: $fbrPosCharges
    )
  }
`
export const ORDER_NUMBER = gql`
  query order($bvid: ID!) {
    order(bvid: $bvid) {
      orderNumber
      orderedProducts {
        name
        price
        discountedAmount
        productBatchBvid
        xid
        selectedMainOptionXids
        selectedSubOptionXids
        selectedOptions
      }
      discountLine {
        discountType
        voucherXid
        voucherCode
        discountedAmount
        discountPercent
      }
    }
  }
`

export const GET_ORDER = gql`
  query order($bvid: ID!) {
    order(bvid: $bvid) {
      createdAt
      orderNumber
      comment
      tip
      orderedProducts {
        name
        price
        quantity
        barcode
        selectedOptions
        comment
        discountedAmount
        productLots {
          bvid
          customField1
          customField2
          customField3
          expiry
          gstValue
          lotCode
          merchantBvid
          orderBvid
          price
          productXid
          quantityInside
          xid
        }
      }
      orderLines {
        name
        price
        quantity
      }
      deliveryDetails {
        name
        phoneNumber
        alternatePhoneNumber
        address {
          details
          area {
            name
            city {
              name
            }
          }
          location {
            coordinates
          }
        }
      }
      dispatchDetails {
        dispatchType
      }
      merchant {
        storeName
      }
      subTotal
      deliveryCharges
      totalAmount
      taxAmount
      discountLine {
        discountedAmount
      }
      fbrInvoiceNo
      fbrPosCharges
      paymentType
      orderType
    }
  }
`
