import React, { useState, useEffect, useContext } from "react"
import { useNavigate } from "react-router-dom"
import ErrorContext from "main/context/error/ErrorContext"
import { eAccumulator } from "main/common/errorAccumulator"
import CommonSnackbar from "main/common/common_snackbar/CommonSnackbar"
import { ErrorType } from "main/common/GlobalErrorTypes"

function GlobalError() {
  const networkErrorMessage = "Seems like you are disconnected"
  const [errorState, setErrorState] = useState({})
  const [snackBarState, setSnackBarState] = useState(true)
  const [message, setMessage] = useState("")
  const navigate = useNavigate()
  const errorContext: any = useContext(ErrorContext)

  const handler = (error: string | object) => {
    setErrorState(error)
  }

  useEffect(() => {
    eAccumulator.setCallback(handler)

    return () => {
      eAccumulator.clearCallback()
    }
  }, [])

  useEffect(() => {
    if (errorState) {
      getErrorMessage(errorState)
    }
  }, [errorContext, navigate, errorState])

  function getErrorMessage(error: ErrorType) {
    if (error.networkError) {
      if (error.networkError.result) {
        const code = error.networkError.result.errors[0].extensions.code
        if (code === "UNAUTHENTICATED") {
          errorContext.toggleErrorAuth()
          closeSnackbar()
          navigate("/reconnect")
          return
        }
      }
      setMessage(networkErrorMessage)
    } else setMessage(error.message as string)
    setSnackBarState(true)
  }

  function closeSnackbar() {
    setErrorState({})
    setSnackBarState(false)
    setMessage("")
  }

  return message ? (
    <CommonSnackbar
      message={message}
      variant="error"
      autoHideDuration={4000}
      open={snackBarState}
      onClose={closeSnackbar}
    />
  ) : (
    <></>
  )
}
export default GlobalError
