import React, { useEffect } from "react"
import { Button } from "@mui/material"
import { TypeTabTypes } from "main/pos/order/order_type/TypeTabTypes"
import { useCustomerContext, usePosContext } from "main/hooks/ContextHooks"

const styles = () => ({
  buttons: {
    width: "100%",
    padding: 2,
    borderRadius: 4,
    fontSize: 14
  },
  icon: {
    width: 24
  }
})

const TypeTab = ({ name, title, icon }: TypeTabTypes) => {
  const classes = styles()
  const { orderType, setOrderType } = usePosContext()
  const { setShowSummary, setCustomerAccordion } = useCustomerContext()
  const isSelected = orderType === name

  const handleSelection = () => {
    setOrderType(name)
    setShowSummary(false)
    setCustomerAccordion(true)
  }

  useEffect(() => {
    if (orderType === name) handleSelection()
  }, [orderType])

  return (
    <Button
      color={isSelected ? "primary" : "secondary"}
      variant={isSelected ? "contained" : "outlined"}
      sx={{ ...classes.buttons }}
      startIcon={icon}
      onClick={handleSelection}
      id={title}
    >
      {title}
    </Button>
  )
}

export default TypeTab
