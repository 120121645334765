import React from "react"
import { Grid } from "@mui/material"
import Sale from "main/pos/checkout/Sale"
import CheckoutCounter from "main/pos/checkout/CheckoutCounter"

const styles = () => ({
  checkoutCounterContainer: {
    position: "relative"
  }
})
const Checkout = ({
  setCheckoutDialog
}: {
  setCheckoutDialog: React.Dispatch<React.SetStateAction<any>>
}) => {
  const classes = styles()
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <Sale />
      </Grid>
      <Grid item xs={12} md={6} sx={classes.checkoutCounterContainer}>
        <CheckoutCounter setCheckoutDialog={setCheckoutDialog} />
      </Grid>
    </Grid>
  )
}

export default Checkout
