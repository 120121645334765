import React, { useState, useContext, useEffect } from "react"
import { Button, Grid, TextField, Typography } from "@mui/material"
import PosProductContext from "main/context/pos/PosProduct/PosProductContext"
import { PosProductType } from "main/context/pos/PosProduct/PosProductTypes"
import { discountTypeList, DiscountTypes } from "main/pos/constant/PosConstant"
import OptionButton from "main/common/option_button/OptionButton"
import Counters from "main/pos/common/counters/Counter"
import { allowOnlyNumbers } from "main/util/NumberHelper"
import PosContext from "main/context/pos/PosContext"
import { PosContextType } from "main/context/pos/PosContextTypes"

const styles = () => ({
  field: {
    marginBottom: 2
  },
  fieldPrefix: {
    paddingRight: 1
  },
  discountTypeButton: {
    width: 112,
    height: 32,
    fontSize: 12,
    margin: "0px 8px 12px 0px"
  }
})

const Discount = ({
  handleDiscount,
  setDiscountDialog
}: {
  handleDiscount: React.Dispatch<React.SetStateAction<any>>
  setDiscountDialog: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const classes = styles()
  const { discountType, setDiscountType, voucherDiscount } = useContext(
    PosContext
  ) as PosContextType
  const productContext = useContext(PosProductContext) as PosProductType

  const [discount, setDiscount] = useState<string | number>("")
  const [discountError, setDiscountError] = useState("")
  const [selectedDiscount, setSelectedDiscount] = useState(discountTypeList[0])

  useEffect(() => {
    setDiscountType(DiscountTypes.Flat)
  }, [])

  const addDiscount = () => {
    const calculatedDiscount = handleDiscountWithPercent(discount)

    if (!discountValidation(calculatedDiscount)) {
      handleDiscount(Math.round(calculatedDiscount))
      setDiscountDialog(false)
    }
  }

  const subTotalValidation = (calculatedDiscount: any) => {
    if (voucherDiscount) {
      return (
        parseInt(calculatedDiscount) >
        productContext.getProductsSubTotal() - voucherDiscount
      )
    } else {
      return parseInt(calculatedDiscount) > productContext.getProductsSubTotal()
    }
  }
  const discountValidation = (calculatedDiscount: any) => {
    const checkDiscountEmpty =
      (calculatedDiscount === "0" || !calculatedDiscount) &&
      DiscountTypes.Flat === discountType

    const checkDiscountAmount = subTotalValidation(calculatedDiscount)
    const numericDiscount =
      typeof discount === "string" ? parseFloat(discount) : discount
    const checkDiscountPercentage =
      typeof numericDiscount === "number" &&
      numericDiscount > 0 &&
      numericDiscount <= 100

    if (checkDiscountEmpty) {
      setDiscountError("Discount cannot be empty or 0")
    } else if (
      DiscountTypes.Percent === discountType &&
      !checkDiscountPercentage
    ) {
      setDiscountError("Select discount percentage between 0 and 100")
    } else if (checkDiscountAmount) {
      setDiscountError("Discount cannot be greater than subtotal")
    }

    return (
      (DiscountTypes.Percent === discountType && !checkDiscountPercentage) ||
      checkDiscountAmount ||
      checkDiscountEmpty
    )
  }

  const handleDiscountWithPercent = (value: any) => {
    if (DiscountTypes.Percent === discountType) {
      const discountByPercent =
        (productContext.getProductsSubTotal() * value) / 100
      return discountByPercent
    }
    return value
  }

  const changeDiscountType = (value: any) => {
    setDiscountType(value.value)
    setSelectedDiscount(value.title)
  }

  const handleDiscountField = (value: string) => {
    const number = allowOnlyNumbers(value)
    if (!value) {
      setDiscountError("")
      setDiscount("")
      return
    }
    if (number) {
      setDiscount(+number)
      setDiscountError("")
    }
  }
  const handleCounter = (value: any) => {
    setDiscount(value)
    setDiscountError("")
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <OptionButton
          singleDefaultOption={selectedDiscount}
          subValues={discountTypeList}
          handleSelection={(value: any) => changeDiscountType(value)}
          optionButtonClass={classes.discountTypeButton}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          autoFocus
          fullWidth
          sx={classes.field}
          variant="outlined"
          name="discount"
          label={
            DiscountTypes.Flat === discountType
              ? "Discount Amount"
              : "Discount Percentage"
          }
          id="discount-txt"
          placeholder={DiscountTypes.Flat === discountType ? "500" : "10"}
          value={discount}
          onChange={(e) => handleDiscountField(e.target.value)}
          error={!!discountError}
          helperText={discountError}
          InputProps={{
            startAdornment: (
              <Typography sx={classes.fieldPrefix}>
                {DiscountTypes.Flat === discountType ? "PKR" : "%"}
              </Typography>
            )
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Grid textAlign="center">
          <Counters
            fullWidth
            hideBigNumbers
            state={discount}
            handleCounter={handleCounter}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Button
          fullWidth
          size="large"
          color="primary"
          variant="contained"
          onClick={addDiscount}
        >
          Done
        </Button>
      </Grid>
    </Grid>
  )
}

export default Discount
