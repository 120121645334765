const CustomOrange = Object.freeze({
  tint: "#FFF7ED",
  100: "#FFE8D0",
  200: "#FFCA91",
  300: "#FFC27F",
  400: "#FEB96C",
  500: "#FEB05A",
  600: "#FF8A58"
})
export default CustomOrange
