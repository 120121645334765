import React from "react"
import { Button, Theme } from "@mui/material"
import { useTheme } from "@mui/styles"

const styles = (theme: Theme) => ({
  countButton: {
    width: 60,
    height: 60,
    fontSize: 20,
    fontWeight: 600,
    margin: "4px 0",
    borderRadius: 3,
    color: theme.palette.secondary.main,
    opacity: 0.5
  }
})

const CounterButton = ({
  text,
  handleClick
}: {
  text: any
  handleClick: (_number: string) => void
}) => {
  const classes = styles(useTheme())
  return (
    <Button
      fullWidth
      sx={classes.countButton}
      variant="outlined"
      color="secondary"
      size="large"
      onClick={() => handleClick(text)}
    >
      {text}
    </Button>
  )
}

export default CounterButton
