import React, { useEffect, useState } from "react"
import { Grid } from "@mui/material"
import SingleButton from "main/common/option_button/SingleButton"
import { SingleButtonTypes } from "main/common/option_button/SingleButtonTypes"
import {
  OptionButtonTypes,
  OptionValueType,
  SelectedOptionsType,
  SingleDefaultOptionType,
  ItemTypes
} from "main/common/option_button/OptionButtonType"

const OptionButton = ({
  singleDefaultOption,
  allActive,
  optionButtonClass,
  mainValues,
  subValues,
  handleSelection,
  clearSelection,
  priceKey = "optionPrice",
  discountedPriceKey = "discountedPrice",
  selectedOptions,
  setMessage,
  setSnackBarState,
  buttonSpacing
}: OptionButtonTypes) => {
  const [singleOption, setSingleOption] = useState<SingleDefaultOptionType>({})
  const [multipleOptions, setMultipleOptions] = useState<SelectedOptionsType[]>(
    []
  )

  useEffect(() => {
    if (singleDefaultOption) {
      setSingleOption(singleDefaultOption)
    }
    // eslint-disable-next-line
  }, [singleDefaultOption])

  useEffect(() => {
    if (clearSelection) {
      setSingleOption({})
      setMultipleOptions([])
    }
    // eslint-disable-next-line
  }, [clearSelection])

  useEffect(() => {
    const multiOptionValues: SelectedOptionsType[] = []

    selectedOptions?.forEach((item: SelectedOptionsType) => {
      if (item?.parent?.isMultiSelect) {
        if (mainValues?.title === item?.parent?.title)
          multiOptionValues.push(item)
      } else {
        if (mainValues?.title === item?.parent?.title) {
          setSingleOption(item)
          handleSelection(item, true)
        }
      }
    })
    setMultipleOptions(multiOptionValues)
    // eslint-disable-next-line
  }, [selectedOptions])

  const handleSingleOption = (value: SingleDefaultOptionType) => {
    setSingleOption(value)
  }

  const validateQuantity = (
    optionIndex: number,
    value: SelectedOptionsType
  ) => {
    const quantity: number = value?.qty ?? 1
    const totalSum =
      optionIndex >= 0 ? getOptionQtySum() : getOptionQtySum() + quantity

    return mainValues?.quantity && totalSum > mainValues.quantity
  }

  const handleMultipleOptions = (value: SelectedOptionsType) => {
    const optionIndex = multipleOptions.findIndex(
      (item: SelectedOptionsType) => item.bvid === value.bvid
    )
    if (mainValues?.quantity && validateQuantity(optionIndex, value)) {
      if (optionIndex >= 0) {
        handleSelection(value)
      } else {
        setMessage?.(
          `You can select only ${mainValues.quantity} option value for ${mainValues.title}`
        )
        setSnackBarState?.(true)
      }
      return
    }
    if (optionIndex < 0) {
      setMultipleOptions([...multipleOptions, value])
    } else {
      multipleOptions?.splice(optionIndex, 1)
      setMultipleOptions(multipleOptions)
    }
    handleSelection(value)
  }

  const isButtonActive = (
    isMultiSelect?: boolean,
    option?: OptionValueType
  ) => {
    if (isMultiSelect) {
      const isActive = !!multipleOptions.find(
        (multiOption: ItemTypes) => multiOption.bvid === option?.bvid
      )
      return isActive
    } else return singleOption?.title === option?.title
  }

  const handleOptionButton = (
    optionValue: SelectedOptionsType | OptionValueType
  ) => {
    if (mainValues?.isMultiSelect) {
      handleMultipleOptions(optionValue as SelectedOptionsType)
    } else {
      handleSingleOption(optionValue)
      handleSelection(optionValue)
    }
  }

  function getOptionQtySum() {
    return multipleOptions.reduce(
      (a: number, b: SelectedOptionsType) => a + (b?.qty ?? 1),
      0
    )
  }

  return (
    <Grid container spacing={buttonSpacing}>
      {subValues?.map((optionValue: OptionValueType) => {
        if (allActive || optionValue.isActive) {
          if (mainValues) {
            optionValue.parent = mainValues as keyof (
              | SingleButtonTypes
              | undefined
            )
          }
          return (
            <SingleButton
              key={optionValue.bvid}
              setMessage={setMessage}
              setSnackBarState={setSnackBarState}
              optionValue={optionValue}
              mainValues={mainValues}
              handleOptionButton={handleOptionButton}
              isButtonActive={isButtonActive}
              optionButtonClass={optionButtonClass}
              getOptionQtySum={getOptionQtySum}
              priceKey={priceKey}
              discountedPriceKey={discountedPriceKey}
              multipleOptions={multipleOptions}
              disabled={Boolean(optionValue.disabled)}
            />
          )
        }
        return <></>
      })}
    </Grid>
  )
}

export default React.memo(OptionButton)
