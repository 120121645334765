import React, { useContext, useEffect } from "react"
import { Grid, TextField } from "@mui/material"
import PosCustomerContext from "main/context/pos/PosCustomer/PosCustomerContext"
import { PosCustomerType } from "main/context/pos/PosCustomer/PosCustomerTypes"
import PosProductContext from "main/context/pos/PosProduct/PosProductContext"
import { PosProductType } from "main/context/pos/PosProduct/PosProductTypes"
import { allowOnlyNumbers, convert03to92 } from "main/util/NumberHelper"
import { fetchDataWithSearch } from "offline_database/queries/DbOperations"
import { gql, useLazyQuery } from "@apollo/client"
import pulse from "graphql/pulse"
import ConnectionHooks from "main/context/connection/ConnectionHook"

const styles = () => ({
  space: {
    marginBottom: 2
  }
})

const CustomerFields = ({
  disabled,
  hideAltNumber
}: {
  disabled?: boolean
  hideAltNumber?: boolean
}) => {
  const customerContext = useContext(PosCustomerContext) as PosCustomerType
  const posProductContext = useContext(PosProductContext) as PosProductType

  const { isInternetOn } = ConnectionHooks()
  const classes = styles()

  const showCustomerDetails = () => {
    customerContext.setShowNewAddress(true)
    customerContext.setShowCustomerName(true)
  }

  const [customerDetail] = useLazyQuery(MERCHANT_CUSTOMER, {
    fetchPolicy: "network-only",
    client: pulse,
    onError: showCustomerDetails
  })

  const fetchOfflineProducts = async (
    tableName: string,
    query?: string,
    search?: any
  ) => {
    const merchantCustomersList = await fetchDataWithSearch(
      tableName,
      query,
      search
    )

    if (!merchantCustomersList[0] && isInternetOn) {
      const customerData = await customerDetail({
        variables: {
          username: convert03to92(customerContext.customerPhone)
        }
      })

      if (customerData.data) {
        const { merchantCustomer } = customerData.data ?? {}
        const { user } = merchantCustomer ?? {}
        const customer = {
          ...merchantCustomer,
          ...user
        }
        performAfterGetDetails({ data: customer })
      }
      return
    }
    performAfterGetDetails(merchantCustomersList[0])
  }

  const handleCustomerPhone = (e: any) => {
    const value = allowOnlyNumbers(e.target.value)
    customerContext.setCustomerPhone(value)
    customerContext.setCustomerPhoneErr(false)
  }
  const handleCustomerAlternativePhone = (e: any) => {
    const value = allowOnlyNumbers(e.target.value)
    customerContext.setAlternatePhoneNumber(value)
    customerContext.setAlternatePhoneNumberErr(false)
  }

  const handleCustomerName = (value: string) => {
    customerContext.setCustomerName(value)
    customerContext.setCustomerNameErr(false)
  }

  const handleEmailAddress = (value: any) => {
    customerContext.setEmail(value)
    customerContext.setEmailError(false)
  }

  useEffect(() => {
    if (customerContext?.customerPhone?.length === 11 && !disabled) {
      fetchOfflineProducts(
        "merchantCustomersList",
        "username",
        convert03to92(customerContext.customerPhone)
      )
    }
  }, [customerContext.customerPhone, isInternetOn])

  const performAfterGetDetails = (data: any) => {
    const customerDetails = data?.data

    customerContext.setCustomerAddressList(customerDetails?.addresses || [])
    customerContext.setShowCustomerName(true)
    customerContext.setCustomerNameErr(false)

    if (customerDetails?.username) {
      const { firstName, lastName } = customerDetails
      customerContext.setCustomerName(`${firstName} ${lastName}`)
    }
    if (data?.merchantCustomer?.email && !customerContext.email) {
      handleEmailAddress(data?.merchantCustomer?.email)
    }
    if (!customerDetails?.username) customerContext.setShowNewAddress(true)
  }

  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Phone"
          name="customer_phone"
          id="customer-phone-txt"
          type="text"
          autoComplete="off"
          variant="outlined"
          placeholder="Enter Customer Phone Number"
          error={customerContext.customerPhoneErr}
          helperText={
            customerContext.customerPhoneErr
              ? "Mobile number must be 11 digits long"
              : ""
          }
          value={customerContext.customerPhone}
          onChange={handleCustomerPhone}
          disabled={disabled}
        />
      </Grid>
      {!hideAltNumber ? (
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Alternative Phone Number"
            name="customer__alternative_phone"
            id="customer-alt-phone-txt"
            type="text"
            autoComplete="off"
            variant="outlined"
            placeholder="Enter Customer Alternative Phone Number"
            value={customerContext.alternatePhoneNumber}
            onChange={handleCustomerAlternativePhone}
            disabled={disabled}
          />
        </Grid>
      ) : (
        <></>
      )}

      {customerContext.showCustomerName ? (
        <>
          <Grid item xs={12} sx={classes.space}>
            <TextField
              fullWidth
              label="Name"
              name="customer_name"
              id="customer-name-txt"
              type="text"
              autoComplete="off"
              variant="outlined"
              placeholder="Enter Customer Name"
              value={customerContext.customerName}
              error={customerContext.customerNameErr}
              helperText={
                customerContext.customerNameErr
                  ? "Customer name cannot be empty"
                  : ""
              }
              onChange={(e) => handleCustomerName(e.target.value)}
              disabled={disabled}
            />
          </Grid>
          {posProductContext.isDigitalProductInCart() ? (
            <Grid item xs={12} sx={classes.space}>
              <TextField
                fullWidth
                required
                label="Email"
                name="email_phone"
                type="email"
                autoComplete="off"
                variant="outlined"
                placeholder="Enter Email Address"
                error={customerContext.emailError}
                helperText={
                  customerContext.emailError ? "Valid Email is Required" : ""
                }
                value={customerContext.email}
                onChange={(e) => handleEmailAddress(e.target.value)}
              />
            </Grid>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </Grid>
  )
}

export default CustomerFields

const MERCHANT_CUSTOMER = gql`
  query merchantCustomer($username: String!, $bvid: ID) {
    merchantCustomer(username: $username, bvid: $bvid) {
      email
      user {
        firstName
        lastName
        username
      }
      addresses {
        uid
        details
        isPrimary
        isAllowed
        location {
          coordinates
        }
        area {
          name
          city {
            name
            bvid
          }
          bvid
        }
      }
    }
  }
`
