import React from "react"
import TitleIcon from "main/common/TitleIcon"
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined"
const CustomerDetailsHeader = () => {
  return (
    <TitleIcon icon={<PersonOutlineOutlinedIcon />} title="Customer Details" />
  )
}

export default CustomerDetailsHeader
