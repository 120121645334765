import React from "react"
import { Grid } from "@mui/material"
import TypeTab from "main/pos/order/order_type/TypeTab"
import { OrderTypes } from "main/pos/constant/PosConstant"
import DeliveryDiningOutlinedIcon from "@mui/icons-material/DeliveryDiningOutlined"
import ShoppingBasketOutlinedIcon from "@mui/icons-material/ShoppingBasketOutlined"
import DirectionsWalkOutlinedIcon from "@mui/icons-material/DirectionsWalkOutlined"

const OrderType = () => {
  return (
    <Grid container spacing={4}>
      <Grid item md={4} xs={12}>
        <TypeTab
          name={OrderTypes.WalkIn}
          title="Walk In"
          icon={<DirectionsWalkOutlinedIcon />}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <TypeTab
          name={OrderTypes.PickUp}
          title="Pick up"
          icon={<ShoppingBasketOutlinedIcon />}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <TypeTab
          name={OrderTypes.Delivery}
          title="Delivery"
          icon={<DeliveryDiningOutlinedIcon />}
        />
      </Grid>
    </Grid>
  )
}
export default OrderType
