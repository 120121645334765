import { useContext } from "react"
import PosContext from "main/context/pos/PosContext"
import { PosContextType } from "main/context/pos/PosContextTypes"
import PosProductContext from "main/context/pos/PosProduct/PosProductContext"
import { PosProductType } from "main/context/pos/PosProduct/PosProductTypes"

export const useCustomerChange = () => {
  const posContext = useContext(PosContext) as PosContextType
  const productContext = useContext(PosProductContext) as PosProductType
  const customerChange: any =
    parseInt(posContext.cashReceived) - productContext?.calculateProductsTotal()

  return posContext?.cashReceived && customerChange > 0 ? customerChange : 0
}
