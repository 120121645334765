import React, { useContext, useEffect, useState } from "react"
import { Button, Grid, Theme } from "@mui/material"
import { useTheme } from "@mui/styles"
import PosSync from "main/pos/sync/PosSync"
import { BranchContextType } from "main/context/branch/BranchContextTypes"
import BranchContext from "main/context/branch/BranchContext"
import { userTypesList } from "main/util/UserHelper"
import { getFromStorage } from "main/util/LocalStorageHelper"
import PosBackgroundSync from "main/pos/sync/PosBackgroundSync"
import PosContext from "main/context/pos/PosContext"
import { PosContextType } from "main/context/pos/PosContextTypes"
import { useDispatch } from "react-redux"
import {
  setBackgroundSync,
  setFirstSync
} from "main/context/connection/ConnectionSlice"
import ConnectionHooks from "main/context/connection/ConnectionHook"

const styles = (theme: Theme) => ({
  posSyncButton: {
    backgroundColor: theme.custom.white
  }
})

const PosSyncButton = () => {
  const { isInternetOn, fromOrderPage, backgroundSync, firstSync } =
    ConnectionHooks()
  const classes = styles(useTheme())
  const dispatch = useDispatch()
  const [syncData, setSyncData] = useState(false)

  const { posConfigs } = useContext(PosContext) as PosContextType
  const { getBranchBvid } = useContext(BranchContext) as BranchContextType
  const branchBvid = getBranchBvid()

  const handleSyncButton = () => {
    dispatch(setFirstSync(false))
    setSyncData(true)
  }
  useEffect(() => {
    const checkUser = getFromStorage("user")
    if (checkUser) {
      const { role } = checkUser?.user ?? {}
      const { MasterAdmin, MasterPOS } = userTypesList
      if (role === MasterAdmin || role === MasterPOS) {
        if (branchBvid) {
          dispatch(setFirstSync(false))
          setSyncData(true)
        }
      } else {
        setSyncData(true)
      }
    }
  }, [branchBvid])

  useEffect(() => {
    if (isInternetOn && !syncData) {
      dispatch(setBackgroundSync(true))
    }
  }, [isInternetOn])

  const isBackgroundSync =
    !fromOrderPage && backgroundSync && posConfigs?.autoSync && firstSync

  return (
    <>
      {isInternetOn ? (
        <>
          {!posConfigs?.autoSync ? (
            <Grid item xs={6}>
              <Button
                fullWidth
                size="large"
                color="primary"
                variant="outlined"
                sx={classes.posSyncButton}
                onClick={handleSyncButton}
              >
                Sync
              </Button>
            </Grid>
          ) : (
            <></>
          )}
          {syncData && !firstSync ? (
            <PosSync stopSyncing={setSyncData} />
          ) : (
            <></>
          )}
          {isBackgroundSync ? <PosBackgroundSync /> : <></>}
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default PosSyncButton
