import { useContext, useEffect } from "react"
import PosContext from "main/context/pos/PosContext"
import { PosContextType } from "main/context/pos/PosContextTypes"
import PosCustomerContext from "main/context/pos/PosCustomer/PosCustomerContext"
import { PosCustomerType } from "main/context/pos/PosCustomer/PosCustomerTypes"
import PosProductContext from "main/context/pos/PosProduct/PosProductContext"
import { PosProductType } from "main/context/pos/PosProduct/PosProductTypes"
import {
  DiscountTypes,
  OrderTypes,
  PaymentMethodTypes
} from "main/pos/constant/PosConstant"
import { checkPastDateTime } from "main/util/DateTimeHelper"
import Validator from "main/util/Validator"
import { size } from "lodash-es"

export const useDefaultDeliveryFlag = () => {
  const posContext = useContext(PosContext) as PosContextType
  const { customerCity, customerArea, customerAddressId } = useContext(
    PosCustomerContext
  ) as PosCustomerType

  if (
    posContext.orderType === OrderTypes.Delivery &&
    ((size(customerCity) && size(customerArea)) || customerAddressId)
  ) {
    return false
  }
  return true
}

export const useExtractDeliveryCharges = (productSubTotal: any) => {
  const posContext = useContext(PosContext) as PosContextType
  const customerContext = useContext(PosCustomerContext) as PosCustomerType
  const { deliveryChargesThreshold } =
    posContext.merchantProfileData?.config ?? {}

  const findCharges = () => {
    if (
      deliveryChargesThreshold !== 0 &&
      productSubTotal >= deliveryChargesThreshold
    ) {
      return 0
    } else {
      const deliveryConfigs = posContext.deliveryConfigsData?.length
      if (deliveryConfigs) {
        if (posContext.orderType === OrderTypes.Delivery) {
          let cityFromAddress: any = customerContext.customerCity
          let areaFromAddress: any = customerContext.customerArea

          const add: any = customerContext.customerAddressList?.find(
            (ad: any) => ad.uid === customerContext.customerAddressId
          )
          if (!customerContext.showNewAddress) {
            cityFromAddress = add?.area?.city
            areaFromAddress = add?.area
          }

          if (cityFromAddress) {
            const deliveryByArea = areaFromAddress?.bvid
              ? posContext.deliveryConfigsData?.find(
                  (config: any) =>
                    areaFromAddress?.bvid === config.selectedArea?.bvid
                )
              : undefined

            const delivery = deliveryByArea
              ? deliveryByArea
              : posContext.deliveryConfigsData?.find(
                  (config: any) =>
                    cityFromAddress?.bvid === config.selectedCity?.bvid
                )

            return (
              delivery?.deliveryCharges ||
              posContext.merchantProfileData?.config?.deliveryCharges ||
              0
            )
          }
        }
      }

      return posContext.merchantProfileData?.config?.deliveryCharges || 0
    }
  }

  useEffect(() => {
    const charges = findCharges()
    posContext.setDeliveryCharges(charges)
  }, [
    customerContext.customerCity,
    customerContext.customerArea,
    customerContext.customerAddressId,
    posContext.merchantProfileData?.config?.deliveryCharges,
    posContext.deliveryConfigsData,
    productSubTotal
  ])
}

export const useCustomerValidations = () => {
  const posContext = useContext(PosContext) as PosContextType
  const {
    customerCity,
    setCustomerCityErr,
    customerArea,
    setCustomerAreaErr,
    customerAddressId,
    customerAddressDetails,
    setCustomerAddressDetailsErr,
    showSummary,
    showNewAddress,
    orderPickupTime,
    setOrderPickupTimeErr,
    autoPickAddress,
    checkFields
  } = useContext(PosCustomerContext) as PosCustomerType
  const isCustomerInfoValid = useIsCustomerInfoValid()
  const checkValidations = () => {
    if (showSummary) return true
    if (posContext.orderType === OrderTypes.Delivery) {
      const isDeliveryLaterValid = posContext.getIsDeliveryLaterValid()
      if (!isDeliveryLaterValid) return false
      if (showNewAddress) {
        const isCityInvalid = Validator.isEmpty(customerCity)
        const checkCityField = checkFields(isCityInvalid, setCustomerCityErr)
        const isAreaInvalid = Validator.isEmptyObject(customerArea)
        const checkAreaField = checkFields(isAreaInvalid, setCustomerAreaErr)
        const isAddressDetailsInvalid = Validator.isEmpty(
          customerAddressDetails
        )
        const checkAddressField = checkFields(
          isAddressDetailsInvalid,
          setCustomerAddressDetailsErr
        )
        const checkAddressFields =
          checkCityField && checkAreaField && checkAddressField

        return isCustomerInfoValid() && checkAddressFields
      } else if (
        Validator.isEmpty(customerAddressId) &&
        isCustomerInfoValid() &&
        !showNewAddress
      ) {
        autoPickAddress()
      }
      return isCustomerInfoValid()
    } else if (posContext.orderType === OrderTypes.PickUp) {
      const isPickUpTimeInvalid =
        Validator.isEmpty(orderPickupTime) || checkPastDateTime(orderPickupTime)

      const checkPickupField = checkFields(
        isPickUpTimeInvalid,
        setOrderPickupTimeErr
      )

      return isCustomerInfoValid() && checkPickupField
    } else if (posContext.orderType === OrderTypes.WalkIn) {
      if (
        posContext.paymentMethod === PaymentMethodTypes.OnlinePayment ||
        posContext.paymentMethod === PaymentMethodTypes.Credit ||
        posContext.discountType === DiscountTypes.Voucher
      ) {
        return isCustomerInfoValid()
      }
      return true
    }
  }

  return checkValidations
}

export const useIsCustomerInfoValid = () => {
  const posContext = useContext(PosContext) as PosContextType
  const {
    email,
    customerName,
    customerPhone,
    setCustomerPhoneErr,
    setCustomerNameErr,
    setEmailError,
    checkFields
  } = useContext(PosCustomerContext) as PosCustomerType
  const { isDigitalProductInCart } = useContext(
    PosProductContext
  ) as PosProductType

  const isCustomerInfoValid = () => {
    let checkNumberField, checkNameField, checkEmailField
    const checkOrderType =
      posContext.orderType === OrderTypes.Delivery ||
      posContext.orderType === OrderTypes.PickUp ||
      posContext.discountType === DiscountTypes.Voucher ||
      (posContext.orderType === OrderTypes.WalkIn &&
        posContext.paymentMethod === PaymentMethodTypes.OnlinePayment) ||
      posContext.paymentMethod === PaymentMethodTypes.Credit

    if (checkOrderType) {
      const isNameInvalid = Validator.isEmpty(customerName)
      const isEmailInvalid = Validator.isEmpty(email)
      const isNumberInvalid = !Validator.is03NumberValid(customerPhone)

      checkNumberField = checkFields(isNumberInvalid, setCustomerPhoneErr)
      checkNameField = checkFields(isNameInvalid, setCustomerNameErr)

      if (
        isDigitalProductInCart() &&
        posContext.paymentMethod !== PaymentMethodTypes.Credit
      ) {
        const errorInField = isEmailInvalid || !Validator.isEmailValid(email)
        checkEmailField = checkFields(errorInField, setEmailError)
      } else checkEmailField = true
    }
    return checkNumberField && checkNameField && checkEmailField
  }

  return isCustomerInfoValid
}
