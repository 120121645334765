import React, { useContext } from "react"
import PosCustomerContext from "main/context/pos/PosCustomer/PosCustomerContext"
import { PosCustomerType } from "main/context/pos/PosCustomer/PosCustomerTypes"
import { OrderSourceList } from "main/pos/constant/PosConstant"
import { Grid, MenuItem, TextField } from "@mui/material"

const OrderSource = () => {
  const { orderSource, setOrderSource } = useContext(
    PosCustomerContext
  ) as PosCustomerType

  const handleOrderSource = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value

    setOrderSource(value)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          select
          fullWidth
          name="order_source"
          label="Order Source"
          variant="outlined"
          value={orderSource}
          onChange={handleOrderSource}
        >
          {OrderSourceList.map(
            ({ label, value }: { label: string; value: string }) => (
              <MenuItem key={label} value={value}>
                {label}
              </MenuItem>
            )
          )}
        </TextField>
      </Grid>
    </Grid>
  )
}

export default OrderSource
