import React from "react"
import { Box } from "@mui/material"
import ConfigContext from "main/context/config/ConfigContext"
import { ConfigContextTypes } from "main/context/config/ConfigContextTypes"
import ImageHelper from "main/util/ImageHelper"
import { useContext } from "react"
import { CustomProductImageProps } from "main/common/CustomProductImageTypes"

const CustomProductImage = ({
  sx,
  alt,
  product,
  ...rest
}: CustomProductImageProps) => {
  const { logoImageUrl } = useContext(ConfigContext) as ConfigContextTypes
  return (
    <Box
      component="img"
      sx={sx}
      alt={alt}
      src={ImageHelper.getImageUrl(product, logoImageUrl)}
      {...rest}
    />
  )
}

export default CustomProductImage
