export const userTypesList = Object.freeze({
  MasterAdmin: "MasterAdmin",
  Owner: "Owner",
  Manager: "Manager",
  MasterPOS: "MasterPOS",
  BranchPOS: "BranchPOS",
  Picker: "Picker",
  Rider: "Rider",
  ShiftUser: "ShiftUser"
})

export const userTypes = [
  "MasterAdmin",
  "Owner",
  "Manager",
  "MasterPOS",
  "BranchPOS",
  "Picker",
  "Rider"
]

export const addUserTypes = [
  "MasterPOS",
  "BranchPOS",
  "Manager",
  "Picker",
  "Rider"
]
export const editUserTypes = ["BranchPOS", "Manager", "Picker"]
export const permanentUserTypes = ["MasterAdmin", "Owner"]
export const posUsers = ["MasterPOS", "BranchPOS"]
export const nonAuthUsers = ["Picker", "Rider"]

export const userTypesMapped = Object.freeze({
  MasterAdmin: "Master Admin",
  Owner: "Owner",
  Manager: "Manager",
  MasterPOS: "Master POS",
  BranchPOS: "Branch POS",
  Picker: "Picker",
  Rider: "Rider"
})

export const userTypesColors = Object.freeze({
  MasterAdmin: "#008080",
  Owner: "#FF0000",
  Manager: "#f79a3c",
  MasterPOS: "#2980B9",
  BranchPOS: "#808000",
  Picker: "#3C4252",
  Rider: "#00A86B"
})

export const filterPosUsers = (userType: any) => {
  return !posUsers.includes(userType)
}
