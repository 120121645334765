import React, { useCallback, useContext, useEffect, useState } from "react"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import { TextField, Theme } from "@mui/material"
import Typography from "@mui/material/Typography"
import useTheme from "@mui/styles/useTheme"
import SimpleDialog from "main/common/simple_dialog/SimpleDialog"
import { gql, useLazyQuery } from "@apollo/client"
import debounceHelper from "main/util/DebounceHelper"
import pulse from "graphql/pulse"
import { saveShiftData } from "main/pos/helpers/ShiftHelper"
import UserContext from "main/context/user/UserContext"
import { UserContextType } from "main/context/user/UserContextTypes"
import { userTypesList } from "main/util/UserHelper"

const styles = (theme: Theme) => ({
  order: {
    backgroundColor: theme.custom.white
  },
  topSpace: {
    marginTop: 1
  }
})

const OrderReturn = () => {
  const classes = styles(useTheme())
  const { isUserRole } = useContext(UserContext) as UserContextType
  const [dialog, setDialog] = useState(false)
  const [orderNumber, setOrderNumber] = useState("")
  const [refundAmount, setRefundAmount] = useState(0)
  const [isDataFetched, setIsDataFetched] = useState(false)
  const isShiftUser = isUserRole(userTypesList.ShiftUser)

  const storeRefundAmount = (data: any) => {
    const { orders } = data?.orderSearch ?? {}
    setIsDataFetched(true)
    if (orders?.length === 1) {
      const { refundAmount } = orders[0]?.refund ?? {}
      if (refundAmount) {
        setRefundAmount(refundAmount)
        return
      }
      setRefundAmount(0)
    }
  }

  const [fetchOrders] = useLazyQuery(ORDERS, {
    fetchPolicy: "network-only",
    client: pulse,
    onCompleted: storeRefundAmount
  })

  const fetchOrderWithNumber = useCallback(
    debounceHelper((orderNumber: string) => {
      fetchOrders({ variables: { searchKeyword: orderNumber } })
    }, 1000),
    []
  )

  useEffect(() => {
    if (orderNumber) fetchOrderWithNumber(orderNumber)
  }, [orderNumber])

  const openDialog = () => {
    setOrderNumber("")
    setRefundAmount(0)
    setIsDataFetched(false)
    setDialog(true)
  }

  const saveRefund = () => {
    saveShiftData("cashRefunds", refundAmount)
    setDialog(false)
  }

  const isOrderRefunded = isDataFetched && refundAmount
  const isOrderNotRefunded = isDataFetched && !refundAmount && orderNumber

  return (
    <>
      {isShiftUser ? (
        <>
          <Grid item xs={6}>
            <Button
              fullWidth
              size="large"
              variant="outlined"
              color="primary"
              sx={classes.order}
              onClick={openDialog}
            >
              Return Order
            </Button>
          </Grid>
          {dialog ? (
            <SimpleDialog
              fullWidth
              allowActions
              closeButton
              maxWidth="xs"
              title={<Typography variant="h5">Order Return</Typography>}
              dialogOpen={dialog}
              onHandleClose={() => setDialog(false)}
              handleCancel={() => setDialog(false)}
              handleOk={saveRefund}
              disabled={!isOrderRefunded}
              okButton="Save refund amount"
              cancelButton="Cancel"
              body={
                <Grid container sx={classes.topSpace}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Type or scan order's number"
                      value={orderNumber}
                      onChange={(e) => setOrderNumber(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {isOrderRefunded ? (
                      <Typography color="error">
                        {`Refund Amount: ${refundAmount}`}
                      </Typography>
                    ) : (
                      <></>
                    )}

                    {isOrderNotRefunded ? (
                      <Typography color="error">
                        This is not returned order
                      </Typography>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Grid>
              }
            />
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  )
}
export default OrderReturn

const ORDERS = gql`
  query orderSearch($searchKeyword: String) {
    orderSearch(searchKeyword: $searchKeyword) {
      orders {
        bvid
        refund {
          refundAmount
        }
      }
    }
  }
`
