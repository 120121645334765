import React from "react"
import { InputAdornment, TextField, Theme } from "@mui/material"
import { useTheme } from "@mui/styles"
import SearchIcon from "@mui/icons-material/Search"
import Clear from "@mui/icons-material/Clear"
import { SearchType } from "main/common/SearchTypes"

const styles = (theme: Theme) => ({
  searchField: {
    backgroundColor: "transparent",
    "& .MuiInputBase-root.MuiOutlinedInput-root": {
      backgroundColor: theme.palette.common.white
    }
  },
  resetIcon: {
    cursor: "pointer",
    marginTop: "1px"
  }
})
const Search = ({
  focus,
  placeholder,
  label,
  searchValue,
  setSearchValue,
  searchInputRef,
  searchVariant,
  searchSize,
  onEnter,
  error,
  hideSearchIcon,
  onPaste
}: SearchType) => {
  const classes = styles(useTheme())

  const handleSearchValue = (event: any) => {
    const searchValue = event.target.value
    setSearchValue(searchValue)
  }

  const resetSearchValue = () => {
    setSearchValue("")
  }

  const handleKeyDown = (event: any) => {
    if (event.keyCode !== 13) return

    const searchValue = event.target.value
    onEnter?.(searchValue)
  }

  return (
    <TextField
      fullWidth
      autoComplete="off"
      label={label}
      size={searchSize}
      autoFocus={focus}
      variant={searchVariant}
      sx={classes.searchField}
      inputRef={searchInputRef}
      value={searchValue}
      id="search-textfield"
      placeholder={placeholder}
      onChange={handleSearchValue}
      onKeyDown={handleKeyDown}
      onPaste={onPaste}
      InputProps={{
        startAdornment: !hideSearchIcon ? (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ) : (
          <></>
        ),
        endAdornment:
          searchValue || error ? (
            <InputAdornment position="end">
              <Clear sx={classes.resetIcon} onClick={resetSearchValue} />
            </InputAdornment>
          ) : (
            <></>
          )
      }}
      error={Boolean(error)}
      helperText={error}
    />
  )
}

export default Search
