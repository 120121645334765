import React, { useContext } from "react"
import { Grid, Typography } from "@mui/material"
import PosProductContext from "main/context/pos/PosProduct/PosProductContext"
import OptionButton from "main/common/option_button/OptionButton"
import { PosProductType } from "main/context/pos/PosProduct/PosProductTypes"

const styles = () => ({
  container: {
    paddingLeft: 4
  }
})

const SubOptions = ({
  subOptions,
  handleOption
}: {
  subOptions: any
  handleOption: any
}) => {
  const classes = styles()
  const productContext = useContext(PosProductContext) as PosProductType

  const selectedOptions = productContext.isProductUpdate
    ? productContext.selectedOptions
    : null

  const subOptionCheck = subOptions?.subOptionValues.filter(
    (subOption: any) => subOption.isActive
  )

  return (
    <>
      {subOptionCheck.length ? (
        <Grid container sx={classes.container}>
          <Grid item xs={12}>
            <Typography>
              {subOptions.title}
              {subOptions.isMultiSelect ? "" : " - Select 1"}
              {subOptions.isMandatory ? "" : "  (optional)"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <OptionButton
              mainValues={subOptions}
              subValues={subOptions?.subOptionValues}
              handleSelection={handleOption}
              selectedOptions={selectedOptions}
            />
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
    </>
  )
}

export default SubOptions
