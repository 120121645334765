import { useContext } from "react"
import PosContext from "main/context/pos/PosContext"
import PosProductContext from "main/context/pos/PosProduct/PosProductContext"
import { PosProductType } from "main/context/pos/PosProduct/PosProductTypes"
import { PosContextType } from "main/context/pos/PosContextTypes"
import PosCustomerContext from "main/context/pos/PosCustomer/PosCustomerContext"
import { PosCustomerType } from "main/context/pos/PosCustomer/PosCustomerTypes"
import ConfigContext from "main/context/config/ConfigContext"
import { ConfigContextTypes } from "main/context/config/ConfigContextTypes"
import BranchContext from "main/context/branch/BranchContext"
import { BranchContextType } from "main/context/branch/BranchContextTypes"

export const usePosContext = () => {
  return useContext(PosContext) as PosContextType
}
export const useCustomerContext = () => {
  return useContext(PosCustomerContext) as PosCustomerType
}
export const useProductContext = () => {
  return useContext(PosProductContext) as PosProductType
}

export const useConfigContext = () => {
  return useContext(ConfigContext) as ConfigContextTypes
}

export const useBranchContext = () => {
  return useContext(BranchContext) as BranchContextType
}
